import {Status, OperationModeType, isProductionMode} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Stack, Box, Button} from '@mui/material'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useMaterialsRecipes} from '../../../materials/hooks'
import {usePermission} from '../../../permissions'
import {useAssetData} from '../../hooks/useAssetData'

import {OperationModeForm, type NewOperationMode} from './OperationModeForm'

export const OperationModesCard = () => {
  const {t} = useTranslation()
  const selectedAsset = useAssetData()
  const canChangeAssets = usePermission('CHANGE_ASSETS')

  const {data: recipes} = useMaterialsRecipes(selectedAsset?.type)

  const [newOperationMode, setNewOperationMode] = useState<NewOperationMode>()

  const {operationModes: allOperationModes} = selectedAsset
  const availableRecipes = recipes ?? []

  // we are not supporting maintenance modes in the settings right now it should be done in the future
  const nonMaintenanceModes = allOperationModes.filter(isProductionMode)

  const operationModes = [...nonMaintenanceModes, ...(newOperationMode ? [newOperationMode] : [])]
  return (
    <Card
      {...dataTestId('operation_modes_card')}
      headerContent={
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <CardTitle sx={{mb: 0}}>
            {t('assetsSettings.operationModesAmount', {amount: nonMaintenanceModes.length})}
          </CardTitle>
          <Button
            variant="text"
            startIcon={<Add />}
            disabled={
              Boolean(newOperationMode) || !canChangeAssets || availableRecipes.length === 0
            }
            {...dataTestId('add_operation_mode')}
            onClick={() =>
              availableRecipes[0] &&
              setNewOperationMode({
                name: '',
                type: OperationModeType.Production,
                status: Status.New,
                throughput: null,
                recipeId: null,
                powerConsumption: 0,
                minimumRuntime: 0,
                isOptimized: false
              })
            }
          >
            {t('assetsSettings.addOperationMode')}
          </Button>
        </Box>
      }
    >
      <Stack spacing={3}>
        {operationModes.map((operationMode, index) => (
          <OperationModeForm
            index={index}
            asset={selectedAsset}
            key={operationMode.status === Status.New ? 'new' : operationMode.id}
            operationMode={operationMode}
            recipes={availableRecipes}
            isReadOnly={!canChangeAssets}
            setNewOperationMode={setNewOperationMode}
          />
        ))}
      </Stack>
    </Card>
  )
}
