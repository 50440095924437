import type {Asset, OperationMode, Recipe} from '@hconnect/common/types'

function isOperationModeContainsAnyRecipe(mode: OperationMode, recipes: Recipe[]) {
  return recipes.find((r) => r.id === mode.recipeId) !== undefined
}

/**
 * filters provided assets
 * keep assets that have any production operation mode that is associated with any provided recipe
 */
export const filterProductionAssetsWithAnyRecipeFromList = (assets: Asset[], recipes: Recipe[]) => {
  const result = assets.filter(
    (asset) =>
      asset.operationModes.find((mode) => isOperationModeContainsAnyRecipe(mode, recipes)) !==
      undefined
  )

  return result
}

/**
 * Find all operation modes that associated with recipe from provided list
 * @param assets too search for operation modes
 * @param recipes to match with operation modes
 */
export const findAllOperationModes = (assets: Asset[], recipes: Recipe[]) => {
  const result = assets.flatMap((asset) =>
    asset.operationModes.filter((mode) => isOperationModeContainsAnyRecipe(mode, recipes))
  )

  return result
}
