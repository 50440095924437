import type {Asset} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {getSettingsQueryData, setSettingsQueryData} from '../../../common/providers/ApiInterface'
import type {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'

export const useEditAsset = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<Asset, AxiosError<ErrorResponse>, Parameters<typeof mutations.editAsset>[0]>(
    mutations.editAsset,
    {
      onError: (error) => {
        const data = error.response?.data
        notifyIfErrorMessage(data?.message, raiseError, notify)
      },
      onSuccess: () => {
        notify('success', t('success.editAsset'))
      },
      onSettled: (editedAsset, error, variables) => {
        const prevAssets = getSettingsQueryData('assets', [variables.plantCode])
        if (prevAssets && editedAsset) {
          setSettingsQueryData(
            'assets',
            [variables.plantCode],
            prevAssets.map((asset) => (asset.id === variables.assetId ? editedAsset : asset))
          )
        }
      }
    }
  )
}
