import {type RecipeComponent, RecipeComponentType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined} from '@mui/icons-material'
import {Box, Stack} from '@mui/material'
import React, {useCallback} from 'react'
import {UseFieldArrayRemove, UseFieldArrayUpdate, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {
  ControlledAutocomplete,
  ControlledTextField,
  DeleteButton
} from '../../../../../common/components'
import {useConfirmDialog} from '../../../../../common/providers'
import {MaterialFormDefaultValues} from '../../../materials-details/ExistingMaterialDetails/hooks'

import type {NewRecipeComponent} from '@settings/modules/materials/types'

interface MaterialFormRecipeComponentProps {
  letterSequenceIndex: number
  fieldArrayRecipeIndex: number
  fieldArrayRecipeComponentIndex: number
  formRecipeComponent: RecipeComponent | NewRecipeComponent
  availableMaterialsMap: Record<number, string>
  isEditMaterialDisabled: boolean
  isEditFractionDisabled: boolean
  isRemoveComponentDisabled: boolean
  removeRecipeComponent: UseFieldArrayRemove
  updateRecipeComponent: UseFieldArrayUpdate<
    MaterialFormDefaultValues,
    `recipes.${number}.components`
  >
}

function getShift(numberStr: string): number {
  const parts = numberStr.split('.')
  return parts.length < 2 ? 1 : Math.pow(10, parts[1].length)
}

function fractionToPercentage(fraction: number): string {
  const shift = getShift(fraction.toString())
  const percentage = ((Math.round(fraction * shift) * 100) / shift).toString()
  return percentage
}

function percentageToFraction(percentage: string): number {
  const shift = getShift(percentage)
  const fraction = Math.round(parseFloat(percentage) * shift) / (100 * shift)
  return isNaN(fraction) ? 0 : fraction
}

export const MaterialFormRecipeComponent: React.FC<MaterialFormRecipeComponentProps> = ({
  letterSequenceIndex,
  fieldArrayRecipeIndex,
  fieldArrayRecipeComponentIndex,
  formRecipeComponent,
  availableMaterialsMap,
  isEditMaterialDisabled,
  isEditFractionDisabled,
  isRemoveComponentDisabled,
  removeRecipeComponent,
  updateRecipeComponent
}) => {
  const {t} = useTranslation()
  const {openDialog} = useConfirmDialog()

  const {control, getValues, setValue} = useFormContext<MaterialFormDefaultValues>()

  // Material dropdown
  const materialInputLabel =
    formRecipeComponent.type === RecipeComponentType.Input
      ? t('materialsSettings.ingredient')
      : t('materialsSettings.output')
  const materialInputOptions = Object.keys(availableMaterialsMap)

  const handleDeleteRecipeComponent = useCallback(() => {
    const isNewRecipeComponent = formRecipeComponent.id === null
    if (isNewRecipeComponent) {
      removeRecipeComponent(fieldArrayRecipeComponentIndex)
      return
    }
    openDialog({
      title: t('materialsSettings.deleteRecipeComponent'),
      description: t('materialsSettings.deleteRecipeComponentConfirmation', {
        name: formRecipeComponent.material.name
      }),
      mainAction: {
        color: 'error',
        text: t('common.delete'),
        icon: <DeleteOutlined />,
        onAction: () => removeRecipeComponent(fieldArrayRecipeComponentIndex)
      }
    })
  }, [fieldArrayRecipeComponentIndex, formRecipeComponent, openDialog, removeRecipeComponent, t])

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{pl: 4}}
      {...dataTestId('recipe_component_form')}
    >
      <NumberLetterSequenceIndicator
        letterIndex={letterSequenceIndex}
        {...dataTestId('recipe_component_form_order_number')}
      />
      <Box sx={{width: (theme) => theme.spacing(32)}}>
        <ControlledAutocomplete
          control={control}
          formDataName={`recipes.${fieldArrayRecipeIndex}.components.${fieldArrayRecipeComponentIndex}.material.id`}
          options={materialInputOptions}
          label={materialInputLabel}
          disabled={isEditMaterialDisabled}
          isOptionEqualToValue={(option, value) => Number(option) === Number(value)}
          getOptionLabel={(option) => availableMaterialsMap[Number(option)]}
          onChangeCb={() => {
            const currentRecipeComponent = getValues(
              `recipes.${fieldArrayRecipeIndex}.components.${fieldArrayRecipeComponentIndex}`
            )
            const currentMaterialId = Number(currentRecipeComponent.material.id)
            updateRecipeComponent(fieldArrayRecipeComponentIndex, {
              ...currentRecipeComponent,
              material: {
                id: currentMaterialId,
                name: availableMaterialsMap[currentMaterialId]
              }
            })
          }}
          {...dataTestId('material_select')}
        />
      </Box>
      <ControlledTextField
        control={control}
        formDataName={`recipes.${fieldArrayRecipeIndex}.components.${fieldArrayRecipeComponentIndex}.fraction`}
        label={'%'}
        TextFieldProps={{
          type: 'number',
          inputRef: null,
          disabled: isEditFractionDisabled,
          onWheel: (e) => {
            // Preventing users from modyfying element value via mouse wheel
            ;(e.target as HTMLElement).blur()
          },
          onBlur: () => {
            // Triggering total components recalculation on fraction change
            const currentRecipeComponent = getValues(
              `recipes.${fieldArrayRecipeIndex}.components.${fieldArrayRecipeComponentIndex}`
            )
            updateRecipeComponent(fieldArrayRecipeComponentIndex, currentRecipeComponent)
          },
          inputProps: {min: '0'},
          sx: ({spacing, palette}) => ({
            width: {xs: '100%', sm: spacing(12)},
            '& .MuiInputBase-root': {
              height: spacing(7),
              borderRadius: spacing(1),
              boxShadow: 'none',
              ...(!isEditFractionDisabled && {background: palette.background.paper})
            },
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
              WebkitAppearance: 'none'
            },
            'input[type=number]': {
              MozAppearance: 'textfield'
            }
          })
        }}
        transformValue={(value) => fractionToPercentage(Number(value))}
        onChangeCb={() => {
          const currentRecipeComponentFraction = getValues(
            `recipes.${fieldArrayRecipeIndex}.components.${fieldArrayRecipeComponentIndex}.fraction`
          )
          setValue(
            `recipes.${fieldArrayRecipeIndex}.components.${fieldArrayRecipeComponentIndex}.fraction`,
            percentageToFraction(currentRecipeComponentFraction.toString())
          )
        }}
        {...dataTestId('fraction_input')}
      />
      <DeleteButton
        onClick={handleDeleteRecipeComponent}
        disabled={isRemoveComponentDisabled}
        {...dataTestId('delete_recipe_component_button')}
      />
    </Stack>
  )
}
