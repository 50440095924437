import type {MaterialWithRecipes} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined} from '@mui/icons-material'
import {Stack, Typography} from '@mui/material'
import React from 'react'
import {UseFieldArrayRemove, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ControlledTextField, DeleteButton} from '../../../../common/components'
import {useConfirmDialog} from '../../../../common/providers'
import {requiredValidator} from '../../../../common/utils'
import type {
  MaterialFormDefaultValues,
  MaterialFormPxTrendCounter
} from '../../materials-details/ExistingMaterialDetails/hooks'

interface MaterialFormPxTrendProps {
  index: number
  formPxTrendCounter: MaterialFormPxTrendCounter
  isPxTrendCounterDisabled: boolean
  material: MaterialWithRecipes
  removePxTrendCounter: UseFieldArrayRemove
}

export const MaterialFormPxTrend: React.FC<MaterialFormPxTrendProps> = ({
  index,
  formPxTrendCounter,
  isPxTrendCounterDisabled,
  material,
  removePxTrendCounter
}) => {
  const {t} = useTranslation()
  const {openDialog} = useConfirmDialog()

  const {control} = useFormContext<MaterialFormDefaultValues>()

  const isAlreadyAttachedPxTrendCounter = !!material.pxTrendCounters.some(
    (pxTrendCounter) => pxTrendCounter === formPxTrendCounter.name
  )

  const handleDeletePxTrendCounter = () => {
    if (!isAlreadyAttachedPxTrendCounter) {
      removePxTrendCounter(index)
      return
    }
    openDialog({
      title: t('materialsSettings.deletePxTrendCounterTitle'),
      mainAction: {
        text: t('common.delete'),
        color: 'error',
        icon: <DeleteOutlined />,
        onAction: () => {
          removePxTrendCounter(index)
        }
      },
      additionalContent: (
        <Typography>
          {t('materialsSettings.deletePxTrendCounterConfirmation', {
            pxTrendCounter: formPxTrendCounter.name
          })}
        </Typography>
      )
    })
  }

  return (
    <Stack direction="row" alignItems="center" spacing={2} {...dataTestId('px_trend_form')}>
      <NumberLetterSequenceIndicator
        numberIndex={index}
        {...dataTestId('px_trend_form_order_number')}
      />
      <ControlledTextField
        control={control}
        formDataName={`pxTrendCounters.${index}.name`}
        rules={requiredValidator(t)}
        label={t('materialsSettings.counter')}
        TextFieldProps={{
          disabled: isPxTrendCounterDisabled,
          sx: ({spacing, palette}) => ({
            width: {xs: '100%', sm: spacing(50)},
            '& .MuiInputBase-root': {
              height: spacing(7),
              borderRadius: spacing(1),
              boxShadow: 'none',
              background: palette.background.paper
            }
          })
        }}
      />
      <DeleteButton
        onClick={handleDeletePxTrendCounter}
        disabled={isPxTrendCounterDisabled}
        {...dataTestId('px_trend_form_delete_button')}
      />
    </Stack>
  )
}
