import {NewProduct, ProductType} from '@hconnect/common/types'

export const getNewFormProduct = (newFormProductType: ProductType): NewProduct => {
  return {
    id: null,
    code: null,
    name: null,
    type: newFormProductType
  }
}
