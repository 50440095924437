import {Card} from '@hconnect/uikit/src/lib2'
import {useState} from 'react'
import {FormProvider} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useIsMutating} from 'react-query'
import {useNavigate} from 'react-router-dom'

import {HeaderTab, HeaderTabs} from '../../../../common/components/HeaderTabs'
import {getUrl, PLANT_ROUTES, useUrlParam} from '../../../../routing'
import {MaterialSettingsGeneralTab} from '../../materials-tabs'
import {MaterialDetailsCardHeader} from '../common/MaterialDetailsCardHeader'

import {useNewMaterialForm, useNewMaterialFormSubmit, useNewMaterialTabs} from './hooks'

export const NewMaterialDetailsCard: React.FC = () => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const navigate = useNavigate()

  // Preventing user access to invalid tabs while saving
  const runningMutations = useIsMutating(['editMaterial'])
  const isMaterialSaving = Boolean(runningMutations)

  const newMaterialForm = useNewMaterialForm()

  const availableTabs = useNewMaterialTabs(newMaterialForm)
  const [selectedTab, setSelectedTab] = useState<HeaderTab>(availableTabs[0])

  return (
    <Card
      headerContent={
        <MaterialDetailsCardHeader
          headerTitle={t('materialsSettings.newMaterial')}
          isCancelDisabled={isMaterialSaving}
          isSaveDisabled={isMaterialSaving || !newMaterialForm.formState.isDirty}
          onCancelClick={() => navigate(getUrl(PLANT_ROUTES.MATERIALS.path, {plantCode}))}
          onSaveClick={useNewMaterialFormSubmit(newMaterialForm)}
        />
      }
    >
      <HeaderTabs
        tabs={availableTabs}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        testIdPrefix={'material-settings-card'}
        sxTabs={{mb: 3}}
        sxTab={{color: ({palette}) => palette.common.black, fontWeight: 500}}
      />
      <FormProvider {...newMaterialForm}>
        <MaterialSettingsGeneralTab material={undefined} />
      </FormProvider>
    </Card>
  )
}
