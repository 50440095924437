import {useMemo} from 'react'

import {useGlobalMaterialsQuery, useMaterialsQuery} from '../../../hooks'

export const useMaterialsWithGlobalName = () => {
  const {data: materials} = useMaterialsQuery()
  const {data: globalMaterialOptions} = useGlobalMaterialsQuery()

  if (!materials || !globalMaterialOptions) {
    throw new Error(
      'materials and globalMaterialOptions must be loaded before displaying materials tree'
    )
  }

  return useMemo(
    () =>
      materials.map((material) => ({
        ...material,
        ...(material.globalMaterialId && {
          globlaMaterialName: globalMaterialOptions.find(
            (option) => option.id === material.globalMaterialId!.toString()
          )?.label
        })
      })),
    [globalMaterialOptions, materials]
  )
}
