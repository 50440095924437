import {
  CementClassificationInputNames,
  ClinkerClassificationInputNames,
  RawMaterialsClassificationInputNames,
  FuelsClassificationInputNames,
  EnhancersForCementClassificationInputNames,
  AdditivesForClinkerClassificationInputNames,
  IntermediateAndFinishedProductsClassificationInputNames
} from '@hconnect/common/enums'
import {
  MaterialBoughtFrom,
  MaterialSource,
  MaterialType,
  MaterialWithRecipes,
  ProductType,
  ClassificationMetadataValueSet,
  Status,
  RecipeComponentType
} from '@hconnect/common/types'
import {isEqual} from 'lodash'
import {useTranslation} from 'react-i18next'
import * as z from 'zod'

import {useMaterialsClassificationMetadata} from '../../../../hooks'

import {MaterialFormDefaultValues} from './useMaterialFormDefaultValues'

export const useMaterialFormValidationSchema = (
  material: MaterialWithRecipes,
  materialFormDefaultValues: MaterialFormDefaultValues
) => {
  const {t} = useTranslation()

  const {data: classificationMetadata} = useMaterialsClassificationMetadata(material.type)
  if (!classificationMetadata) {
    throw new Error('BUG: classification metadata should be loaded before using this hook')
  }

  const generalTabValidationSchema = z.object({
    type: z.nativeEnum(MaterialType, {message: t('error.required')}),
    name: z.string().nonempty({message: t('error.required')}),
    description: z.string(),
    // TODO <HCP-82759>: remove when we switch to globalName from BE
    globalMaterial: z.union([z.object({id: z.string(), label: z.string()}), z.null()]),
    source: z.nativeEnum(MaterialSource, {message: t('materialsSettings.sourceValidationError')}),
    boughtFrom: z.nativeEnum(MaterialBoughtFrom)
  })

  const classificationMetadataTabValidationSchema = z.object({
    classification: z
      .record(
        z.union([
          z.nativeEnum(CementClassificationInputNames),
          z.nativeEnum(ClinkerClassificationInputNames),
          z.nativeEnum(RawMaterialsClassificationInputNames),
          z.nativeEnum(FuelsClassificationInputNames),
          z.nativeEnum(EnhancersForCementClassificationInputNames),
          z.nativeEnum(AdditivesForClinkerClassificationInputNames),
          z.nativeEnum(IntermediateAndFinishedProductsClassificationInputNames)
        ]),
        z.union([z.string(), z.null()])
      )
      .superRefine((classification, ctx) => {
        let isClassificationValid = true

        const hasClassificationChanged = !isEqual(
          materialFormDefaultValues.classification,
          classification
        )
        if (!hasClassificationChanged) return true

        Object.entries(classification).forEach(([inputName, inputValue]) => {
          const shouldInputHaveValue = !!classificationMetadata.inputs[inputName].valueSets.find(
            (valueSet: ClassificationMetadataValueSet) => {
              // Agreement with BE: for now if valuesShownWnen is undefined, it's the only valid value set
              if (valueSet.valuesShownWhen === undefined) {
                return true
              }
              // Agreement with BE: for now any field can be dependant only on one other field
              const conditionForValueSet = valueSet.valuesShownWhen[0]
              const conditionCurrentFormValue = classification[conditionForValueSet.inputName]
              return conditionCurrentFormValue === conditionForValueSet.value
            }
          )

          if (shouldInputHaveValue && inputValue === null) {
            isClassificationValid = false
            ctx.addIssue({
              path: [inputName],
              code: z.ZodIssueCode.custom,
              message: t('error.required')
            })
          }
        })

        return isClassificationValid
      })
  })

  const productsTabValidationSchema = z.object({
    products: z
      .object({
        id: z.number(),
        name: z.string({message: t('error.required')}).nonempty({message: t('error.required')}),
        code: z.string({message: t('error.required')}).nonempty({message: t('error.required')}),
        type: z.nativeEnum(ProductType, {message: t('error.required')})
      })
      .array()
      .optional()
  })

  const limsMaterialsTabValidationSchema = z.object({
    limsMaterials: z
      .object({
        id: z.number(),
        code: z.string({message: t('error.required')}).nonempty({message: t('error.required')}),
        name: z.string().optional()
      })
      .array()
      .optional()
  })

  const pxTrendCountersTabValidationSchema = z.object({
    pxTrendCounters: z
      .object({
        name: z.string({message: t('error.required')}).nonempty({message: t('error.required')})
      })
      .array()
      .optional()
  })

  const recipesTabValidationSchema = z.object({
    recipes: z
      .object({
        id: z.number().nullable(),
        name: z.string().nonempty({message: t('error.required')}),
        mainMaterialId: z.number(),
        status: z.nativeEnum(Status),
        components: z
          .object({
            id: z.number().nullable(),
            type: z.nativeEnum(RecipeComponentType),
            fraction: z
              .number({message: t('error.required')})
              .min(0, {message: t('error.minValue', {value: 0})}),
            material: z.object({
              id: z.number({message: t('error.required')}),
              name: z.string({message: t('error.required')})
            })
          })
          .array()
      })
      .array()
      .optional()
  })

  return generalTabValidationSchema
    .merge(classificationMetadataTabValidationSchema)
    .merge(productsTabValidationSchema)
    .merge(limsMaterialsTabValidationSchema)
    .merge(recipesTabValidationSchema)
    .merge(pxTrendCountersTabValidationSchema)
}
