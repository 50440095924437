import {RecipeComponentType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Add, KeyboardArrowDown} from '@material-ui/icons'
import {Button, Menu, MenuItem, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

interface RecipeComponentAddDropdownProps {
  disabled: boolean
  onAddComponent: (type: RecipeComponentType) => void
  setSelectedComponentType: (componentType: RecipeComponentType) => void
}

export const RecipeComponentAddDropdown: React.FC<RecipeComponentAddDropdownProps> = ({
  disabled,
  onAddComponent,
  setSelectedComponentType
}) => {
  const {t} = useTranslation()

  const [recipeComponentTypeDropdown, setRecipeComponentTypeDropdown] =
    React.useState<null | HTMLElement>(null)

  return (
    <Stack>
      <Button
        variant="text"
        startIcon={<Add />}
        endIcon={<KeyboardArrowDown />}
        disabled={disabled}
        onClick={(e) => setRecipeComponentTypeDropdown(e.currentTarget)}
        {...dataTestId('add_recipe_component_button')}
      >
        {t('common.add')}
      </Button>
      <Menu
        anchorEl={recipeComponentTypeDropdown}
        open={!!recipeComponentTypeDropdown}
        onClose={() => setRecipeComponentTypeDropdown(null)}
      >
        <MenuItem
          onClick={() => {
            setRecipeComponentTypeDropdown(null)
            setSelectedComponentType(RecipeComponentType.Input)
            onAddComponent(RecipeComponentType.Input)
          }}
          disableRipple
          {...dataTestId('add_ingredient_button')}
        >
          {t('materialsSettings.ingredient')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRecipeComponentTypeDropdown(null)
            setSelectedComponentType(RecipeComponentType.Output)
            onAddComponent(RecipeComponentType.Output)
          }}
          disableRipple
          {...dataTestId('add_output_button')}
        >
          {t('materialsSettings.output')}
        </MenuItem>
      </Menu>
    </Stack>
  )
}
