import {MaterialSource, ProductType} from '@hconnect/common/types'
import type {MaterialWithRecipes} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Add} from '@mui/icons-material'
import {Box, Button, Stack} from '@mui/material'
import React from 'react'
import {useFieldArray, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useIsMutating} from 'react-query'

import {usePermission} from '../../../../permissions'
import {useMaterialsProductsQuery, useMaterialsQuery} from '../../../hooks'
import {MaterialFormDefaultValues} from '../../materials-details/ExistingMaterialDetails/hooks'

import {getAvailableProducts, getNewFormProduct} from './helpers'
import {MaterialFormProduct} from './MaterialFormProduct'
import {SalesForecastInfo} from './salesForecast'

interface MaterialSettingsProductsTabProps {
  material: MaterialWithRecipes
}

export const MaterialSettingsProductsTab: React.FC<MaterialSettingsProductsTabProps> = ({
  material
}) => {
  const {t} = useTranslation()
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')
  const isMaterialSaving = !!useIsMutating(['editMaterial'])

  const {control, watch} = useFormContext<MaterialFormDefaultValues>()
  const currentSource: MaterialSource | null = watch('source')

  const {data: materials} = useMaterialsQuery()
  if (!materials) {
    throw new Error('BUG: materials should be loaded before rendering sap codes tab')
  }

  const {data: materialsProducts} = useMaterialsProductsQuery(
    currentSource ?? MaterialSource.BoughtFromVendor
  )
  if (!materialsProducts) {
    throw new Error('BUG: material products should be loaded before rendering sap codes tab')
  }

  const {
    fields: formProducts,
    append: appendProduct,
    remove: removeProduct,
    update: updateProduct
  } = useFieldArray({
    control,
    name: 'products',
    keyName: 'formProductId'
  })

  // It shouldn't be possible to attach the same product twice
  const allProducts = Object.values(materialsProducts).flat()
  const allAvailableProducts = getAvailableProducts({
    products: allProducts,
    productsToExclude: material.products
  })

  const isAddNewProductDisabled =
    !canChangeMaterials || isMaterialSaving || allAvailableProducts.length === 0
  const isRemoveProductDisabled = !canChangeMaterials || isMaterialSaving

  const isProducedOrBoughtAndProducedMaterial = [
    MaterialSource.ProducedInPlant,
    MaterialSource.BoughtAndProduced
  ].includes(material.source)

  return (
    <Stack spacing={3} {...dataTestId('attached_products')}>
      <Box>
        <Button
          variant="text"
          startIcon={<Add />}
          disabled={isAddNewProductDisabled}
          onClick={() => {
            const [firstProduct] = allAvailableProducts
            if (firstProduct) {
              appendProduct(getNewFormProduct(firstProduct.type))
            }
          }}
          {...dataTestId('add_material_code')}
        >
          {t('materialsSettings.addCode')}
        </Button>
      </Box>
      <Stack spacing={3}>
        {formProducts.map((formProduct, index) => {
          const isAlreadyAttachedProduct = !!(
            formProduct.id && material.products.some(({id}) => id === formProduct.id)
          )
          const shouldSalesForecastInfoBeDisplayed =
            isAlreadyAttachedProduct &&
            isProducedOrBoughtAndProducedMaterial &&
            formProduct.type !== ProductType.MARERIAL_SAP
          return (
            <Stack
              key={formProduct.formProductId}
              spacing={0.5}
              {...dataTestId('material_product_row')}
            >
              <MaterialFormProduct
                index={index}
                formProduct={formProduct}
                isAlreadyAttachedProduct={isAlreadyAttachedProduct}
                productsByType={materialsProducts}
                materials={materials}
                material={material}
                isRemoveProductDisabled={isRemoveProductDisabled}
                removeProduct={removeProduct}
                updateProduct={updateProduct}
              />
              {shouldSalesForecastInfoBeDisplayed && (
                <SalesForecastInfo productId={formProduct.id} materialId={material.id} />
              )}
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}
