import {HPCommentItem} from '@hconnect/uikit/src/lib2/components/comment'
import {Typography, Box} from '@mui/material'
import moment from 'moment'

import {Comment} from '../../types'
import {useTranslation} from 'react-i18next'

export const ListComment = ({
  comment,
  onClick
}: {
  comment: Comment
  onClick: (id: string) => void
}) => {
  const {t} = useTranslation()
  const {userName: user, kpiId, value, kpiName, commentDate, id} = comment

  return (
    <Box sx={{cursor: 'pointer'}} onClick={() => onClick(kpiId)}>
      <HPCommentItem
        userName={user}
        defaultUserName={t('common.systemUser')}
        commentDate={moment.utc(commentDate).local()}
        title={kpiName}
        content={<Typography variant="body1">{value}</Typography>}
        data-test-id={`kpi-comment-${id}`}
      />
    </Box>
  )
}
