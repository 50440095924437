import {MaterialWithRecipes, RecipeComponentType, Status} from '@hconnect/common/types'

import {getNewFormRecipeComponent} from './getNewFormRecipeComponent'

import type {NewRecipe} from '@settings/modules/materials/types'

export const getNewFormRecipe = (material: MaterialWithRecipes): NewRecipe => {
  return {
    id: null,
    name: '',
    mainMaterialId: material.id,
    status: Status.Created,
    components: [
      getNewFormRecipeComponent({type: RecipeComponentType.Output, fraction: 1, material})
    ]
  }
}
