import {MaterialWithRecipes} from '@hconnect/common/types'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm, UseFormReturn} from 'react-hook-form'

import {
  useMaterialFormDefaultValues,
  MaterialFormDefaultValues
} from './useMaterialFormDefaultValues'
import {useMaterialFormValidationSchema} from './useMaterialFormValidationSchema'

export const useMaterialForm = (
  material: MaterialWithRecipes
): UseFormReturn<MaterialFormDefaultValues> => {
  const materialFormDefaultValues = useMaterialFormDefaultValues(material)

  const materialFormValidationSchema = useMaterialFormValidationSchema(
    material,
    materialFormDefaultValues
  )

  const materialForm = useForm<MaterialFormDefaultValues>({
    mode: 'onSubmit',
    shouldFocusError: false,
    resolver: zodResolver(materialFormValidationSchema),
    defaultValues: materialFormDefaultValues
  })

  return materialForm
}
