import {useRef} from 'react'
import {visitParents} from 'unist-util-visit-parents'

import {useSettingsQuery} from '@settings/modules/common/providers'
import {
  StructureNode,
  StructureNodeUnist,
  StructureNodeUnistWithAncestors
} from '@settings/modules/common/types'
import {useUrlParam} from '@settings/modules/routing'
import {mapToStructureWithCodesPath} from '../../mappers/plantStructure.mappers'

const getUnistNodes = (
  node: StructureNode
): {tree: StructureNodeUnist; flatNodes: StructureNodeUnistWithAncestors[]} => {
  const flatNodes: StructureNodeUnistWithAncestors[] = []

  const transformToUnistNode = (node: StructureNode): StructureNodeUnist => {
    return {
      type: node.entityType,
      data: {
        id: node.id,
        upmId: node.upmId,
        name: node.name,
        signalCount: node.signalCount,
        entityProperties: node.entityProperties,
        entityLabel: node.entityLabel
      },
      children: node.children ? node.children.map(transformToUnistNode) : [],
      entityType: node.entityType
    }
  }

  const tree = transformToUnistNode(node)

  visitParents(tree, (node, ancestors) => {
    flatNodes.push({
      ...node,
      ancestors
    })
  })

  return {
    tree,
    flatNodes
  }
}

export const useGetUnistPlantStructure = () => {
  // we use the full structure
  const {data} = useGetPlantStructure({includeEquipment: true, product: undefined})
  const unistStructureRef = useRef<ReturnType<typeof getUnistNodes> | undefined>()

  // this operation is so heavy that it must NEVER run after initial load as it can crash the browser
  // the structure has potentially hundreds of thousands of nodes so we must never traverse it on client side
  // TODO: extend BE structure endpoint to return also flat structure so we dont have to do this here
  if (!unistStructureRef.current && data) {
    unistStructureRef.current = getUnistNodes(data)
  }

  return unistStructureRef.current
}

export const useGetPlantStructure = ({
  includeEquipment,
  product,
  addCodesPath
}: {
  includeEquipment: boolean
  product?: string
  addCodesPath?: boolean
}) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('upmStructure', [plantCode, includeEquipment, product], {
    staleTime: Infinity,
    keepPreviousData: true,
    select: (data) => (addCodesPath ? mapToStructureWithCodesPath(data) : data)
  })
}
