import {dataTestId} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {Check, Clear} from '@mui/icons-material'
import {Button, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

interface MaterialDetailsCardHeaderProps {
  headerTitle: string
  headerSubtitle?: string
  isCancelDisabled: boolean
  isSaveDisabled: boolean
  onCancelClick: () => void
  onSaveClick: () => Promise<void>
}

export const MaterialDetailsCardHeader: React.FC<MaterialDetailsCardHeaderProps> = ({
  headerTitle,
  headerSubtitle,
  isCancelDisabled,
  isSaveDisabled,
  onCancelClick,
  onSaveClick
}) => {
  const {t} = useTranslation()
  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <CardTitle variant="h3" sx={{mb: 0}}>
          {headerTitle}
        </CardTitle>
        {headerSubtitle && <Typography variant="body1">{headerSubtitle}</Typography>}
      </Stack>
      <Stack direction="row" spacing={1.5}>
        <Button
          color="secondary"
          startIcon={<Clear />}
          disabled={isCancelDisabled}
          onClick={onCancelClick}
          {...dataTestId('material_form_cancel_button')}
        >
          {t('common.cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<Check />}
          disabled={isSaveDisabled}
          onClick={() => void onSaveClick()}
          {...dataTestId('material_form_save_button')}
        >
          {t('common.save')}
        </Button>
      </Stack>
    </Stack>
  )
}
