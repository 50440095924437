import {ProductType} from '@hconnect/common/types'
import type {Product, NewProduct, MaterialWithRecipes, Material} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Alert, NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {Box} from '@material-ui/core'
import {DeleteOutlined, Done} from '@mui/icons-material'
import {Typography, Stack} from '@mui/material'
import React from 'react'
import {useFormContext, UseFieldArrayRemove, UseFieldArrayUpdate} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useUrlParam} from '../../../../../modules/routing'
import {ControlledAutocomplete, DeleteButton} from '../../../../common/components'
import {removeSettingsQuery, useConfirmDialog} from '../../../../common/providers'
import {requiredValidator} from '../../../../common/utils'
import {MaterialFormDefaultValues} from '../../materials-details/ExistingMaterialDetails/hooks'

import {getAvailableProducts, getNewFormProduct, getMaterialWithAttachedProduct} from './helpers'

interface MaterialFormProductProps {
  index: number
  formProduct: Product | NewProduct
  productsByType: Record<ProductType, Product[]>
  isAlreadyAttachedProduct: boolean
  isRemoveProductDisabled: boolean
  materials: Material[]
  material: MaterialWithRecipes
  updateProduct: UseFieldArrayUpdate<MaterialFormDefaultValues, 'products'>
  removeProduct: UseFieldArrayRemove
}

export const MaterialFormProduct: React.FC<MaterialFormProductProps> = ({
  index,
  formProduct,
  productsByType,
  isAlreadyAttachedProduct,
  isRemoveProductDisabled,
  materials,
  material,
  updateProduct,
  removeProduct
}) => {
  const {t} = useTranslation()
  const {openDialog} = useConfirmDialog()
  const plantCode = useUrlParam('plantCode')

  const {control, getValues} = useFormContext<MaterialFormDefaultValues>()
  const currentProducts = getValues('products')

  // Getting options for product type dropdown
  const productTypes = Object.keys(productsByType).sort() as ProductType[]

  // Getting options for product code dropdown
  const productsForCurrentProductType = productsByType[formProduct.type]
  const availableProducts = getAvailableProducts({
    products: productsForCurrentProductType,
    productsToExclude: currentProducts,
    productsToInclude: [formProduct]
  })
  const availableProductCodes = availableProducts.map(({code}) => code)

  // Creating a map of products by code for easier access later
  const productsByCode = Object.fromEntries(
    productsForCurrentProductType.map((product) => [product.code, product])
  )

  const handleDeleteProduct = () => {
    if (!isAlreadyAttachedProduct) {
      removeProduct(index)
      return
    }
    openDialog({
      title: t('materialsSettings.deleteMaterialCode'),
      description: t('materialsSettings.deleteMaterialCodeConfirmation', {name: formProduct.code}),
      mainAction: {
        text: t('common.delete'),
        color: 'error',
        icon: <DeleteOutlined />,
        onAction: () => {
          removeProduct(index)
        }
      }
    })
  }

  const checkForConflictedProduct = (product: Product) => {
    const conflictedMaterial = getMaterialWithAttachedProduct(product.id, materials)
    if (conflictedMaterial) {
      openDialog({
        title: t('common.conflict'),
        description: t('materialsSettings.productAlreadyAttachedDescription', {
          materialName: conflictedMaterial.name
        }),
        mainAction: {
          text: t('materialsSettings.assignProductCode'),
          icon: <Done />,
          onAction: () => {
            removeSettingsQuery('materialWithRecipes', plantCode, conflictedMaterial.id)
          }
        },
        onCancel: () => {
          removeProduct(index)
        },
        additionalContent: (
          <>
            <Alert
              accentColor="error"
              content={t('materialsSettings.productAlreadyAttachedAdditionalInfo', {
                thisMaterialName: material.name,
                otherMaterialName: conflictedMaterial.name
              })}
            />
            <Typography variant="body1" sx={{mt: 2}}>
              {t('materialsSettings.productAlreadyAttachedConfirmation')}
            </Typography>
          </>
        ),
        testId: 'conflict_product_already_attached'
      })
      return
    }
  }

  return (
    <Stack direction="row" alignItems="center" spacing={2} {...dataTestId('product_form')}>
      <NumberLetterSequenceIndicator numberIndex={index} />
      {productTypes.length > 1 && (
        <Box sx={{width: 310}}>
          <ControlledAutocomplete
            control={control}
            formDataName={`products.${index}.type`}
            options={productTypes}
            label={t('materialsSettings.productCodeType')}
            rules={requiredValidator(t)}
            disabled={isAlreadyAttachedProduct}
            translateOption={(option) => t(`materialsSettings.productTypes.${option}`)}
            onChangeCb={() => {
              updateProduct(index, getNewFormProduct(getValues(`products.${index}.type`)))
            }}
            {...dataTestId('product_type_select')}
          />
        </Box>
      )}
      <ControlledAutocomplete
        control={control}
        formDataName={`products.${index}.code`}
        options={availableProductCodes}
        label={t('materialsSettings.product')}
        rules={requiredValidator(t)}
        disabled={isAlreadyAttachedProduct}
        placeholder={t('materialsSettings.productAutocompletePlaceholder')}
        renderOption={(props, code) => (
          <Stack
            {...props}
            component="li"
            spacing={0.5}
            sx={{
              '&.MuiAutocomplete-option': {
                alignItems: 'flex-start'
              }
            }}
          >
            <Typography variant="subtitle1">{code}</Typography>
            <Typography variant="caption">{productsByCode[code].name}</Typography>
          </Stack>
        )}
        getOptionLabel={(code) => {
          return code ? `${code} ${productsByCode[code].name}` : ''
        }}
        onChangeCb={() => {
          const currentProductCode = getValues(`products.${index}.code`)
          if (!currentProductCode) return

          const currentProduct = productsByCode[currentProductCode]
          updateProduct(index, currentProduct)

          checkForConflictedProduct(currentProduct)
        }}
        {...dataTestId('product_code_input')}
      />
      <DeleteButton
        onClick={handleDeleteProduct}
        disabled={isRemoveProductDisabled}
        {...dataTestId('delete_product')}
      />
    </Stack>
  )
}
