import {NewLimsMaterial, LimsMaterial} from '@hconnect/common/types'

interface GetAvailableLimsMaterialsProps {
  limsMaterials: LimsMaterial[]
  limsMaterialsToExclude?: (LimsMaterial | NewLimsMaterial)[]
  limsMaterialsToInclude?: (LimsMaterial | NewLimsMaterial)[]
}

export const getAvailableLimsMaterials = ({
  limsMaterials,
  limsMaterialsToExclude = [],
  limsMaterialsToInclude = []
}: GetAvailableLimsMaterialsProps) => {
  const limsMaterialsIdsToExclude = limsMaterialsToExclude.map(({id}) => id)
  const limsMaterialsToIncludeIds = limsMaterialsToInclude.map(({id}) => id)

  return limsMaterials.filter((limsMaterial) => {
    return (
      !limsMaterialsIdsToExclude.includes(limsMaterial.id) ||
      limsMaterialsToIncludeIds.includes(limsMaterial.id)
    )
  })
}
