import {isProducingMode, type Asset, type Recipe} from '@hconnect/common/types'

export const validateAssetOperationModesHaveExistingRecipes = (
  assets: Asset[],
  recipes: Recipe[]
) => {
  const operationModes = assets.flatMap((asset) => asset.operationModes).filter(isProducingMode)
  const recipeIdSet = new Set(recipes.map((recipe) => recipe.id))
  for (const mode of operationModes) {
    if (!recipeIdSet.has(mode.recipeId)) {
      throw new Error(
        `Asset operation mode with id ${mode.id} has recipe id ${mode.recipeId} which does not exist or is deleted.`
      )
    }
  }
}
