import {AssetType} from '@hconnect/common/types'
import type {Asset} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit/src/common'
import {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../api/mutations'
import {
  getSettingsQueryData,
  invalidateSettingsQuery,
  removeSettingsQuery,
  setSettingsQueryData
} from '../../../common/providers/ApiInterface'
import {settingsQueryClient} from '../../../common/providers/QueryProvider'
import {ErrorResponse} from '../../../common/types'
import {notifyIfErrorMessage} from '../../../common/utils/errorHandling'
import {getStandardOperationTimes} from '../../utils'

export const useDeleteOperationMode = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    void,
    AxiosError<ErrorResponse>,
    Parameters<typeof mutations.deleteOperationMode>[0],
    {updatedAsset: Asset}
  >(mutations.deleteOperationMode, {
    onMutate: (variables) => {
      const prevAssets = getSettingsQueryData('assets', [variables.plantCode])
      if (!prevAssets) throw new Error('Assets should be loaded to query before using this hook')

      const assetToUpdate = prevAssets.find((asset) => asset.id === variables.assetId)
      if (!assetToUpdate)
        throw new Error(
          'BUG: operation modes are attached to assets, its not possible to delete an operation mode from a non existing asset'
        )

      const updatedAsset = {
        ...assetToUpdate,
        operationModes: assetToUpdate.operationModes.filter(
          (operationMode) => operationMode.id !== variables.operationModeId
        )
      }
      return {updatedAsset}
    },
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.detail, raiseError, notify)
    },
    onSuccess: (data, variables, context) => {
      notify('success', t('success.deleteOperationMode'))
      const prevAssets = getSettingsQueryData('assets', [variables.plantCode])
      const updatedAsset = context?.updatedAsset
      if (!prevAssets || !updatedAsset) return

      // updating assets query
      void settingsQueryClient.setQueryData(
        ['assets', variables.plantCode],
        prevAssets.map((asset) => (asset.id === variables.assetId ? updatedAsset : asset))
      )

      // invalidating switch matrix query if asset is cement type
      if (updatedAsset.type === AssetType.CementMill) {
        invalidateSettingsQuery('assetsTransitions', variables.plantCode)
      }

      // if there are some standard operation times affected, we need to remove them from query state
      const assetsIds = prevAssets.map(({id}) => id)
      const prevStandardOperationTimes = getSettingsQueryData('assetsStandardOperationTimes', [
        {assetsIds, plantCode: variables.plantCode}
      ])

      const standardOperationTimesIdsToDelete = getStandardOperationTimes(
        variables.operationModeId,
        prevStandardOperationTimes?.[variables.assetId]
      ).map(({id}) => id)

      if (standardOperationTimesIdsToDelete.length > 0 && prevStandardOperationTimes) {
        setSettingsQueryData(
          'assetsStandardOperationTimes',
          [{assetsIds, plantCode: variables.plantCode}],
          {
            ...prevStandardOperationTimes,
            [variables.assetId]: prevStandardOperationTimes[variables.assetId].filter(
              (standardOperationTime) =>
                !standardOperationTimesIdsToDelete.includes(standardOperationTime.id)
            )
          }
        )
      }

      invalidateSettingsQuery('assetsTransitions', variables.plantCode)
      removeSettingsQuery('schedule')
    }
  })
}
