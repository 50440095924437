import {Material} from '@hconnect/common/types'
import {Stack} from '@mui/material'
import {UseFieldArrayRemove, UseFieldArrayUpdate} from 'react-hook-form'

import {MaterialFormDefaultValues} from '../../../materials-details/ExistingMaterialDetails/hooks'
import {IndexedFormArrayRecipeComponent} from '../types'

import {MaterialFormRecipeComponent} from './MaterialFormRecipeComponent'

import {useUrlParam} from '@settings/modules/routing'

interface RecipeComponentListProps {
  formRecipeComponents: IndexedFormArrayRecipeComponent[]
  fieldArrayRecipeIndex: number
  materials: Material[]
  materialIdsUsedInComponents: (number | null)[]
  disabled: boolean
  removeRecipeComponent: UseFieldArrayRemove
  updateRecipeComponent: UseFieldArrayUpdate<
    MaterialFormDefaultValues,
    `recipes.${number}.components`
  >
}

export const RecipeComponentList: React.FC<RecipeComponentListProps> = ({
  formRecipeComponents,
  fieldArrayRecipeIndex,
  materials,
  materialIdsUsedInComponents,
  disabled,
  removeRecipeComponent,
  updateRecipeComponent
}) => {
  const materialId = Number(useUrlParam('materialId'))
  return (
    <Stack spacing={3}>
      {formRecipeComponents.map((formRecipeComponent, index) => {
        const isDefaultOutputComponent = formRecipeComponent.material.id === materialId

        const isEditMaterialDisabled = disabled || isDefaultOutputComponent

        const isEditFractionDisabled =
          disabled || (isDefaultOutputComponent && formRecipeComponents.length === 1)

        const isRemoveComponentDisabled = disabled || isDefaultOutputComponent

        const availableMaterials = materials.filter(
          (material) =>
            !materialIdsUsedInComponents.includes(material.id) ||
            material.id === formRecipeComponent.material.id
        )
        const availableMaterialsMap = Object.fromEntries(
          availableMaterials.map((material) => [material.id, material.name])
        )

        return (
          <MaterialFormRecipeComponent
            key={formRecipeComponent.formRecipeComponentId}
            letterSequenceIndex={index}
            fieldArrayRecipeIndex={fieldArrayRecipeIndex}
            fieldArrayRecipeComponentIndex={formRecipeComponent.fieldArrayRecipeComponentIndex}
            formRecipeComponent={formRecipeComponent}
            availableMaterialsMap={availableMaterialsMap}
            isEditMaterialDisabled={isEditMaterialDisabled}
            isEditFractionDisabled={isEditFractionDisabled}
            isRemoveComponentDisabled={isRemoveComponentDisabled}
            removeRecipeComponent={removeRecipeComponent}
            updateRecipeComponent={updateRecipeComponent}
          />
        )
      })}
    </Stack>
  )
}
