import {PermissionType} from '@hconnect/apiclient'

import {StructureNode, UpmEntityType} from '../../../../common/types'
import {departmentsWithConfig} from '../../../consts'

export const plantStructureTabs = [
  'layout',
  'sensors',
  'kpis',
  'operationModes',
  'standardTimes',
  'general',
  'configuration'
] as const

export type PlantStructureTab = (typeof plantStructureTabs)[number]

export const commonTabs: PlantStructureTab[] = [
  'sensors',
  'kpis',
  'operationModes',
  'standardTimes'
]

export const upmEntityTabsMap: Record<UpmEntityType, PlantStructureTab[]> = {
  Plant: ['general', ...commonTabs],
  Area: commonTabs,
  ProductionLine: commonTabs,
  Department: ['general', 'configuration', 'layout', ...commonTabs],
  Equipment: ['general', ...commonTabs]
}

export const plantStructureTabPermissions: Record<PlantStructureTab, PermissionType[] | null> = {
  general: null,
  layout: ['CREATE_MASTER_DATA'],
  sensors: ['VIEW_UPM'],
  configuration: ['VIEW_UPM', 'CREATE_MASTER_DATA', 'VIEW_MATERIALS'],
  kpis: null,
  operationModes: null,
  standardTimes: null
}

export const plantStructureTabCustomFilter: Record<
  PlantStructureTab,
  ({selectedItem}: {selectedItem: StructureNode}) => boolean
> = {
  general: () => true,
  configuration: ({selectedItem}) =>
    selectedItem.entityType === 'Department' &&
    departmentsWithConfig.some((department) => selectedItem.entityCodesPath === department),
  layout: ({selectedItem}) => selectedItem.name === 'Kiln',
  sensors: () => true,
  kpis: () => true,
  operationModes: () => true,
  standardTimes: () => true
}
