import {EditKpiStatus, Status, WarningMessage, UnitType} from '../enums'

export enum KpiType {
  Manual = 1,
  Calculated = 2
}

export interface Unit {
  unitName: string
  abbvr?: string
  id: UnitType
}

export interface DailyKpiInfo {
  id: string
  name: string
  unit: Unit
  kpiType: KpiType
  value: string | null
  versions: number
  version: number
  comments: number
  index: number
}

export type EditMonthlyKpi = {
  specificKpiId: string
  version: number
  value?: string
  date: string
}

export type EditedKpi = {
  status: EditKpiStatus
  id: string
}
export type EditedMonthlyKpi = EditedKpi & {
  specificKpiId: string
}

export type EditedFinalKpiValue = {
  id: string
  specificKpiId: string
  status: EditKpiStatus
}

export type EditKpiResponse = {
  janusDailyKpis: EditedKpi[]
}

export type CumulatedKpisResponse = {
  kpis: CumulatedKpi[]
  monthlySums: MonthlySum[]
  finalValues: FinalValue[]
  warnings: WarningMessage[]
}

export interface CumulatedKpi {
  id: string
  date: string
  values: CumulatedKpiValue[]
  status: Status
}

export interface CumulatedKpiValue {
  id: string
  value: string
  name: string
  specificKpiId: string
  commentCount: number
  modificationDate: string
}

export interface MonthlySum {
  specificKpiId: string
  value: number
  adjustment: number
  version: number
}

export interface FinalValue {
  specificKpiId: string
  value: string
  adjustment: number
  version: number
  unitTypeId: UnitType
}
