import {ProductForecastStatus} from '@hconnect/common/types'
import {useNotification} from '@hconnect/uikit/src/common'
import type {AxiosError} from 'axios'
import {useErrorHandler} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {mutations} from '../../../../../api/mutations'
import {
  getSettingsQueryData,
  invalidateSettingsQuery,
  setSettingsQueryData
} from '../../../../common/providers/ApiInterface'
import {notifyIfErrorMessage} from '../../../../common/utils/errorHandling'

export const useRequestProductSalesForecast = () => {
  const {notify} = useNotification()
  const raiseError = useErrorHandler()
  const {t} = useTranslation()

  return useMutation<
    void,
    AxiosError<{message: string}>,
    Parameters<typeof mutations.requestSalesForecast>[0]
  >(mutations.requestSalesForecast, {
    onError: (error) => {
      notifyIfErrorMessage(error.response?.data.message, raiseError, notify)
    },
    onSuccess: (data, {plantCode, productId, materialId}) => {
      notify('success', t('success.requestProductSalesForecast'))
      const prevData = getSettingsQueryData('productsSalesForecastInfo', [plantCode, materialId])
      if (prevData) {
        const newData = {
          ...prevData,
          [productId]: {status: ProductForecastStatus.Requested}
        }
        setSettingsQueryData('productsSalesForecastInfo', [plantCode, materialId], newData)
      }
      invalidateSettingsQuery('productsSalesForecastInfo', plantCode, materialId)
    }
  })
}
