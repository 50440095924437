import {Material} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {FormHelperText} from '@material-ui/core'
import ClearIcon from '@mui/icons-material/Clear'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Button
} from '@mui/material'
import React from 'react'
import {Controller, Control, Path, FieldValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ChipInput} from './ChipInput'

interface FuelSelectionProps<T extends FieldValues> {
  control: Control<T>
  fuels: Material[]
  selectedItem?: {upmId: string}
  name: Path<T>
  label: string
  testId?: string
}

export const FuelSelection = <T extends FieldValues>({
  control,
  fuels,
  label,
  testId,
  name
}: FuelSelectionProps<T>): JSX.Element => {
  const {t} = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: t('error.required')
      }}
      render={({field: {ref, value, onChange}, fieldState: {error}}) => {
        const helperText = (error && error.message) || ''
        return (
          <FormControl error={!!error}>
            <InputLabel id={`${label}-label`}>{t(label)}</InputLabel>
            <Select
              multiple
              labelId={`${label}-label`}
              label={t(label)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 420
                  }
                }
              }}
              inputRef={ref}
              onChange={(e) => {
                const selectedValues = e.target.value
                onChange(selectedValues.join('";"'))
              }}
              {...dataTestId(testId || '')}
              value={value ? value.split('";"') : []}
              renderValue={(selected) => (
                <ChipInput
                  options={selected}
                  onRemove={(option) =>
                    onChange(selected.filter((item) => item !== option).join('";"'))
                  }
                  key={`${label}-selected-fuels`}
                />
              )}
            >
              {fuels.map((option) => {
                const currentValue = value ? value.split('";"') : []
                return (
                  <MenuItem key={`${label}-${option.id}`} value={option.name}>
                    <Checkbox checked={currentValue.includes(option.name)} />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                )
              })}
              <Box display="flex" alignItems="center" justifyContent="center" paddingTop={2}>
                <Button sx={{width: '90%'}} onClick={() => onChange('')} color="secondary">
                  <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                    <ClearIcon color="secondary" />
                    {t('janusConfig.plantSetup.clearAll')}
                  </Box>
                </Button>
              </Box>
            </Select>
            <FormHelperText error={!!error}>{helperText}</FormHelperText>
          </FormControl>
        )
      }}
    />
  )
}
