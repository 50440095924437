import {MaterialWithRecipes} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Add} from '@mui/icons-material'
import {Box, Button, Stack} from '@mui/material'
import React from 'react'
import {useFieldArray, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useIsMutating} from 'react-query'

import {usePermission} from '../../../../permissions'
import {MaterialFormDefaultValues} from '../../materials-details/ExistingMaterialDetails/hooks'

import {MaterialFormPxTrend} from './MaterialFormPxTrend'

interface MaterialSettingsPxTrendTabProps {
  material: MaterialWithRecipes
}

export const MaterialSettingsPxTrendTab: React.FC<MaterialSettingsPxTrendTabProps> = ({
  material
}) => {
  const {t} = useTranslation()
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')
  const isMaterialSaving = !!useIsMutating(['editMaterial'])

  const {control} = useFormContext<MaterialFormDefaultValues>()

  const {
    fields: formPxTrendCounters,
    append: appendPxTrendCounter,
    remove: removePxTrendCounter
  } = useFieldArray({
    control,
    name: 'pxTrendCounters',
    keyName: 'formPxTrendCounterId'
  })

  const isPxTrendCounterDisabled = !canChangeMaterials || isMaterialSaving

  return (
    <Stack spacing={3} {...dataTestId('px_trend_counters_card')}>
      <Box>
        <Button
          variant="text"
          startIcon={<Add />}
          disabled={isPxTrendCounterDisabled}
          onClick={() => {
            appendPxTrendCounter({name: ''})
          }}
          {...dataTestId('add_px_trend')}
        >
          {t('materialsSettings.addTag')}
        </Button>
      </Box>
      <Stack spacing={3}>
        {formPxTrendCounters.map((formPxTrendCounter, index) => (
          <MaterialFormPxTrend
            key={formPxTrendCounter.formPxTrendCounterId}
            index={index}
            formPxTrendCounter={formPxTrendCounter}
            isPxTrendCounterDisabled={isPxTrendCounterDisabled}
            material={material}
            removePxTrendCounter={removePxTrendCounter}
          />
        ))}
      </Stack>
    </Stack>
  )
}
