import {FuelsClassificationInputNames} from '@hconnect/common/enums'
import {MaterialType} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {Stack} from '@mui/material'
import {useFormContext} from 'react-hook-form'

import {MaterialFormDefaultValues} from '../../materials-details/ExistingMaterialDetails/hooks'
import {useMaterialsClassificationMetadata} from '../../../../../modules/materials/hooks'

import {ClassificationAutocomplete} from './inputs/ClassificationAutocomplete'
import {ClassificationSegmentedButton} from './inputs/ClassificationSegmentedButton'

interface FuelsClassificationProps {
  isDisabled: boolean
}

export const FuelsClassification: React.FC<FuelsClassificationProps> = ({isDisabled}) => {
  const {data: metadata} = useMaterialsClassificationMetadata(MaterialType.Fuels)
  const {setValue, getValues, clearErrors} = useFormContext<MaterialFormDefaultValues>()

  if (!metadata) {
    throw new Error(
      'BUG: classification metadata should be loaded before rendering classification tab'
    )
  }

  return (
    <Stack
      {...dataTestId('fuels_classification_form')}
      spacing={2}
      sx={({spacing}) => ({maxWidth: spacing(49), height: spacing(43)})}
    >
      <ClassificationSegmentedButton
        materialType={MaterialType.Fuels}
        inputName={FuelsClassificationInputNames.FuelType}
        metadata={metadata}
        isDisabled={isDisabled}
        onChangeCb={() => {
          setValue('classification', {
            ...getValues('classification'),
            [FuelsClassificationInputNames.FuelCategory]: null,
            [FuelsClassificationInputNames.FuelSubCategory]: null,
            [FuelsClassificationInputNames.FormSize]: null,
            [FuelsClassificationInputNames.ProcessingDegree]: null
          })
          clearErrors([
            `classification.${FuelsClassificationInputNames.FuelCategory}`,
            `classification.${FuelsClassificationInputNames.FuelSubCategory}`,
            `classification.${FuelsClassificationInputNames.FormSize}`,
            `classification.${FuelsClassificationInputNames.ProcessingDegree}`
          ])
        }}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.Fuels}
        inputName={FuelsClassificationInputNames.FuelCategory}
        metadata={metadata}
        isDisabled={isDisabled}
        onChangeCb={() => {
          setValue('classification', {
            ...getValues('classification'),
            [FuelsClassificationInputNames.FuelSubCategory]: null,
            [FuelsClassificationInputNames.FormSize]: null,
            [FuelsClassificationInputNames.ProcessingDegree]: null
          })
          clearErrors([
            `classification.${FuelsClassificationInputNames.FuelSubCategory}`,
            `classification.${FuelsClassificationInputNames.FormSize}`,
            `classification.${FuelsClassificationInputNames.ProcessingDegree}`
          ])
        }}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.Fuels}
        inputName={FuelsClassificationInputNames.FuelSubCategory}
        metadata={metadata}
        isDisabled={isDisabled}
        onChangeCb={() => {
          setValue('classification', {
            ...getValues('classification'),
            [FuelsClassificationInputNames.FormSize]: null,
            [FuelsClassificationInputNames.ProcessingDegree]: null
          })
          clearErrors([
            `classification.${FuelsClassificationInputNames.FormSize}`,
            `classification.${FuelsClassificationInputNames.ProcessingDegree}`
          ])
        }}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.Fuels}
        inputName={FuelsClassificationInputNames.FormSize}
        metadata={metadata}
        isDisabled={isDisabled}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.Fuels}
        inputName={FuelsClassificationInputNames.ProcessingDegree}
        metadata={metadata}
        isDisabled={isDisabled}
      />
    </Stack>
  )
}
