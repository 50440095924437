import {
  AdditivesForClinkerClassificationInputNames,
  CementClassificationInputNames,
  RawMaterialsClassificationInputNames,
  EnhancersForCementClassificationInputNames,
  ClinkerClassificationInputNames,
  FuelsClassificationInputNames,
  IntermediateAndFinishedProductsClassificationInputNames
} from '@hconnect/common/enums'
import type {
  AutocompleteOption,
  LimsMaterial,
  NewLimsMaterial,
  ClassificationDTO,
  Recipe,
  MaterialSource,
  MaterialWithRecipes,
  Product,
  NewProduct,
  ArrayElement
} from '@hconnect/common/types'
import {MaterialBoughtFrom, MaterialType} from '@hconnect/common/types'
import {FormState} from 'react-hook-form'

import type {Nullable} from '../../../../../common/types'
import {useGlobalMaterialsQuery} from '../../../../hooks'

import type {NewRecipe} from '@settings/modules/materials/types'

type MaterialFormClassificationDTO = Nullable<Omit<ClassificationDTO, 'materialType'>>

export type MaterialFormPxTrendCounter = {
  name: string
}

export type MaterialFormDefaultValues = {
  type: MaterialType
  name: string
  description: string
  globalMaterial: AutocompleteOption | null
  source: MaterialSource
  boughtFrom: MaterialBoughtFrom
  classification: MaterialFormClassificationDTO
  products: (Product | NewProduct)[]
  limsMaterials: (LimsMaterial | NewLimsMaterial)[]
  recipes: (Recipe | NewRecipe)[]
  pxTrendCounters: MaterialFormPxTrendCounter[]
}

type MaterialFormArrayDirtyFields = FormState<MaterialFormDefaultValues>['dirtyFields'][
  | 'products'
  | 'limsMaterials'
  | 'pxTrendCounters']

export type MaterialFormObjectDirtyFields = FormState<MaterialFormDefaultValues>['dirtyFields'][
  | 'classification'
  | 'globalMaterial'] &
  ArrayElement<MaterialFormArrayDirtyFields>

export const getEmptyClassification = (type: MaterialType) => {
  const emptyClassification: MaterialFormClassificationDTO = {}
  switch (type) {
    case MaterialType.Cement: {
      Object.values(CementClassificationInputNames).forEach((inputName) => {
        emptyClassification[inputName] = null
      })
      emptyClassification[CementClassificationInputNames.IntermediateMillProduct] = 'No'
      break
    }
    case MaterialType.RawMaterials: {
      Object.values(RawMaterialsClassificationInputNames).forEach((inputName) => {
        emptyClassification[inputName] = null
      })
      emptyClassification[
        RawMaterialsClassificationInputNames.IsThermallyProcessedClinkerSubstitute
      ] = 'No'
      emptyClassification[RawMaterialsClassificationInputNames.RawMaterialType] = 'Conventional'
      break
    }
    case MaterialType.Clinker: {
      Object.values(ClinkerClassificationInputNames).forEach((inputName) => {
        emptyClassification[inputName] = null
      })
      emptyClassification[ClinkerClassificationInputNames.AlkaliContent] = '-'
      emptyClassification[ClinkerClassificationInputNames.LowHeat] = '-'
      emptyClassification[ClinkerClassificationInputNames.SulphateResistance] = '-'
      break
    }
    case MaterialType.EnhancersForCement: {
      Object.values(EnhancersForCementClassificationInputNames).forEach((inputName) => {
        emptyClassification[inputName] = null
      })
      break
    }
    case MaterialType.IntermediateAndFinishedProducts: {
      Object.values(IntermediateAndFinishedProductsClassificationInputNames).forEach(
        (inputName) => {
          emptyClassification[inputName] = null
        }
      )
      break
    }
    case MaterialType.AdditivesForClinker: {
      Object.values(AdditivesForClinkerClassificationInputNames).forEach((inputName) => {
        emptyClassification[inputName] = null
      })
      break
    }
    case MaterialType.Fuels: {
      Object.values(FuelsClassificationInputNames).forEach((inputName) => {
        emptyClassification[inputName] = null
      })
      emptyClassification[FuelsClassificationInputNames.FuelType] = 'Conventional'
      break
    }
  }
  return emptyClassification
}

export const getMaterialFormClassification = (
  materialType: MaterialType,
  currentClassification?: ClassificationDTO
): MaterialFormClassificationDTO => {
  if (!currentClassification) {
    return getEmptyClassification(materialType)
  }

  const materialFormClassification: MaterialFormClassificationDTO = {}

  switch (materialType) {
    case MaterialType.Cement: {
      Object.values(CementClassificationInputNames).forEach((cementInputName) => {
        materialFormClassification[cementInputName] = currentClassification[cementInputName] ?? null
      })
      break
    }

    case MaterialType.RawMaterials: {
      Object.values(RawMaterialsClassificationInputNames).forEach((inputName) => {
        materialFormClassification[inputName] = currentClassification[inputName] ?? null
      })
      break
    }
    case MaterialType.Clinker: {
      Object.values(ClinkerClassificationInputNames).forEach((clinkerInputName) => {
        materialFormClassification[clinkerInputName] =
          currentClassification[clinkerInputName] ?? null
      })
      break
    }
    case MaterialType.EnhancersForCement: {
      Object.values(EnhancersForCementClassificationInputNames).forEach((enhancersInputName) => {
        materialFormClassification[enhancersInputName] =
          currentClassification[enhancersInputName] ?? null
      })
      break
    }
    case MaterialType.IntermediateAndFinishedProducts: {
      Object.values(IntermediateAndFinishedProductsClassificationInputNames).forEach(
        (inputName) => {
          materialFormClassification[inputName] = currentClassification[inputName] ?? null
        }
      )
      break
    }
    case MaterialType.AdditivesForClinker: {
      Object.values(AdditivesForClinkerClassificationInputNames).forEach((additivesInputName) => {
        materialFormClassification[additivesInputName] =
          currentClassification[additivesInputName] ?? null
      })
      break
    }
    case MaterialType.Fuels: {
      Object.values(FuelsClassificationInputNames).forEach((inputName) => {
        materialFormClassification[inputName] = currentClassification[inputName] ?? null
      })
      break
    }
  }
  return materialFormClassification
}

export const useMaterialFormDefaultValues = ({
  type,
  name,
  description,
  globalMaterialId,
  origin,
  classification,
  products,
  limsMaterials,
  pxTrendCounters,
  recipes
}: MaterialWithRecipes): MaterialFormDefaultValues => {
  // TODO <HCP-82759>: global material will be removed after material classification is implemented
  const {data: globalMaterialOptions} = useGlobalMaterialsQuery(type)
  if (!globalMaterialOptions) {
    throw new Error('BUG: global materials data should be loaded before using this hook')
  }
  return {
    type,
    name,
    description: description ?? '',
    globalMaterial:
      globalMaterialOptions.find(
        (globalMaterialOption) => Number(globalMaterialOption.id) === globalMaterialId
      ) ?? null,
    source: origin.source,
    boughtFrom: origin.boughtFrom ?? MaterialBoughtFrom.Domestic,
    classification: getMaterialFormClassification(type, classification),
    products,
    limsMaterials,
    recipes,
    pxTrendCounters: pxTrendCounters.map((pxTrendCounter) => ({name: pxTrendCounter}))
  }
}
