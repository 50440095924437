export enum ProductForecastStatus {
  Requested = 'Requested',
  Success = 'Success'
}

export enum Feature {
  Stock = 'stock_screen',
  PriceForecast = 'price_forecast_screen',
  DemandForecast = 'demand_forecast_screen',
  Planning = 'planning_screen',
  ElectricityPurchasing = 'electricity_purchasing',
  ControlOperatorScreen = 'control_room_operator_view',
  PlanningComments = 'planning_comments',
  OperatorViewExactElectricityPrices = 'operator_view_exact_electricity_prices',
  OperatorViewColorCodedElectricityPrices = 'operator_view_color_coded_electricity_prices',
  ShutdownAvailability = 'shutdown_availability',
  CostAvoidance = 'cost_avoidance',
  // TODO: to be removed once standardOperationTimes are new fully implemented
  NewStandardOperationTimes = 'newStandardOperationTimes'
}

export enum MaterialSettingsTabs {
  General = 'general',
  Classification = 'classification',
  SapCodes = 'sapCodes',
  LimsCodes = 'limsCodes',
  Recipes = 'recipes',
  PxTrendTags = 'pxTrendTags'
}

// All classification input names
enum ClassificationInputNames {
  Selection = 'selection',
  Standard = 'standard',
  Const1 = 'const1',
  Const2 = 'const2',
  Strength1 = 'strength1',
  Strength2 = 'strength2',
  SulphurLevel = 'sulphurLevel',
  HeatOfHydration = 'heatOfHydration',
  LowAlkali = 'lowAlkali',
  SpecialCharacteristics = 'specialCharacteristics',
  CategoryForAnnualReport = 'categoryForAnnualReport',
  IntermediateMillProduct = 'intermediateMillProduct',
  MethodOfFinenessDetermination = 'methodOfFinenessDetermination',
  ReferenceMeshSizeForResidue = 'referenceMeshSizeForResidue',
  AlkaliContent = 'alkaliContent',
  Grade = 'grade',
  LowHeat = 'lowHeat',
  IsThermallyProcessedClinkerSubstitute = 'isThermallyProcessedClinkerSubstitute',
  RawMaterialCategory = 'rawMaterialCategory',
  RawMaterialSubCategory = 'rawMaterialSubCategory',
  RawMaterialType = 'rawMaterialType',
  FuelType = 'fuelType',
  FuelCategory = 'fuelCategory',
  FuelSubCategory = 'fuelSubCategory',
  SubCategory = 'subCategory',
  Brand = 'brand',
  OtherBrand = 'otherBrand',
  Category = 'category',
  Type = 'type',
  SulphateResistance = 'sulphateResistance',
  FormSize = 'formSize',
  ProcessingDegree = 'processingDegree'
}

export const CementClassificationInputNames = {
  Standard: ClassificationInputNames.Standard,
  Type: ClassificationInputNames.Type,
  Const1: ClassificationInputNames.Const1,
  Const2: ClassificationInputNames.Const2,
  Strength1: ClassificationInputNames.Strength1,
  Strength2: ClassificationInputNames.Strength2,
  SulphateResistance: ClassificationInputNames.SulphateResistance,
  SulphurLevel: ClassificationInputNames.SulphurLevel,
  HeatOfHydration: ClassificationInputNames.HeatOfHydration,
  LowAlkali: ClassificationInputNames.LowAlkali,
  SpecialCharacteristics: ClassificationInputNames.SpecialCharacteristics,
  CategoryForAnnualReport: ClassificationInputNames.CategoryForAnnualReport,
  IntermediateMillProduct: ClassificationInputNames.IntermediateMillProduct,
  MethodOfFinenessDetermination: ClassificationInputNames.MethodOfFinenessDetermination,
  ReferenceMeshSizeForResidue: ClassificationInputNames.ReferenceMeshSizeForResidue
} as const

export type CementClassificationInputNames =
  (typeof CementClassificationInputNames)[keyof typeof CementClassificationInputNames]

export const ClinkerClassificationInputNames = {
  AlkaliContent: ClassificationInputNames.AlkaliContent,
  Grade: ClassificationInputNames.Grade,
  LowHeat: ClassificationInputNames.LowHeat,
  SulphateResistance: ClassificationInputNames.SulphateResistance,
  Type: ClassificationInputNames.Type
} as const

export type ClinkerClassificationInputNames =
  (typeof ClinkerClassificationInputNames)[keyof typeof ClinkerClassificationInputNames]

export const RawMaterialsClassificationInputNames = {
  Category: ClassificationInputNames.Category,
  IsThermallyProcessedClinkerSubstitute:
    ClassificationInputNames.IsThermallyProcessedClinkerSubstitute,
  RawMaterialCategory: ClassificationInputNames.RawMaterialCategory,
  RawMaterialSubCategory: ClassificationInputNames.RawMaterialSubCategory,
  RawMaterialType: ClassificationInputNames.RawMaterialType,
  FormSize: ClassificationInputNames.FormSize,
  ProcessingDegree: ClassificationInputNames.ProcessingDegree
} as const

export type RawMaterialsClassificationInputNames =
  (typeof RawMaterialsClassificationInputNames)[keyof typeof RawMaterialsClassificationInputNames]

export const FuelsClassificationInputNames = {
  FuelType: ClassificationInputNames.FuelType,
  FuelCategory: ClassificationInputNames.FuelCategory,
  FuelSubCategory: ClassificationInputNames.FuelSubCategory,
  FormSize: ClassificationInputNames.FormSize,
  ProcessingDegree: ClassificationInputNames.ProcessingDegree
} as const

export type FuelsClassificationInputNames =
  (typeof FuelsClassificationInputNames)[keyof typeof FuelsClassificationInputNames]

export const EnhancersForCementClassificationInputNames = {
  Category: ClassificationInputNames.Category,
  SubCategory: ClassificationInputNames.SubCategory,
  Brand: ClassificationInputNames.Brand,
  OtherBrand: ClassificationInputNames.OtherBrand
} as const

export type EnhancersForCementClassificationInputNames =
  (typeof EnhancersForCementClassificationInputNames)[keyof typeof EnhancersForCementClassificationInputNames]

export const AdditivesForClinkerClassificationInputNames = {
  Category: ClassificationInputNames.Category
} as const

export type AdditivesForClinkerClassificationInputNames =
  (typeof AdditivesForClinkerClassificationInputNames)[keyof typeof AdditivesForClinkerClassificationInputNames]

export const IntermediateAndFinishedProductsClassificationInputNames = {
  Category: ClassificationInputNames.Category,
  IntermediateMillProduct: ClassificationInputNames.IntermediateMillProduct,
  MethodOfFinenessDetermination: ClassificationInputNames.MethodOfFinenessDetermination,
  ReferenceMeshSizeForResidue: ClassificationInputNames.ReferenceMeshSizeForResidue,
  Selection: ClassificationInputNames.Selection
} as const

export type IntermediateAndFinishedProductsClassificationInputNames =
  (typeof IntermediateAndFinishedProductsClassificationInputNames)[keyof typeof IntermediateAndFinishedProductsClassificationInputNames]
