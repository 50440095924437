import {
  MaterialType,
  ClassificationInputName,
  ClassificationMetadataEntry,
  ClassificationMetadataValueSet
} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit/src/common/utils/testingUtils'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ControlledTextField} from '../../../../../common/components/form/ControlledTextField'
import {MaterialFormDefaultValues} from '../../../materials-details/ExistingMaterialDetails/hooks'

interface ClassificationTextFieldProps<T extends MaterialType> {
  materialType: T
  inputName: ClassificationInputName<T>
  metadata: ClassificationMetadataEntry<T>
  isDisabled: boolean
  onChangeCb?: () => void
}

/** This component handles a unique case when users must type their values in a free text input.
 *  The metadata values would always be an empty array */
export const ClassificationTextField = <T extends MaterialType>({
  materialType,
  inputName,
  metadata,
  isDisabled,
  onChangeCb
}: ClassificationTextFieldProps<T>) => {
  const {t} = useTranslation()
  const {control, watch} = useFormContext<MaterialFormDefaultValues>()

  const validValueSet = metadata.inputs[inputName].valueSets.find(
    (valueSet: ClassificationMetadataValueSet) => {
      // Agreement with BE: for now if valuesShownWnen is undefined, it's the only valid value set
      if (valueSet.valuesShownWhen === undefined) {
        return true
      }
      // Agreement with BE: for now any field can be dependant only on one other field
      const conditionForValueSet = valueSet.valuesShownWhen[0]

      const conditionCurrentFormValue = watch(
        `classification.${conditionForValueSet.inputName}`
      ) as string | null
      return conditionCurrentFormValue === conditionForValueSet.value
    }
  )

  return (
    <ControlledTextField
      control={control}
      formDataName={`classification.${inputName}`}
      rules={{
        validate: (value) => !validValueSet || !!value || t('error.required')
      }}
      label={t(`materialsSettings.classificationInputs.${materialType}.${inputName}`)}
      TextFieldProps={{
        disabled: isDisabled || !validValueSet,
        sx: {
          '& .MuiInputBase-root': {
            height: '56px',
            borderRadius: '8px',
            boxShadow: 'none'
          }
        }
      }}
      onChangeCb={onChangeCb}
      {...dataTestId(`${materialType}_classification_${inputName}`)}
    />
  )
}
