import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useEditAsset} from '../../../assets'
import {useGetUnistPlantStructure} from '../../../common/hooks/queries/usePlantStructureQuery'
import {settingsQueryClient} from '../../../common/providers'
import {StructureNodeUnistWithAncestors} from '../../../common/types'
import {useUrlParam} from '../../../routing'
import {useEditStorage} from '../../../storages/hooks'

import {AddSubdomainPopup} from './AddSubdomainPopup'
import {useItemLink} from './ItemLinkContext'

export const AddSubdomain = () => {
  const {t} = useTranslation()

  const plantCode = useUrlParam('plantCode')
  const {mutateAsync: mutateAsset} = useEditAsset()
  const {mutateAsync: mutateMaterialStorage} = useEditStorage()

  const {itemToLink, setItemToLink} = useItemLink()
  const plantStructure = useGetUnistPlantStructure() // potentially heavy operations on the tree

  // potentially heavy operations on the tree
  const nodesByUpmId = useMemo(() => {
    return plantStructure?.flatNodes.reduce(
      (acc, node) => {
        acc[node.data.upmId] = node
        return acc
      },
      {} as Record<string, StructureNodeUnistWithAncestors>
    )
  }, [plantStructure?.flatNodes])

  const upmNodeToLink = useMemo(
    () => (itemToLink && nodesByUpmId ? nodesByUpmId[itemToLink] : null),
    [itemToLink, nodesByUpmId]
  )

  const handleAssociateSubdomainsClick = (values: {
    assetId?: number
    storageId?: number
    upmId: string
  }) => {
    const assetMutation =
      values.assetId &&
      mutateAsset({
        plantCode,
        assetId: values.assetId,
        key: 'upmId',
        dto: {upmId: values.upmId}
      })

    const storageMutation =
      values.storageId &&
      mutateMaterialStorage({
        plantCode,
        id: values.storageId,
        key: 'upmId',
        dto: {upmId: values.upmId}
      })

    void Promise.all([
      assetMutation,
      storageMutation,
      new Promise((r) => setTimeout(r, 3000))
    ]).then(() => settingsQueryClient.invalidateQueries(['assetsGeneralInfo'], {exact: false}))
  }

  return (
    <>
      {itemToLink && (
        <AddSubdomainPopup
          name={upmNodeToLink?.data.name ?? t('janusConfig.plantSetup.unknown')}
          upmId={itemToLink}
          closeAndSubmit={handleAssociateSubdomainsClick}
          close={() => setItemToLink(null)}
          nodesById={nodesByUpmId}
        />
      )}
    </>
  )
}
