import {dataTestId, useTranslation} from '@hconnect/uikit'
import {
  LayersOutlined as LayersOutlinedIcon,
  ChatBubbleOutline as ChatBubbleOutlineIcon
} from '@mui/icons-material/'
import {Box, CardContent, Collapse, Stack, TextField, Typography, Paper} from '@mui/material'
import React, {useCallback, useEffect, useState} from 'react'
import {Controller, useFormContext} from 'react-hook-form'

import {invalidateSettingsQuery} from '../../../common/providers'
import {useUrlParam} from '../../../routing'
import {MonthsFilterOptions} from '../../enums'
import {flattenLastValue} from '../../helpers/getFlattenLastValue'
import {getIsValueFieldValid} from '../../helpers/getIsValueFieldValid'
import {CommentsRequest, Kpi, MonthlyKpisDetails} from '../../types'
import {KpiCommentsView} from '../comments/KpiCommentsView'

import {ResourceCardHeader} from './ResourceCardHeader'

export const ResourceCardMobileView = ({
  group,
  selectedDate,
  parentId,
  periodType
}: {
  group: MonthlyKpisDetails
  selectedDate?: string
  parentId: string
  periodType: MonthsFilterOptions
}) => {
  const [open, setOpen] = useState<boolean>(true)
  const {t} = useTranslation()

  const handleToggle = () => {
    setOpen(!open)
  }

  const {
    control,
    formState: {errors},
    clearErrors,
    setValue
  } = useFormContext()

  const {name, kpis, children, id} = group

  const [selectedReport, setSelectedReport] = useState<CommentsRequest | undefined>()
  const plantCode = useUrlParam('plantCode')

  const mapResources = useCallback(
    (kpis: Kpi[] | undefined) => {
      const newResources = flattenLastValue(kpis)

      if (newResources) {
        newResources.forEach((resource) =>
          setValue(`entries.${parentId}.${resource.specificKpiId}`, resource)
        )
      }
    },
    [parentId, setValue]
  )

  useEffect(() => {
    mapResources(kpis)
  }, [kpis, id, mapResources])

  if (!kpis && !children) {
    return (
      <Paper>
        <ResourceCardHeader title={name} handleToggle={handleToggle} open={open} />
        <Collapse in={open} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography>{t('janusConfig.dailyEntry.noData')}</Typography>
          </CardContent>
        </Collapse>
      </Paper>
    )
  }

  return (
    <>
      {selectedReport ? (
        <KpiCommentsView
          kpiName={selectedReport.kpiName}
          kpiId={selectedReport.kpiValueId}
          closeComments={() => setSelectedReport(undefined)}
          unitName={selectedReport.unitName}
          onSuccess={() => {
            invalidateSettingsQuery('resourceDetails', plantCode, id, periodType, selectedDate)
          }}
          backButtonText={t('janusConfig.dailyEntry.backTo', {name: name})}
        />
      ) : (
        <>
          <ResourceCardHeader title={name} handleToggle={handleToggle} open={open} />
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack gap={2}>
              {' '}
              {!kpis && !children && (
                <Typography key={`no-data-${id}`}>{t('janusConfig.dailyEntry.noData')}</Typography>
              )}
              {children &&
                children?.map((child) => (
                  <ResourceCardMobileView
                    key={child.id}
                    group={child}
                    parentId={parentId}
                    selectedDate={selectedDate}
                    periodType={periodType}
                  />
                ))}
              {kpis &&
                kpis[0]?.values &&
                kpis.map((report) => {
                  const editableValue = report.values[report.values.length - 1]

                  return (
                    <Box
                      key={report.specificKpiId}
                      display="flex"
                      flexDirection="row"
                      gap={1}
                      alignItems="center"
                    >
                      <Box width="70%">
                        <Controller
                          control={control}
                          rules={{
                            validate: (value) => getIsValueFieldValid(value, report.unit.id, t)
                          }}
                          name={`entries.${parentId}.${report.specificKpiId}.value`}
                          render={({field: {ref, value, onChange}}) => {
                            const error = errors?.entries?.[parentId]?.[report.specificKpiId]

                            const helperText = (error && error?.value?.message) || ''

                            return (
                              <TextField
                                {...dataTestId(`value-text-field-${editableValue.date}`)}
                                fullWidth
                                InputProps={{inputProps: {min: 0}}}
                                onClick={(event) => event.stopPropagation()}
                                onChange={(e) => {
                                  clearErrors(`entries.${parentId}.${report.specificKpiId}`)
                                  onChange(e)
                                }}
                                label={report.name}
                                value={value || ''}
                                inputRef={ref}
                                variant="outlined"
                                error={!!error}
                                helperText={helperText}
                              />
                            )
                          }}
                        />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={1}
                        sx={{cursor: 'pointer'}}
                        onClick={() =>
                          setSelectedReport({
                            kpiValueId: editableValue.kpiValueId,
                            kpiName: report.name,
                            unitName: report.unit.unitName
                          })
                        }
                      >
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <LayersOutlinedIcon fontSize="small" />
                          {editableValue.version}
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <ChatBubbleOutlineIcon
                            {...dataTestId(`kpi-comments-number-${editableValue.kpiValueId}`)}
                            fontSize="small"
                          />{' '}
                        </Box>
                      </Box>
                      <Typography>{editableValue.comments}</Typography>
                    </Box>
                  )
                })}
            </Stack>
          </Collapse>
        </>
      )}
    </>
  )
}
