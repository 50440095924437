import {CardBox, Loader} from '@hconnect/uikit/src/lib2'
import {Collapse, Stack, Paper} from '@mui/material'
import React, {useEffect, useState} from 'react'

import {MonthsFilterOptions} from '../../enums'
import {useResourceDetails} from '../../hooks/useResourceDetails'
import {Group} from '../../types'

import {ResourceCardHeader} from './ResourceCardHeader'
import {ResourceCardMobileView} from './ResourceCardMobileView'
import {ResourceCardView} from './ResourceCardView'

export const ResourceCard = ({
  selectedResource,
  periodType,
  selectedDate,
  parentId,
  isMobile,
  showAll
}: {
  selectedResource: Group
  periodType: MonthsFilterOptions
  selectedDate?: string
  parentId: string
  isMobile?: boolean
  showAll?: boolean
}) => {
  const {id} = selectedResource

  const {
    data: resources,
    isLoading,
    isFetching,
    refetch
  } = useResourceDetails(id, periodType, selectedDate)
  const [open, setOpen] = useState<boolean>(true)

  const handleToggle = () => {
    setOpen(!open)
  }

  useEffect(() => {
    void refetch()
  }, [parentId, showAll])

  if (isLoading || isFetching) {
    return (
      <CardBox>
        <Loader />
      </CardBox>
    )
  }

  return (
    <Paper sx={{padding: 2}}>
      <ResourceCardHeader title={selectedResource.name} handleToggle={handleToggle} open={open} />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Stack>
          {resources?.map((group) =>
            !isMobile ? (
              <ResourceCardView
                key={group.id}
                group={group}
                parentId={parentId}
                selectedDate={selectedDate}
                periodType={periodType}
              />
            ) : (
              <ResourceCardMobileView
                key={group.id}
                group={group}
                parentId={parentId}
                selectedDate={selectedDate}
                periodType={periodType}
              />
            )
          )}
        </Stack>
      </Collapse>
    </Paper>
  )
}
