import type {NewProduct, Product} from '@hconnect/common/types'

interface GetAvailableProductsProps {
  products: Product[]
  productsToExclude?: (Product | NewProduct)[]
  productsToInclude?: (Product | NewProduct)[]
}

export const getAvailableProducts = ({
  products,
  productsToExclude = [],
  productsToInclude = []
}: GetAvailableProductsProps) => {
  const productsIdsToExclude = productsToExclude.map(({id}) => id)
  const productsToIncludeIds = productsToInclude.map(({id}) => id)

  return products.filter((product) => {
    return !productsIdsToExclude.includes(product.id) || productsToIncludeIds.includes(product.id)
  })
}
