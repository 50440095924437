import {dataTestId, useTranslation} from '@hconnect/uikit'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Error as ErrorIcon,
  Check as CheckIcon,
  PendingOutlined as PendingOutlinedIcon
} from '@mui/icons-material'
import {Collapse, ListItemButton, ListItemIcon, ListItemText, IconButton, Box} from '@mui/material'
import {useMemo, useState} from 'react'

import {Status} from '../../enums'
import {Group} from '../../types'

export const CategoryItem = ({
  data,
  level,
  onClick,
  selectedItemId
}: {
  data?: Group
  level: number
  onClick: (item: Group) => void
  selectedItemId?: string
}) => {
  const [expanded, setExpanded] = useState<boolean>(true)
  const {t} = useTranslation()

  const FUELS_CATEGORY_ID = '3'
  const isClickable = level !== 0
  const addAllElement =
    data?.id.toString() !== FUELS_CATEGORY_ID &&
    level === 0 &&
    data?.groups &&
    data.groups.length > 1

  const allElement = useMemo(() => {
    if (addAllElement && data) {
      return {
        ...data,
        name: t('janusConfig.monthlyEntry.all'),
        hideGroups: true,
        id: `${data.id}-all`
      }
    }
  }, [addAllElement, data])

  if (!data) {
    return null
  }

  const isCompleted = [Status.Approved, Status.Saved].includes(data.status)
  const isPending = data.status === Status.PartiallySaved

  const Icon = isCompleted ? (
    <CheckIcon color="success" {...dataTestId('check-icon')} />
  ) : isPending ? (
    <PendingOutlinedIcon color="secondary" {...dataTestId('pending-icon')} />
  ) : (
    <ErrorIcon color="error" {...dataTestId('error-icon')} />
  )

  return (
    <>
      <ListItemButton
        key={data.id}
        selected={data.id === selectedItemId}
        sx={{
          ml: level,
          borderBottom: '1px solid #E8ECF0',
          cursor: isClickable ? 'pointer' : 'default'
        }}
        onClick={() => isClickable && onClick(data)}
        {...dataTestId(`category-item-${data.id}`)}
      >
        <ListItemText
          primary={
            <Box display="flex" flexDirection="row" alignContent="center" gap={1}>
              {Icon}
              {data.name}
            </Box>
          }
          primaryTypographyProps={{variant: 'body1'}}
        />
        {data?.groups && data.groups.length > 0 && !data.hideGroups && (
          <ListItemIcon
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '40px',
              width: '40px'
            }}
          >
            <IconButton
              size="medium"
              onClick={(e) => {
                setExpanded((prev) => !prev)
                e.stopPropagation()
              }}
            >
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </ListItemIcon>
        )}
      </ListItemButton>
      <Collapse in={expanded} timeout="auto">
        {allElement && (
          <CategoryItem
            onClick={onClick}
            selectedItemId={selectedItemId}
            key="show-all-element"
            data={allElement}
            level={level + 2}
          />
        )}
        {!data.hideGroups &&
          data.groups?.map((item) => (
            <CategoryItem
              onClick={onClick}
              selectedItemId={selectedItemId}
              key={item.id}
              data={item}
              level={level + 2}
            />
          ))}
      </Collapse>
    </>
  )
}
