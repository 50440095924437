export const quarryOptions = [
  'chalkQuarry',
  'clayQuarry',
  'gypsumQuarry',
  'limestoneQuarry',
  'marlQuarry',
  'marlAndChalkQuarry',
  'oilQuarry',
  'puzzolanQuarry',
  'sandQuarry',
  'sandstoneQuarry',
  'otherQuarry'
]

export const crusherOptions = [
  'jawCrusher',
  'coneCrusher',
  'gyratoryCrusher',
  'hammerCrusher',
  'horizontalShaftImpactor',
  'verticalShaftImpactor',
  'rollCrusher',
  'sizer',
  'combination',
  'other'
]

export const rawOptions = [
  'ballMill',
  'ballMillCombined',
  'crusherAndBallMill',
  'hammerMill',
  'rollerPress',
  'rollerPressAndBallMill',
  'rollerPressAndVertical',
  'verticalBallMill',
  'verticalRollerMill',
  'aerofallMill',
  'horomill',
  'ballMillWet'
] as const

export const separatorOptions = [
  'firstGeneration',
  'secondGeneration',
  'thirdGeneration',
  'vSeparator',
  'combination',
  'openCircuit',
  'none'
]

export const kilnTechnologyOptions = ['dry', 'longDry', 'semiDry', 'wet']

export const kilnPreheaterOptions = [
  'suspensionPreheaterCalciner',
  'suspensionPreheater',
  'gratePreheater',
  'noPreheater',
  'none'
]

export const kilnCalcinerOptions = [
  'airSeparateCombustinChamber',
  'airSeparateInLine',
  'airSeparateInLineWithChamber',
  'airSeparateLine',
  'airThroughInLine'
]

export const copgCategoryOptions = ['fuelFired', 'wind', 'solar', 'other']
export const kilnPreaheterStagesOptions = ['_', '2', '3', '4', '5', '6']
export const kilnPreaheterStringsOptions = ['_', '2']
export const kilnSateliteBurnerOptions = ['withSateliteBurner', 'noSateliteBurner']
export const kilnCoolerTypeOptions = ['rotary', 'satellite', 'grate']
export const kilnClinkerTypeOptions = ['grey', 'white', 'other', 'none']
export const meshSizeOptions = ['25', '30', '32', '40', '45', '80', '90', '-']
export const operatingTimeFactorOptions = ['1', '2', '3']

export type CrusherType = (typeof crusherOptions)[number]
export type QuarryType = (typeof quarryOptions)[number]
export type RawType = (typeof rawOptions)[number]
export type SeparatorType = (typeof separatorOptions)[number]
export type MeshSizeType = (typeof meshSizeOptions)[number]
export type OperatingTimeFactorType = (typeof operatingTimeFactorOptions)[number]
export type KilnTechnologyType = (typeof kilnTechnologyOptions)[number]
export type KilnPreheaterType = (typeof kilnPreheaterOptions)[number]
export type KilnCalcinerType = (typeof kilnCalcinerOptions)[number]
export type KilnPreheaterStagesType = (typeof kilnPreaheterStagesOptions)[number]
export type KilnPreheaterStringsType = (typeof kilnPreaheterStringsOptions)[number]
export type KilnSateliteBurnerType = (typeof kilnSateliteBurnerOptions)[number]
export type KilnCoolerType = (typeof kilnCoolerTypeOptions)[number]
export type KilnClinkerType = (typeof kilnClinkerTypeOptions)[number]
export type CopgCategoryType = (typeof copgCategoryOptions)[number]

export type ProductionData = {
  name: string
  value: string
}

export type Config = {
  localName: string
  workCenter?: string
  quarryType: QuarryType
  commissStartDate: string
  commissEndDate: string
  mothballed: string
  installedPowerMainDrive: string
  separator: SeparatorType
  meshSize: MeshSizeType
  fuelAssignment: string
  operatingTimeFactor: OperatingTimeFactorType
  crusherType: CrusherType
  clinkerType: KilnClinkerType
  dailyCapacity: string
  fuelAssignmentMainBurner: string
  fuelAssignmentPreheater: string
  fuelAssignmentOther: string
  technology: KilnTechnologyType
  preheater: KilnPreheaterStagesType
  calciner: KilnCalcinerType
  preheaterStagesNumber: KilnPreheaterStagesType
  preheaterStringsNumber: KilnPreheaterStringsType
  mainBurner: KilnSateliteBurnerType
  cooler: KilnCoolerType
  reliabilityFactorForPss: string
  mtbfTarget: string
  gasBypassRate: string
  copgCategory: CopgCategoryType
}

export type InputType = 'Autocomplete' | 'Select' | 'Date' | 'Text' | 'Checkbox'

export type ConfigType =
  | 'CementBlending'
  | 'CementMill'
  | 'Crusher'
  | 'Kiln'
  | 'Quarry'
  | 'RawMaterialDeforesting'
  | 'RawMaterialDryer'
  | 'ClayCalcination'
  | 'WHR'
  | 'COPG'

export const commonFields: ConfigField[] = ['localName', 'workCenter']

export const configFieldsMap: Record<ConfigType, ConfigField[]> = {
  CementBlending: [...commonFields],
  CementMill: [
    ...commonFields,
    'fuelAssignment',
    'comissionDates',
    'installedPowerMainDrive',
    'separator',
    'meshSize',
    'operatingTimeFactor',
    'mothballed'
  ],
  Crusher: [
    ...commonFields,
    'crusherType',
    'comissionDates',
    'installedPowerMainDrive',
    'mothballed'
  ],
  Kiln: [
    ...commonFields,
    'clinkerType',
    'dailyCapacity',
    'fuelAssignmentMainBurner',
    'fuelAssignmentPreheater',
    'fuelAssignmentOther',
    'comissionDates',
    'technology',
    'preheater',
    'calciner',
    'preheaterStagesNumber',
    'preheaterStringsNumber',
    'mainBurner',
    'cooler',
    'reliabilityFactorForPss',
    'mtbfTarget',
    'gasBypassRate',
    'installedPowerMainDrive',
    'mothballed'
  ],
  Quarry: [...commonFields, 'quarryType'],
  ClayCalcination: [...commonFields, 'fuelAssignment', 'comissionDates', 'mothballed'],
  RawMaterialDeforesting: [...commonFields, 'fuelAssignment'],
  RawMaterialDryer: [...commonFields, 'fuelAssignment', 'comissionDates', 'mothballed'],
  WHR: [...commonFields],
  COPG: [
    ...commonFields,
    'nominalElectricalPower',
    'fuelAssignment',
    'copgCategory',
    'comissionDates',
    'mothballed'
  ]
}

export const configFields = [
  'localName',
  'workCenter',
  'fuelAssignment',
  'crusherType',
  'quarryType',
  'comissionDates',
  'commissStartDate',
  'commissEndtDate',
  'installedPowerMainDrive',
  'mothballed',
  'separator',
  'meshSize',
  'operatingTimeFactor',
  'clinkerType',
  'dailyCapacity',
  'fuelAssignmentMainBurner',
  'fuelAssignmentPreheater',
  'fuelAssignmentOther',
  'technology',
  'preheater',
  'calciner',
  'preheaterStagesNumber',
  'preheaterStringsNumber',
  'mainBurner',
  'cooler',
  'reliabilityFactorForPss',
  'mtbfTarget',
  'gasBypassRate',
  'copgCategory',
  'nominalElectricalPower'
] as const

export type ConfigField = (typeof configFields)[number]

export type FieldType = {
  name: ConfigField
  testId?: string
  type: InputType
  options?: string[]
  label?: string
  optional?: boolean
  isNumeric?: boolean
  startDate?: string
  endDate?: string
  translateOptions?: boolean
}

export const fields: Record<ConfigField, FieldType> = {
  fuelAssignment: {
    name: 'fuelAssignment',
    testId: 'fuel_assignment',
    label: 'fuelAssignment',
    type: 'Select',
    options: []
  },
  fuelAssignmentMainBurner: {
    name: 'fuelAssignmentMainBurner',
    testId: 'fuel_main_burner',
    label: 'fuelAssignmentMainBurner',
    type: 'Select',
    options: []
  },
  fuelAssignmentPreheater: {
    name: 'fuelAssignmentPreheater',
    testId: 'fuel_pre_heater',
    label: 'fuelAssignmentPreheater',
    type: 'Select',
    options: []
  },
  fuelAssignmentOther: {
    name: 'fuelAssignmentOther',
    testId: 'fuel_other',
    label: 'fuelAssignmentOther',
    type: 'Select',
    options: []
  },
  localName: {name: 'localName', testId: 'local_name', type: 'Text', label: 'localName'},
  mainBurner: {
    name: 'mainBurner',
    testId: 'main_burner',
    type: 'Autocomplete',
    label: 'mainBurner',
    options: kilnSateliteBurnerOptions,
    translateOptions: true
  },
  cooler: {
    name: 'cooler',
    testId: 'cooler',
    type: 'Autocomplete',
    label: 'coolerType',
    options: kilnCoolerTypeOptions,
    translateOptions: true
  },
  copgCategory: {
    name: 'copgCategory',
    testId: 'copgCategory',
    type: 'Autocomplete',
    label: 'copgCategory',
    options: copgCategoryOptions,
    translateOptions: true
  },
  reliabilityFactorForPss: {
    name: 'reliabilityFactorForPss',
    testId: 'reliability_factor',
    type: 'Text',
    label: 'reliabilityFactorForPss',
    isNumeric: true
  },
  mtbfTarget: {
    name: 'mtbfTarget',
    testId: 'mtbf-target',
    type: 'Text',
    label: 'mtbfTarget',
    isNumeric: true
  },
  gasBypassRate: {
    name: 'gasBypassRate',
    testId: 'gas_bypass',
    type: 'Text',
    label: 'gasBypassRate',
    isNumeric: true
  },
  dailyCapacity: {
    name: 'dailyCapacity',
    testId: 'daily_capacity',
    type: 'Text',
    label: 'dailyCapacityForPss',
    isNumeric: true
  },
  quarryType: {
    name: 'quarryType',
    testId: 'type',
    type: 'Autocomplete',
    label: 'type',
    options: quarryOptions,
    translateOptions: true
  },
  crusherType: {
    name: 'crusherType',
    testId: 'type',
    type: 'Autocomplete',
    label: 'type',
    options: crusherOptions,
    translateOptions: true
  },
  workCenter: {
    name: 'workCenter',
    testId: 'work_center',
    type: 'Text',
    label: 'workCenter',
    optional: true
  },
  comissionDates: {
    type: 'Date',
    name: 'comissionDates'
  },
  commissStartDate: {
    type: 'Date',
    name: 'commissStartDate'
  },
  commissEndtDate: {
    type: 'Date',
    name: 'commissEndtDate'
  },
  installedPowerMainDrive: {
    testId: 'installed_power_main_drive',
    name: 'installedPowerMainDrive',
    type: 'Text',
    isNumeric: true,
    label: 'installedPowerMainDrive'
  },
  nominalElectricalPower: {
    testId: 'nominal_electrical_power',
    name: 'nominalElectricalPower',
    type: 'Text',
    isNumeric: true,
    label: 'nominalElectricalPower'
  },
  mothballed: {
    name: 'mothballed',
    testId: 'mothballed',
    type: 'Checkbox',
    label: 'mothballed'
  },
  separator: {
    name: 'separator',
    label: 'separatorType',
    testId: 'separator',
    options: separatorOptions,
    translateOptions: true,
    type: 'Autocomplete'
  },
  technology: {
    name: 'separator',
    label: 'technology',
    testId: 'technology',
    options: kilnTechnologyOptions,
    translateOptions: true,
    type: 'Autocomplete'
  },
  meshSize: {
    name: 'meshSize',
    label: 'meshSize',
    testId: 'mesh',
    options: meshSizeOptions,
    type: 'Autocomplete'
  },
  operatingTimeFactor: {
    name: 'operatingTimeFactor',
    label: 'operatingTimeFactor',
    testId: 'operating_time',
    options: operatingTimeFactorOptions,
    type: 'Autocomplete'
  },
  clinkerType: {
    name: 'clinkerType',
    label: 'clinkerType',
    testId: 'clinker_type',
    options: kilnClinkerTypeOptions,
    type: 'Autocomplete',
    translateOptions: true
  },
  preheater: {
    name: 'preheater',
    label: 'preheaterType',
    testId: 'preheater_type',
    options: kilnPreheaterOptions,
    type: 'Autocomplete',
    translateOptions: true
  },
  calciner: {
    name: 'calciner',
    label: 'calcinerType',
    testId: 'calciner_type',
    options: kilnCalcinerOptions,
    type: 'Autocomplete',
    translateOptions: true
  },
  preheaterStagesNumber: {
    name: 'preheaterStagesNumber',
    label: 'preheaterStagesNumber',
    testId: 'preheater_stages',
    options: kilnPreaheterStagesOptions,
    type: 'Autocomplete'
  },
  preheaterStringsNumber: {
    name: 'preheaterStringsNumber',
    label: 'preheaterStringsNumber',
    testId: 'preheater_string',
    options: kilnPreaheterStringsOptions,
    type: 'Autocomplete'
  }
}

export type DepartmentConfigDto = {
  metadata: {
    configurationType: string
    plantId: string
    upmType: 'Department'
    typeUpmId: string
  }
  payload: {
    productionData: ProductionData[]
  }
}
