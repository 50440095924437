import {Material} from '@hconnect/common/types'

export const getMaterialWithAssignedLimsMaterial = (
  limsMaterialId: number,
  materials: Material[]
): Material | undefined => {
  return materials.find((material) =>
    material.limsMaterials.some((limsMaterial) => limsMaterial.id === limsMaterialId)
  )
}
