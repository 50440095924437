import {MaterialBoughtFrom, MaterialSource, MaterialType} from '@hconnect/common/types'
import {useForm, UseFormReturn} from 'react-hook-form'

export type NewMaterialFormDefaultValues = {
  type: MaterialType | null
  name: string
  description: string
  source: MaterialSource | null
  boughtFrom: MaterialBoughtFrom
}

export const useNewMaterialForm = (): UseFormReturn<NewMaterialFormDefaultValues> => {
  const materialForm = useForm<NewMaterialFormDefaultValues>({
    mode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: {
      type: null,
      name: '',
      description: '',
      source: null,
      boughtFrom: MaterialBoughtFrom.Domestic
    }
  })

  return materialForm
}
