/**
 * @author Martin Petran
 */
import {mergeSx} from '@hconnect/uikit/src/lib2'
import {Autocomplete, SxProps, Theme} from '@mui/material'
import {isObject} from 'lodash'
import {KeyboardEvent} from 'react'
import {Controller} from 'react-hook-form'
import type {Control, FieldValues, Path, RegisterOptions} from 'react-hook-form'

import {submitOnBlurAndEnterProps} from '../../../common/utils'

import {BaseTextField} from './BaseTextField'

interface ControlledAutocompleteProps<
  TOption extends {id: string; label: string} | string,
  TField extends FieldValues
> {
  control: Control<TField>
  formDataName: Path<TField>
  options: TOption[]
  label: string
  rules?: RegisterOptions
  disableClearable?: boolean
  disabled?: boolean
  helperText?: string
  placeholder?: string
  isOptionEqualToValue?: (option: TOption, value: TOption) => boolean
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: TOption) => React.ReactNode
  getOptionLabel?: (option: TOption) => string
  translateOption?: (option: string) => string
  onChangeCb?: () => void
  onSubmit?: () => Promise<void>
  onAddCustomValue?: (newValue: string) => void
  sx?: SxProps<Theme>
  'data-test-id'?: string
}

export const ControlledAutocomplete = <
  TOption extends {id: string; label: string} | string,
  TField extends FieldValues
>({
  control,
  formDataName,
  options,
  label,
  rules,
  disableClearable = true,
  disabled = false,
  helperText,
  placeholder,
  isOptionEqualToValue,
  renderOption,
  getOptionLabel,
  translateOption,
  onChangeCb,
  onSubmit,
  onAddCustomValue,
  sx,
  'data-test-id': dataTestId
}: ControlledAutocompleteProps<TOption, TField>) => (
  <Controller
    name={formDataName}
    control={control}
    rules={rules}
    render={({field: {ref, onChange, value}, fieldState: {invalid, error}}) => (
      <Autocomplete
        fullWidth
        value={value}
        disabled={disabled}
        disableClearable={disableClearable}
        onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
          onAddCustomValue?.((event.target as HTMLInputElement).value)
        }}
        onChange={(e, data) => {
          onChange(data)
          onChangeCb?.()
        }}
        options={options}
        isOptionEqualToValue={
          isOptionEqualToValue ??
          ((option, value) => {
            return isObject(option) && isObject(value) ? option.id === value.id : option === value
          })
        }
        renderInput={(params) => {
          return (
            <BaseTextField
              {...params}
              label={label}
              inputRef={ref}
              helperText={invalid ? (error?.message ? error.message : 'Invalid value') : helperText}
              placeholder={placeholder}
              error={invalid}
            />
          )
        }}
        renderOption={renderOption}
        getOptionLabel={
          getOptionLabel ??
          ((option) => {
            if (typeof option === 'string') {
              return translateOption ? translateOption(option) : option
            }
            return option.label
          })
        }
        {...(onSubmit && submitOnBlurAndEnterProps(onSubmit))}
        slotProps={{
          paper: {
            elevation: 12,
            sx(theme) {
              return {
                background: theme.palette.grey[50]
              }
            }
          }
        }}
        sx={mergeSx(
          {
            '& .MuiInputBase-root': {
              height: ({spacing}) => spacing(7),
              borderRadius: ({spacing}) => spacing(1),
              background: ({palette}) => palette.background.paper,
              boxShadow: 'none',
              mb: helperText && 1,
              '&.Mui-focused': {
                background: ({palette}) => palette.grey[50]
              }
            }
          },
          sx
        )}
        data-test-id={dataTestId}
      />
    )}
  />
)
