import {MaterialType, MaterialWithRecipes} from '@hconnect/common/types'
import {useMemo} from 'react'
import {useIsMutating} from 'react-query'

import {usePermission} from '../../../../permissions'

import {AdditivesForClinkerClassification} from './AdditivesForClinkerClassification'
import {CementClassification} from './CementClassification'
import {ClinkerClassification} from './ClinkerClassification'
import {EnhancersForCementClassification} from './EnhancersForCementClassification'
import {FuelsClassification} from './FuelsClassification'
import {IntermediateAndFinishedProductsClassification} from './IntermediateAndFinishedProductsClassification'
import {RawMaterialsClassification} from './RawMaterialsClassification'

interface MaterialSettingsClassificationTabProps {
  material: MaterialWithRecipes
}

export const MaterialSettingsClassificationTab: React.FC<
  MaterialSettingsClassificationTabProps
> = ({material}) => {
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')

  const runningMutations = useIsMutating(['editMaterial'])
  const isMaterialSaving = Boolean(runningMutations)

  const ClassificationTabComponent = useMemo(() => {
    switch (material.type) {
      case MaterialType.Cement:
        return CementClassification
      case MaterialType.Clinker:
        return ClinkerClassification
      case MaterialType.Fuels:
        return FuelsClassification
      case MaterialType.RawMaterials:
        return RawMaterialsClassification
      case MaterialType.EnhancersForCement:
        return EnhancersForCementClassification
      case MaterialType.IntermediateAndFinishedProducts:
        return IntermediateAndFinishedProductsClassification
      case MaterialType.AdditivesForClinker:
        return AdditivesForClinkerClassification
      default:
        throw new Error(`BUG: Unknown material type: ${material.type}`)
    }
  }, [material.type])

  return <ClassificationTabComponent isDisabled={!canChangeMaterials || isMaterialSaving} />
}
