import {Material, NewLimsMaterial, LimsMaterial} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Alert, NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined, Done} from '@mui/icons-material'
import {Box, Stack, Typography} from '@mui/material'
import React from 'react'
import {UseFieldArrayRemove, UseFieldArrayUpdate, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {ControlledAutocomplete, DeleteButton} from '../../../../../common/components'
import {removeSettingsQuery, useConfirmDialog} from '../../../../../common/providers'
import {requiredValidator} from '../../../../../common/utils'
import {useUrlParam} from '../../../../../routing'
import {MaterialFormDefaultValues} from '../../../materials-details/ExistingMaterialDetails/hooks'
import {getMaterialWithAssignedLimsMaterial, getAvailableLimsMaterials} from '../helpers'

interface MaterialFormLimsMaterialProps {
  index: number
  formLimsMaterial: LimsMaterial | NewLimsMaterial
  limsMaterials: LimsMaterial[]
  material: Material
  materials: Material[]
  isRemoveLimsMaterialDisabled: boolean
  updateLimsMaterial: UseFieldArrayUpdate<MaterialFormDefaultValues, 'limsMaterials'>
  removeLimsMaterial: UseFieldArrayRemove
}

export const MaterialFormLimsMaterial: React.FC<MaterialFormLimsMaterialProps> = ({
  index,
  formLimsMaterial,
  limsMaterials,
  material,
  materials,
  isRemoveLimsMaterialDisabled,
  updateLimsMaterial,
  removeLimsMaterial
}) => {
  const {t} = useTranslation()
  const {openDialog} = useConfirmDialog()
  const plantCode = useUrlParam('plantCode')

  const {control, getValues} = useFormContext<MaterialFormDefaultValues>()
  const currentLimsMaterials = getValues('limsMaterials')

  const isAlreadyAttachedLimsMaterial = !!(
    formLimsMaterial.id && material.limsMaterials.some(({id}) => id === formLimsMaterial.id)
  )

  // Getting options for lims material code dropdown
  const availableLimsMaterials = getAvailableLimsMaterials({
    limsMaterials,
    limsMaterialsToExclude: currentLimsMaterials,
    limsMaterialsToInclude: [formLimsMaterial]
  })
  const availableLimsMaterialCodes = availableLimsMaterials.map(({code}) => code)

  // Creating a map of lims materials by code for easier access later
  const limsMaterialsByCode = Object.fromEntries(
    limsMaterials.map((limsMaterial) => [limsMaterial.code, limsMaterial])
  )

  const handleDeleteLimsMaterial = () => {
    if (!isAlreadyAttachedLimsMaterial) {
      removeLimsMaterial(index)
      return
    }
    openDialog({
      title: t('materialsSettings.deleteLimsMaterial'),
      description: t('materialsSettings.deleteLimsMaterialConfirmation', {
        limsMaterialCode: formLimsMaterial.code
      }),
      mainAction: {
        text: t('common.delete'),
        color: 'error',
        icon: <DeleteOutlined />,
        onAction: () => {
          removeLimsMaterial(index)
        }
      }
    })
  }

  const checkForConflictedLimsMaterial = (limsMaterial: LimsMaterial) => {
    const conflictedMaterial = getMaterialWithAssignedLimsMaterial(limsMaterial.id, materials)
    if (conflictedMaterial) {
      openDialog({
        title: t('common.conflict'),
        description: t('materialsSettings.limsMaterialAlreadyAssignedDescription', {
          materialName: conflictedMaterial.name
        }),
        mainAction: {
          text: t('materialsSettings.assignLimsMaterial'),
          icon: <Done />,
          onAction: () => {
            removeSettingsQuery('materialWithRecipes', plantCode, conflictedMaterial.id)
          }
        },
        onCancel: () => {
          removeLimsMaterial(index)
        },
        additionalContent: (
          <>
            <Alert
              accentColor="error"
              content={t('materialsSettings.limsMaterialAlreadyAssignedAdditionalInfo', {
                currentMaterialName: material.name,
                conflictingMaterialName: conflictedMaterial.name
              })}
            />
            <Typography variant="body1" sx={{mt: 2}}>
              {t('materialsSettings.limsMaterialAlreadyAssignedConfirmation')}
            </Typography>
          </>
        ),
        testId: 'conflict_lims_material_code_already_assigned'
      })
      return
    }
  }

  return (
    <Stack direction="row" alignItems="center" spacing={2} {...dataTestId('lims_material_form')}>
      <NumberLetterSequenceIndicator
        numberIndex={index}
        {...dataTestId('lims_material_form_order_number')}
      />
      <Box sx={{width: ({spacing}) => spacing(44)}}>
        <ControlledAutocomplete
          control={control}
          formDataName={`limsMaterials.${index}.code`}
          options={availableLimsMaterialCodes}
          label={t('materialsSettings.limsMaterial')}
          rules={requiredValidator(t)}
          disabled={isAlreadyAttachedLimsMaterial}
          placeholder={t('materialsSettings.limsMaterialAutocompletePlaceholder')}
          renderOption={(props, code) => {
            const limsMaterialName = limsMaterialsByCode[code]?.name
            return (
              <Stack
                {...props}
                component="li"
                spacing={0.5}
                sx={{
                  '&.MuiAutocomplete-option': {
                    alignItems: 'flex-start'
                  }
                }}
              >
                {limsMaterialName && (
                  <Typography variant="subtitle1">{limsMaterialName}</Typography>
                )}
                <Typography variant={limsMaterialName ? 'caption' : 'subtitle1'}>{code}</Typography>
              </Stack>
            )
          }}
          getOptionLabel={(code) => {
            const limsMaterialName = limsMaterialsByCode[code]?.name
            return limsMaterialName ? `${limsMaterialName} - ${code}` : code
          }}
          onChangeCb={() => {
            const currentLimsMaterialCode = getValues(`limsMaterials.${index}.code`)
            if (!currentLimsMaterialCode) return

            const currentLimsMaterial = limsMaterialsByCode[currentLimsMaterialCode]
            updateLimsMaterial(index, currentLimsMaterial)

            checkForConflictedLimsMaterial(currentLimsMaterial)
          }}
          {...dataTestId('lims_material_form_autocomplete')}
        />
      </Box>
      <DeleteButton
        onClick={handleDeleteLimsMaterial}
        disabled={isRemoveLimsMaterialDisabled}
        {...dataTestId('lims_material_form_delete_button')}
      />
    </Stack>
  )
}
