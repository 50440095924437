import {OperationMode} from '@hconnect/common/types'
import {dataTestId, dateFormatter, timeFormatter} from '@hconnect/uikit'
import {CardTitle} from '@hconnect/uikit/src/lib2'
import {DialogContent, Stack, Alert, Typography} from '@mui/material'
import moment from 'moment-timezone'
import {Trans, useTranslation} from 'react-i18next'

import {ScheduleItem} from '../../../../common/types'
import {AssetStandardOperationTimeValidationData} from '../../../types'
import {LoadingButton} from '../common/LoadingButton'

type StandardOperationTimeEditDialogProps = {
  validateResult: AssetStandardOperationTimeValidationData
  operationModes: OperationMode[]
  isLoading: boolean
  onSkipConflicts: () => void
  onOverwriteConflicts: () => void
  onCancel: () => void
}

const formatScheduleItemTimeRange = (item: ScheduleItem, locale: string) => {
  const momentStart = moment(item.start)
  const momentEnd = moment(item.end)

  if (momentStart.clone().isSame(momentEnd, 'day')) {
    return `${dateFormatter(momentStart, locale)} ${timeFormatter(
      momentStart,
      locale
    )} - ${timeFormatter(momentEnd, locale)}`
  }
  return `${dateFormatter(momentStart, locale)} ${timeFormatter(
    momentStart,
    locale
  )} - ${dateFormatter(momentEnd, locale)} ${timeFormatter(momentEnd, locale)}`
}

export const StandardOperationTimeSchedulingConflictDialogContent: React.FC<
  StandardOperationTimeEditDialogProps
> = ({
  validateResult,
  operationModes,
  isLoading,
  onSkipConflicts,
  onOverwriteConflicts,
  onCancel
}) => {
  const {
    i18n: {language}
  } = useTranslation()

  return (
    <DialogContent>
      <CardTitle>
        <Trans i18nKey="assetsSettings.standardOperationTimes.schedulingConflict" />
      </CardTitle>
      <form {...dataTestId('standard_operation_times_form')}>
        <Stack spacing={2}>
          <Alert severity="error">
            <Trans
              i18nKey="assetsSettings.standardOperationTimes.schedulingConflictInfo"
              values={{
                conflicts: validateResult.conflictItems.length.toString()
              }}
            />
          </Alert>
          <Stack>
            <Typography>
              <Trans
                i18nKey={
                  validateResult.conflictItems.length < 3
                    ? 'assetsSettings.standardOperationTimes.theseAreConflicts'
                    : 'assetsSettings.standardOperationTimes.theseAreFirstThreeConflicts'
                }
              />
            </Typography>
            {validateResult.conflictItems.slice(0, 3).map((conflict) => (
              <Typography key={conflict.id}>
                {formatScheduleItemTimeRange(conflict, language)}
                {operationModes.find((e) => e.id === conflict.assetOperationModeId)?.name}
              </Typography>
            ))}
          </Stack>
          <Stack>
            <Typography>
              <Trans i18nKey="assetsSettings.standardOperationTimes.howDoYouWantToProceed" />
            </Typography>
            <ul>
              <li>
                <Trans i18nKey="assetsSettings.standardOperationTimes.skipConflictsLong" />
              </li>
              <li>
                <Trans i18nKey="assetsSettings.standardOperationTimes.overwriteConflictLong" />
              </li>
            </ul>
          </Stack>
          <Stack mt={2} direction="row" justifyContent="flex-end" spacing={1}>
            <LoadingButton
              color="primary"
              variant="text"
              {...dataTestId('standard_operation_times_conflict_dialog_cancel_button')}
              sx={{
                minWidth: (theme) => theme.spacing(15)
              }}
              onClick={onCancel}
              isLoading={isLoading}
            >
              <Trans i18nKey="common.cancel" />
            </LoadingButton>

            <LoadingButton
              color="primary"
              variant="text"
              {...dataTestId('standard_operation_times_conflict_dialog_skip_conflicts_button')}
              sx={{
                minWidth: (theme) => theme.spacing(15)
              }}
              onClick={onSkipConflicts}
              isLoading={isLoading}
            >
              <Trans i18nKey="assetsSettings.standardOperationTimes.skipConflicts" />
            </LoadingButton>

            <LoadingButton
              color="primary"
              variant="text"
              {...dataTestId('standard_operation_times_conflict_dialog_overwrite_conflicts_button')}
              sx={{
                minWidth: (theme) => theme.spacing(15)
              }}
              onClick={onOverwriteConflicts}
              isLoading={isLoading}
            >
              <Trans i18nKey="assetsSettings.standardOperationTimes.overwriteConflicts" />
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </DialogContent>
  )
}
