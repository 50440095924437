import {dataTestId} from '@hconnect/uikit'
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon
} from '@mui/icons-material'
import {Box, IconButton, InputAdornment} from '@mui/material'
import {CalendarPickerView, PickersDay, PickersDayProps} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import moment, {Moment} from 'moment'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {CalendarRange} from '../../enums'
import {calculateWeekRange} from '../../helpers/calculateWeekRange'
import {CalendarInput} from '../DailyEntry/CalendarInput'

export const CalendarDatePicker = ({
  selectedDate,
  setSelectedDate,
  type,
  fullWidth,
  maxDateAllowed
}: {
  selectedDate: string | undefined
  setSelectedDate: (date: string | undefined) => void
  type: CalendarRange
  fullWidth: boolean
  maxDateAllowed?: Moment
}) => {
  const {t} = useTranslation()

  const [calendarIsOpen, setCalendarIsOpen] = useState<boolean>(false)
  const dateChangeRange = useMemo(() => (type === CalendarRange.Monthly ? 'month' : 'week'), [type])

  const disableNextDate = useMemo(() => {
    const newestPossibleDate = maxDateAllowed ?? moment().subtract(1, 'day')
    const selected = moment(selectedDate)
    return selected.isSameOrAfter(newestPossibleDate, dateChangeRange)
  }, [selectedDate, dateChangeRange, maxDateAllowed])

  const maxDate = useMemo(
    () =>
      (maxDateAllowed && maxDateAllowed.toDate()) ||
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
    [maxDateAllowed]
  )

  const renderDay = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    const dateIsInTheSameWeek = moment(selectedDate, 'YYYYMMDD').isSame(
      new Date(pickersDayProps.day).toISOString(),
      'week'
    )

    const isStart = moment(date).weekday() === 0
    const isEnd = moment(date).weekday() === 6

    if (dateIsInTheSameWeek) {
      return (
        <PickersDay
          {...pickersDayProps}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.light,
            color: 'white',
            borderTopRightRadius: isEnd ? 8 : 0,
            borderBottomRightRadius: isEnd ? 8 : 0,
            borderTopLeftRadius: isStart ? 8 : 0,
            borderBottomLeftRadius: isStart ? 8 : 0,
            paddingX: 2.5,
            margin: 0
          }}
          day={date}
        />
      )
    }

    return (
      <PickersDay
        {...pickersDayProps}
        day={date}
        sx={{borderRadius: 0, paddingX: 2.5, margin: 0}}
      />
    )
  }

  const calendarViews: CalendarPickerView[] =
    type === CalendarRange.Monthly ? ['month', 'year'] : ['day']

  const inputLabel =
    type === CalendarRange.Monthly
      ? t('janusConfig.dailyEntry.month')
      : t('janusConfig.dailyEntry.week')

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={inputLabel}
        value={selectedDate}
        views={calendarViews}
        onChange={(date) => setSelectedDate(date?.toISOString())}
        open={calendarIsOpen}
        onOpen={() => setCalendarIsOpen(true)}
        onClose={() => setCalendarIsOpen(false)}
        renderDay={renderDay}
        renderInput={(props) => (
          <CalendarInput
            {...props}
            fullWidth={fullWidth}
            inputProps={{
              ...props.inputProps,
              readOnly: true,
              ...(type === CalendarRange.Weekly && {
                value: `CW${moment(selectedDate).week()} (${calculateWeekRange(selectedDate)})`
              })
            }}
            InputProps={{
              ...props.InputProps,
              startAdornment: (
                <InputAdornment position="start" sx={{paddingLeft: 0, marginLeft: 0}}>
                  <IconButton
                    {...dataTestId('calendar-picker-left-arrow')}
                    sx={{borderRadius: 0}}
                    size="large"
                    onClick={(event) => {
                      event.stopPropagation()
                      const date = moment(
                        selectedDate ?? moment().subtract(1, 'days').toISOString()
                      )

                      setSelectedDate(date.subtract(1, dateChangeRange).toISOString())
                    }}
                  >
                    <KeyboardArrowLeftIcon style={{color: 'white'}} />
                  </IconButton>
                </InputAdornment>
              ),
              sx: {paddingX: 0},
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    {...dataTestId('calendar-picker-right-arrow')}
                    sx={{borderRadius: 0}}
                    size="large"
                    onClick={(event) => {
                      event.stopPropagation()
                      if (!disableNextDate) {
                        const date = moment(
                          selectedDate ?? moment().subtract(1, 'days').toISOString()
                        )
                        setSelectedDate(date.add(1, dateChangeRange).toISOString())
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        justifyItems: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        height: '100%'
                      }}
                    >
                      <KeyboardArrowRightIcon style={{color: 'white'}} />
                    </Box>
                  </IconButton>
                </InputAdornment>
              )
            }}
            onClick={() => setCalendarIsOpen(true)}
            {...dataTestId(`calendar-date-picker-${type}`)}
          />
        )}
        maxDate={maxDate}
      />
    </LocalizationProvider>
  )
}
