import {
  MaterialType,
  Material,
  Recipe,
  Status,
  RecipeComponent,
  ComponentMaterial
} from '@hconnect/common/types'

// Generic settings tree types
export type SettingsTreeNode<T extends MaterialTreeComponents> = {
  id: string
  name: string
  originalEntity: T
  children?: SettingsTree<T>
}
export type SettingsTree<T extends MaterialTreeComponents> = ReadonlyArray<SettingsTreeNode<T>>

// Material settings tree types
type NewMaterial = undefined
export type SelectedMaterial = Material | NewMaterial
type MaterialTreeComponents = MaterialType | SelectedMaterial
export type MaterialTreeNode = SettingsTreeNode<MaterialTreeComponents>
export type MaterialTree = SettingsTree<MaterialTreeComponents>

export const isNewMaterialGuard = (
  originalEntity: MaterialTreeComponents
): originalEntity is NewMaterial => originalEntity === undefined

export const isExistingMaterialGuard = (
  originalEntity: MaterialTreeComponents
): originalEntity is Material =>
  !isNewMaterialGuard(originalEntity) && (originalEntity as Material).status !== undefined

// Material settings recipes tab
export interface NewRecipe extends Omit<Recipe, 'id' | 'status' | 'components'> {
  id: null
  status: Status.Created
  components: NewRecipeComponent[]
}

export interface NewRecipeComponent extends Omit<RecipeComponent, 'id' | 'material'> {
  id: null
  material: ComponentMaterial | NewComponentMaterial
}

interface NewComponentMaterial extends Omit<ComponentMaterial, 'id'> {
  id: null
}
