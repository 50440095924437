export const departmentsWithConfig = [
  '10_2',
  '20_4',
  '25_1',
  '25_2',
  '40_4',
  '45_1',
  '50_4',
  '55_1',
  '41_1',
  '05_5',
  '05_6'
]
