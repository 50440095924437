import {dataTestId} from '@hconnect/uikit'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Add} from '@mui/icons-material'
import {Button} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {usePermission} from '../../../permissions'

import {useMaterialsTreeData} from './hooks/useMaterialsTreeData'
import {useMaterialsWithGlobalName} from './hooks/useMaterialsWithGlobalName'
import {MaterialsTree} from './MaterialsTree'
import {MaterialsTreeFilters, FilterOptions} from './MaterialsTreeFilters'

import {getUrl, PLANT_ROUTES, useUrlParam} from '@settings/modules/routing'

interface MaterialsStructureProps {
  isNewMaterial: boolean
}

export const MaterialsStructure: React.FC<MaterialsStructureProps> = ({isNewMaterial}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const navigate = useNavigate()
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')

  const [treeQuickFilter, setTreeQuickFilter] = useState<FilterOptions>(FilterOptions.All)
  const [treeFilterText, setTreeFilterText] = useState<string>()

  const materialsWithGlobalName = useMaterialsWithGlobalName()
  const materialsTreeData = useMaterialsTreeData(materialsWithGlobalName, isNewMaterial)

  return (
    <CardBox height="100vh">
      <MaterialsTreeFilters
        treeQuickFilter={treeQuickFilter}
        setTreeQuickFilter={setTreeQuickFilter}
        setTreeFilterText={setTreeFilterText}
      />
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<Add />}
        disabled={!canChangeMaterials || isNewMaterial}
        onClick={() => navigate(getUrl(PLANT_ROUTES.MATERIALS.NEW_MATERIAL.path, {plantCode}))}
        sx={{mt: 3, mb: 3}}
        {...dataTestId('settings-tree-add-new-material-button')}
      >
        {t('materialsSettings.addMaterial')}
      </Button>
      <MaterialsTree
        treeData={materialsTreeData}
        treeQuickFilter={treeQuickFilter}
        treeFilterText={treeFilterText}
      />
    </CardBox>
  )
}
