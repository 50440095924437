import {Asset} from '@hconnect/common/types'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Radio
} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {StructureNodeUnist} from '@settings/modules/common/types'

const columns = [
  'select',
  'isAttached',
  'name',
  'type',
  'startupCost',
  'optimized',
  'minimumDowntime',
  'isShutdownAvailable',
  'startCoefficient',
  'stopCoefficient'
]

interface ProductionAssetsProps {
  assets?: Asset[]
  setSelectedAssetId: React.Dispatch<React.SetStateAction<number | undefined>>
  nodesById?: Record<string, StructureNodeUnist>
  selectedAssetId?: number
}

export const ProductionAssets: React.FC<ProductionAssetsProps> = ({
  assets,
  setSelectedAssetId,
  selectedAssetId,
  nodesById
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const {t} = useTranslation()

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSelectionChange = (assetId: number) => {
    setSelectedAssetId(assetId)
  }

  const sortedAssets = assets
    ? assets.sort((a, b) => {
        if (a.type < b.type) return -1
        if (a.type > b.type) return 1

        if (a.name < b.name) return -1
        if (a.name > b.name) return 1

        return 0
      })
    : []

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={`production-assets-column-${column}`}>
                  {t(`janusConfig.plantSetup.${column}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAssets
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((asset) => {
                const index = asset.upmId ?? asset.id
                const node = nodesById?.[index] ?? nodesById?.[asset.id]
                return (
                  <TableRow key={asset.id}>
                    <TableCell>
                      <Radio
                        checked={selectedAssetId === asset.id}
                        onChange={() => handleSelectionChange(asset.id)}
                      />
                    </TableCell>
                    <TableCell>
                      {nodesById && asset.upmId
                        ? t('janusConfig.plantSetup.yesAttachedTo', {
                            name: node?.data.name ?? t('janusConfig.plantSetup.unknown')
                          })
                        : t('janusConfig.plantSetup.no')}
                    </TableCell>
                    <TableCell>{asset.name}</TableCell>
                    <TableCell>{asset.type}</TableCell>
                    <TableCell>{asset.startupCost}</TableCell>
                    <TableCell>
                      {asset.isOptimized
                        ? t('janusConfig.plantSetup.yes')
                        : t('janusConfig.plantSetup.no')}
                    </TableCell>
                    <TableCell>{asset.minimumDowntime}</TableCell>
                    <TableCell>
                      {asset.isShutdownAvailable
                        ? t('janusConfig.plantSetup.yes')
                        : t('janusConfig.plantSetup.no')}
                    </TableCell>
                    <TableCell>{asset.startCoefficient}</TableCell>
                    <TableCell>{asset.stopCoefficient}</TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={assets?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
