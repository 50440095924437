import {Asset, RecipeComponentType, Material} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {NumberLetterSequenceIndicator} from '@hconnect/uikit/src/lib2'
import {DeleteOutlined} from '@mui/icons-material'
import {Box, Typography, ToggleButtonGroup, ToggleButton, Divider, Stack} from '@mui/material'
import React, {useState, useCallback} from 'react'
import {useFieldArray, UseFieldArrayRemove, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {findAllOperationModes} from '../../../../../assets/utils'
import {ControlledTextField, DeleteButton} from '../../../../../common/components'
import {useConfirmDialog} from '../../../../../common/providers'
import type {Schedule} from '../../../../../common/types'
import {requiredValidator} from '../../../../../common/utils'
import type {MaterialFormDefaultValues} from '../../../materials-details/ExistingMaterialDetails/hooks'
import {getNewFormRecipeComponent, calculateTotalFraction} from '../helpers'
import {RecipeComponentList, RecipeComponentAddDropdown} from '../MaterialFormRecipeComponent'
import {FormArrayRecipe, IndexedFormArrayRecipeComponent} from '../types'

import {RecipeDeleteInfo} from './RecipeDeleteInfo'

interface MaterialFormRecipeProps {
  index: number
  formRecipe: FormArrayRecipe
  disabled: boolean
  materials: Material[]
  assets: Asset[]
  schedule: Schedule
  removeRecipe: UseFieldArrayRemove
}

export const MaterialFormRecipe: React.FC<MaterialFormRecipeProps> = ({
  index,
  formRecipe,
  disabled,
  materials,
  assets,
  schedule,
  removeRecipe
}) => {
  const {t} = useTranslation()
  const {openDialog} = useConfirmDialog()

  const {control} = useFormContext<MaterialFormDefaultValues>()

  const [selectedComponentType, setSelectedComponentType] = useState<RecipeComponentType>(
    RecipeComponentType.Input
  )

  const {
    fields: formRecipeComponents,
    append: appendRecipeComponent,
    remove: removeRecipeComponent,
    update: updateRecipeComponent
  } = useFieldArray({
    control,
    name: `recipes.${index}.components`,
    keyName: 'formRecipeComponentId'
  })

  const materialIdsUsedInComponents = formRecipeComponents.map((entry) => entry.material.id)

  const indexedFormRecipeComponents: IndexedFormArrayRecipeComponent[] = formRecipeComponents.map(
    (formRecipeComponent, index) => ({
      fieldArrayRecipeComponentIndex: index,
      ...formRecipeComponent
    })
  )

  const inputComponents = indexedFormRecipeComponents.filter(
    (component) => component.type === RecipeComponentType.Input
  )

  const outputComponents = indexedFormRecipeComponents.filter(
    (component) => component.type === RecipeComponentType.Output
  )

  const formRecipeComponentsForSelectedType =
    selectedComponentType === RecipeComponentType.Input ? inputComponents : outputComponents

  const handleDeleteRecipe = useCallback(() => {
    const isNewRecipe = formRecipe.id === null

    if (isNewRecipe) {
      return removeRecipe(index)
    }
    const operationModes = findAllOperationModes(assets, [formRecipe])
    const scheduleItems = Object.values(schedule.schedules).filter(
      (item) => operationModes.find((mode) => mode.id === item.assetOperationModeId) !== undefined
    )
    openDialog({
      title: t('materialsSettings.deleteRecipe'),
      mainAction: {
        text: t('common.delete'),
        color: 'error',
        icon: <DeleteOutlined />,
        onAction: () => removeRecipe(index)
      },
      additionalContent: (
        <RecipeDeleteInfo
          recipe={formRecipe}
          operationModes={operationModes}
          scheduleItems={scheduleItems}
        />
      )
    })
  }, [index, formRecipe, assets, schedule.schedules, openDialog, removeRecipe, t])

  const handleAddRecipeComponent = useCallback(
    (type: RecipeComponentType) => {
      appendRecipeComponent(getNewFormRecipeComponent({type, fraction: 0.1}))
    },
    [appendRecipeComponent]
  )

  return (
    <Stack spacing={2} {...dataTestId('recipe_form')}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <NumberLetterSequenceIndicator
          numberIndex={index}
          {...dataTestId('recipe_form_order_number')}
        />
        <ControlledTextField
          control={control}
          formDataName={`recipes.${index}.name`}
          rules={requiredValidator(t)}
          label={t('materialsSettings.recipeName')}
          TextFieldProps={{
            disabled,
            sx: ({spacing, palette}) => ({
              width: {xs: '100%', sm: spacing(50)},
              '& .MuiInputBase-root': {
                height: spacing(7),
                borderRadius: spacing(1),
                boxShadow: 'none',
                ...(!disabled && {background: palette.background.paper})
              }
            })
          }}
          {...dataTestId('recipe_name_input')}
        />
        <RecipeComponentAddDropdown
          disabled={disabled}
          onAddComponent={handleAddRecipeComponent}
          setSelectedComponentType={setSelectedComponentType}
        />
        <DeleteButton
          onClick={handleDeleteRecipe}
          disabled={disabled}
          {...dataTestId('delete_recipe_button')}
        />
      </Stack>
      {inputComponents.length === 0 && (
        <Box {...dataTestId('no_ingredients_in_recipe_message')}>
          <Typography variant="body2" sx={{ml: 4.5, fontStyle: 'italic'}}>
            {t('materialsSettings.noIngredients')}
          </Typography>
        </Box>
      )}
      <Box>
        <ToggleButtonGroup
          value={selectedComponentType}
          exclusive
          disabled={disabled}
          onChange={(_, value: RecipeComponentType | null) => {
            if (value !== null) {
              setSelectedComponentType(value)
            }
          }}
          color="primary"
          fullWidth
          sx={({spacing}) => ({
            ml: spacing(4),
            width: spacing(50)
          })}
        >
          <ToggleButton value={RecipeComponentType.Input} {...dataTestId('recipe_ingredients')}>
            {t('materialsSettings.ingredients', {amount: inputComponents.length})}
          </ToggleButton>
          <ToggleButton value={RecipeComponentType.Output} {...dataTestId('recipe_outputs')}>
            {t('materialsSettings.outputs', {amount: outputComponents.length})}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <RecipeComponentList
        formRecipeComponents={formRecipeComponentsForSelectedType}
        fieldArrayRecipeIndex={index}
        materials={materials}
        materialIdsUsedInComponents={materialIdsUsedInComponents}
        disabled={disabled}
        removeRecipeComponent={removeRecipeComponent}
        updateRecipeComponent={updateRecipeComponent}
      />
      {formRecipeComponentsForSelectedType.length > 0 && (
        <Box>
          <Divider sx={{ml: 4, width: (theme) => theme.spacing(50)}} />
          <Stack
            direction="row"
            justifyContent={'space-between'}
            sx={{ml: 4, mt: 2, width: (theme) => theme.spacing(50)}}
          >
            <Typography variant="body1">
              {t(
                selectedComponentType === RecipeComponentType.Input
                  ? 'materialsSettings.combinedIngredients'
                  : 'materialsSettings.combinedOutput'
              )}
            </Typography>
            <Typography variant="h5" sx={{fontWeight: 'bold'}} {...dataTestId('total_fraction')}>
              {calculateTotalFraction(formRecipeComponentsForSelectedType)}%
            </Typography>
          </Stack>
        </Box>
      )}
    </Stack>
  )
}
