import {Loader} from '@hconnect/uikit/src/lib2'

import {ItemDetailsLayout} from './ItemDetailsLayout'
import {useItemDetails} from './ItemDetailsProvider'
import {General, Kpis, Layout, OperationModes, Sensors, StandardTimes, Configuration} from './tabs'

export const ItemDetails = () => {
  const {selectedTab, availableTabs} = useItemDetails()

  const renderTabContent = () => {
    if (!availableTabs.includes(selectedTab)) {
      return (
        <ItemDetailsLayout>
          <Loader />
        </ItemDetailsLayout>
      )
    }

    switch (selectedTab) {
      case 'general':
        return <General />
      case 'configuration':
        return <Configuration />
      case 'layout':
        return <Layout />
      case 'sensors':
        return <Sensors />
      case 'kpis':
        return <Kpis />
      case 'operationModes':
        return <OperationModes />
      case 'standardTimes':
        return <StandardTimes />
      default:
        return null
    }
  }

  return renderTabContent()
}
