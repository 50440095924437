import type {AuditFields} from '../common.types'
import {Status, AssetType, OperationModeType} from '../enum.types'
import type {UnNullified} from '../utility.types'

type OperationModeStatus = Status.Created | Status.Edited

interface OperationModeBaseData {
  id: number
  name: string
  throughput: number | null
  recipeId: number | null
  minimumRuntime: number
  isOptimized: boolean
  status: OperationModeStatus
}

export type ProductionOperationMode = OperationModeBaseData & {
  type: OperationModeType.Production
  powerConsumption: number
}

export type MaintenanceOperationMode = OperationModeBaseData & {
  type: OperationModeType.Maintenance
  powerConsumption: number | null // null for assets created from maintenance mode (from settings), however, there are old not migrated data at BE which contains also 0 for maintenance mode items
}

export type OperationMode = ProductionOperationMode | MaintenanceOperationMode

// we can create/edit only production modes
export type OperationModePayload = Omit<ProductionOperationMode, 'id' | 'status'>

export type ProducingOperationMode = UnNullified<OperationMode>

export interface Asset extends AuditFields {
  id: number
  name: string
  status: Status.Created | Status.Edited
  type: AssetType
  startupCost: number
  isOptimized: boolean
  minimumDowntime: number
  operationModes: OperationMode[]
  isShutdownAvailable: boolean
  startCoefficient: number
  stopCoefficient: number
  upmId?: string
  minStoptime?: number
}

export type AssetEditableFields = keyof Omit<
  Asset,
  keyof AuditFields | 'id' | 'status' | 'operationModes' | 'minStoptime'
>

export interface AssetPayload {
  type: AssetType
  name: string
  upmId?: string
}

// history asset types
type HistoricalOperationModeStatus = OperationModeStatus | Status.Deleted

export type ProductionOperationModeSnapshot = Omit<ProductionOperationMode, 'status'> & {
  status: HistoricalOperationModeStatus
}
export type MaintenanceOperationModeSnapshot = Omit<MaintenanceOperationMode, 'status'> & {
  status: HistoricalOperationModeStatus
}
export type ProducingOperationModeSnapshot = UnNullified<OperationModeSnapshot>

export type OperationModeSnapshot =
  | ProductionOperationModeSnapshot
  | MaintenanceOperationModeSnapshot

export type AssetSnapshot = Omit<Asset, 'status' | 'operationModes'> & {
  status: Status.Created | Status.Edited | Status.Deleted
  operationModes: OperationModeSnapshot[]
}

export type AssetsHistory = Record<string, AssetSnapshot[]>

// type guards
export const isProductionMode = (
  operationMode: OperationMode
): operationMode is ProductionOperationMode => operationMode.type === OperationModeType.Production

export const isProducingMode = (
  operationMode: OperationMode
): operationMode is ProducingOperationMode => !!operationMode.recipeId && !!operationMode.throughput
