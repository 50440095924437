import {MaterialSource} from '@hconnect/common/types'
import {UseFormReturn} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

import {PLANT_ROUTES, getUrl, useUrlParam} from '../../../../../routing'
import {useAddMaterial} from '../../../../hooks'

import {NewMaterialFormDefaultValues} from './useNewMaterialForm'

export const useNewMaterialFormSubmit = (
  materialForm: UseFormReturn<NewMaterialFormDefaultValues>
) => {
  const plantCode = useUrlParam('plantCode')
  const navigate = useNavigate()

  const {
    handleSubmit,
    reset,
    formState: {isDirty}
  } = materialForm

  const {mutate: addMaterial} = useAddMaterial()

  return handleSubmit((params) => {
    const {type, name, description, source, boughtFrom} = params

    // A safety net since type field cannot be changed after material creation
    // Also we don't want to send a request if nothing has changed
    if (type === null || source === null || !isDirty) return

    const materialBoughtFrom = source === MaterialSource.ProducedInPlant ? undefined : boughtFrom

    return addMaterial(
      {
        plantCode,
        dto: {
          type,
          name,
          description,
          source,
          boughtFrom: materialBoughtFrom
        }
      },
      {
        onError: () => reset(),
        onSuccess: (createdMaterial) =>
          navigate(
            getUrl(PLANT_ROUTES.MATERIALS.TYPE.MATERIAL_TYPE.ID.MATERIAL_ID.path, {
              plantCode,
              materialType: createdMaterial.type,
              materialId: String(createdMaterial.id)
            })
          )
      }
    )
  })
}
