import {Asset} from '@hconnect/common/types'
import {useMemo} from 'react'

import {useAssetsByIdQuery} from './queries'

import {useUrlParam} from '@settings/modules/routing'

export const useAssetData = () => {
  const assetId = useUrlParam('assetId')
  const {data: assetsById} = useAssetsByIdQuery()
  if (!assetsById) {
    throw new Error('BUG: Assets should be loaded before mounting this component')
  }
  const selectedAsset: Asset | undefined = assetsById[assetId]
  if (!selectedAsset) {
    throw new Error('No asset found for the given id')
  }
  return useMemo(() => selectedAsset, [selectedAsset])
}
