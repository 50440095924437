import {MaterialFormObjectDirtyFields} from '../hooks/useMaterialFormDefaultValues'

export const hasMaterialFormObjectFieldChanged = (
  formField: MaterialFormObjectDirtyFields | undefined
) => {
  return !!(
    formField &&
    typeof formField === 'object' &&
    !Array.isArray(formField) &&
    Object.values(formField).some((hasValueChanged) => hasValueChanged === true)
  )
}
