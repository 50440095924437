import {AdditivesForClinkerClassificationInputNames} from '@hconnect/common/enums'
import {MaterialType} from '@hconnect/common/types'
import {Box} from '@mui/material'

import {useMaterialsClassificationMetadata} from '../../../hooks/queries'

import {ClassificationAutocomplete} from './inputs/ClassificationAutocomplete'

interface AdditivesForClinkerClassificationProps {
  isDisabled: boolean
}

export const AdditivesForClinkerClassification: React.FC<
  AdditivesForClinkerClassificationProps
> = ({isDisabled}) => {
  const {data: additivesForClinkerClassificationMetadata} = useMaterialsClassificationMetadata(
    MaterialType.AdditivesForClinker
  )
  if (!additivesForClinkerClassificationMetadata) {
    throw new Error(
      'BUG: classification metadata should be loaded before rendering classification tab'
    )
  }

  return (
    <Box sx={{width: ({spacing}) => spacing(49)}}>
      <ClassificationAutocomplete
        materialType={MaterialType.AdditivesForClinker}
        inputName={AdditivesForClinkerClassificationInputNames.Category}
        metadata={additivesForClinkerClassificationMetadata}
        isDisabled={isDisabled}
      />
    </Box>
  )
}
