import {useSettingsQuery} from '../../../common/providers/ApiInterface'
import {useUrlParam} from '../../../routing'

export const useDepartmentConfig = (departmentId?: string) => {
  const plantCode = useUrlParam('plantCode')
  return useSettingsQuery('departmentConfig', [plantCode, departmentId], {
    enabled: !!departmentId,
    cacheTime: 0,
    retry: false,
    staleTime: 0,
    onError: (error) => error
  })
}

export const QueryKey = 'departmentConfig'
