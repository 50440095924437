import {Box, Stack} from '@mui/material'
import React, {useEffect} from 'react'

import {MonthsFilterOptions} from '../../enums'
import {Group} from '../../types'

import {CategoriesTree} from './CategoriesTree'
import {useItemGroups} from './ItemGroupsContext'
import {ResourceCard} from './ResourceCard'

export const MonthlyEntryView = ({
  showTreeContainer,
  selectedDate,
  setSelectedResource,
  selectedResource,
  isMobile,
  periodType,
  showDetails
}: {
  showTreeContainer: boolean
  selectedDate: string
  setSelectedResource: (group: Group) => void
  selectedResource?: Group
  isMobile?: boolean
  periodType: MonthsFilterOptions
  showDetails?: boolean
}) => {
  const {setItemGroups} = useItemGroups()

  useEffect(() => {
    if (showDetails && selectedResource && selectedResource.groups) {
      const groups = selectedResource.groups.map((group: Group) => group.id) || []
      setItemGroups(groups)
    } else {
      if (!selectedResource) {
        setItemGroups([])
      } else {
        setItemGroups([selectedResource.id])
      }
    }
  }, [showDetails, selectedResource, setItemGroups])

  return (
    <Box display="flex" flexDirection="row" gap={2} justifyItems="space-between" width="100%">
      {showTreeContainer && (
        <Box width={isMobile ? '100%' : '25%'}>
          <CategoriesTree
            selectedResource={selectedResource}
            setSelectedResource={setSelectedResource}
            selectedDate={selectedDate}
          />
        </Box>
      )}
      {showDetails && selectedResource && (
        <Stack width={isMobile ? '100%' : '75%'} gap={2}>
          {selectedResource.groups ? (
            selectedResource.groups.map((group) => (
              <ResourceCard
                key={group.id}
                selectedResource={group}
                selectedDate={selectedDate}
                periodType={isMobile ? MonthsFilterOptions.Month : periodType}
                parentId={group.id}
                isMobile={isMobile}
              />
            ))
          ) : (
            <ResourceCard
              key={selectedResource.id}
              selectedResource={selectedResource}
              selectedDate={selectedDate}
              periodType={isMobile ? MonthsFilterOptions.Month : periodType}
              parentId={selectedResource.id}
              isMobile={isMobile}
              showAll={true}
            />
          )}
        </Stack>
      )}
    </Box>
  )
}
