import {
  MaterialType,
  ClassificationMetadataResponse,
  ClassificationMetadataEntry
} from '@hconnect/common/types'

import {useSettingsQuery} from '../../../common/providers/ApiInterface'

export const useMaterialsClassificationMetadata = <T extends MaterialType>(materialType: T) => {
  return useSettingsQuery('classificationMetadata', [], {
    select: (data: ClassificationMetadataResponse): ClassificationMetadataEntry<T> => {
      const metadataForMaterial = data.metadata.find(
        (matadata) => matadata.materialType === materialType
      )
      if (!metadataForMaterial) {
        throw new Error(`Metadata for material type ${materialType} not found`)
      }
      return metadataForMaterial as ClassificationMetadataEntry<T>
    }
  })
}
