import {dataTestId, useTranslation} from '@hconnect/uikit'
import {CardBox, Loader} from '@hconnect/uikit/src/lib2'
import {Box, Stack, Typography} from '@mui/material'
import moment from 'moment'
import React, {useMemo} from 'react'

import {Status} from '../../enums'
import {useCategoriesTree} from '../../hooks/useCategoriesTree'
import {Group} from '../../types'

import {CategoriesHeader} from './CategoriesHeader'
import {CategoryItem} from './CategoryItem'

const calculatePercentage = (rootGroups: Group[]) => {
  let totalGroups = 0
  let matchedStatusGroups = 0

  const countGroups = (groups: Group[] = []) => {
    groups.forEach((group) => {
      totalGroups++
      if ([Status.Saved, Status.PartiallySaved, Status.Approved].includes(group.status)) {
        matchedStatusGroups++
      }
      countGroups(group.groups)
    })
  }

  rootGroups.forEach((rootGroup) => {
    countGroups(rootGroup.groups)
  })

  return totalGroups === 0 ? 0 : (matchedStatusGroups / totalGroups) * 100
}

export const CategoriesTree = ({
  selectedDate,
  width,
  selectedResource,
  setSelectedResource
}: {
  selectedDate: string
  width?: string
  selectedResource?: Group
  setSelectedResource: (group: Group) => void
}) => {
  const {data: tree, isLoading, isFetching} = useCategoriesTree(selectedDate)

  const formattedDate = useMemo(
    () => moment(selectedDate).format('MMMM YYYY').toString(),
    [selectedDate]
  )

  const {t} = useTranslation()

  return (
    <CardBox width={width ?? '100%'} {...dataTestId('categories-tree-container')}>
      {isLoading || isFetching ? (
        <Loader />
      ) : !tree || tree.businessAreas.length === 0 ? (
        <Box width="100%" display="flex" flexDirection="row" justifyContent="center">
          <Typography>{t('janusConfig.dailyEntry.noData')}</Typography>
        </Box>
      ) : (
        <Stack
          direction="column"
          justifyContent="space-between"
          {...dataTestId('categories-tree')}
          alignItems={'left'}
        >
          <CategoriesHeader
            selectedDate={formattedDate}
            progress={calculatePercentage(tree?.businessAreas)}
          />
          {tree?.businessAreas.map((area) => (
            <CategoryItem
              key={area.id}
              data={area}
              selectedItemId={selectedResource?.id}
              level={0}
              onClick={(item) => setSelectedResource(item)}
            />
          ))}
        </Stack>
      )}
    </CardBox>
  )
}
