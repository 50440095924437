import type {Asset, MaterialWithRecipes} from '@hconnect/common/types'
import {DeleteOutlined, DeleteOutline} from '@mui/icons-material'
import {ListItemSecondaryAction} from '@mui/material'
import React from 'react'
import {NodeApi} from 'react-arborist'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import type {Schedule} from '../../../common/types'
import {MaterialTreeNode, isExistingMaterialGuard} from '../../types'

import {MaterialDeleteInfo} from './MaterialDeleteInfo'

import {findAllOperationModes, useAssetsQuery} from '@settings/modules/assets'
import {KebabMenu} from '@settings/modules/common/components'
import {useScheduleQuery} from '@settings/modules/common/hooks'
import {useConfirmDialog} from '@settings/modules/common/providers'
import {useDeleteMaterial, useMaterialWithRecipesQuery} from '@settings/modules/materials/hooks'
import {usePermission} from '@settings/modules/permissions'
import {PLANT_ROUTES, getUrl, useUrlParam} from '@settings/modules/routing'

interface MaterialsTreeNodeActionsProps {
  node: NodeApi<MaterialTreeNode>
}

export const MaterialNodeActions: React.FC<MaterialsTreeNodeActionsProps> = ({node}) => {
  const {t} = useTranslation()
  const plantCode = useUrlParam('plantCode')
  const materialType = useUrlParam('materialType')
  const navigate = useNavigate()
  const redirectToMaterialType = () => {
    navigate(
      getUrl(PLANT_ROUTES.MATERIALS.TYPE.MATERIAL_TYPE.path, {
        plantCode,
        materialType
      })
    )
  }
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')
  const {openDialog} = useConfirmDialog()

  const {mutate: deleteMaterial, isLoading: isMaterialDeleting} = useDeleteMaterial()

  const originalEntity = node.data.originalEntity
  const isExistingMaterialNode = isExistingMaterialGuard(originalEntity)
  const selectedMaterialId = isExistingMaterialNode ? Number(node.data.id) : undefined

  const {data: assets} = useAssetsQuery()
  const {data: schedule} = useScheduleQuery()
  const {data: materialWithRecipes} = useMaterialWithRecipesQuery(selectedMaterialId)

  const openDeleteMaterialDialog = (
    schedule: Schedule,
    assets: Asset[],
    material: MaterialWithRecipes
  ) => {
    const operationModes = findAllOperationModes(assets, material.recipes)
    const scheduleItems = Object.values(schedule.schedules).filter(
      (item) => operationModes.find((mode) => mode.id === item.assetOperationModeId) !== undefined
    )
    openDialog({
      title: t('materialsSettings.deleteMaterial'),
      mainAction: {
        color: 'error',
        text: t('common.delete'),
        icon: <DeleteOutline />,
        onAction: () =>
          deleteMaterial({plantCode, materialId: material.id}, {onSuccess: redirectToMaterialType})
      },
      additionalContent: (
        <MaterialDeleteInfo
          material={material}
          operationModes={operationModes}
          scheduleItems={scheduleItems}
        />
      )
    })
  }

  return (
    <ListItemSecondaryAction>
      <KebabMenu
        id={`${`settings-tree-item-${node.data.name}`}-menu`}
        actions={
          schedule && assets && materialWithRecipes
            ? [
                {
                  icon: <DeleteOutlined fontSize="small" color="error" />,
                  title: t('common.delete'),
                  testId: 'delete_material',
                  isDisabled: isMaterialDeleting || !canChangeMaterials,
                  onClick: () => openDeleteMaterialDialog(schedule, assets, materialWithRecipes)
                }
              ]
            : []
        }
      />
    </ListItemSecondaryAction>
  )
}
