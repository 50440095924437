import {dataTestId} from '@hconnect/uikit'
import {Divider, ListItemButton, ListItemText, Box} from '@mui/material'
import React, {useEffect} from 'react'
import {NodeRendererProps} from 'react-arborist'
import {useNavigate} from 'react-router'

import {MaterialTreeNode, isExistingMaterialGuard, isNewMaterialGuard} from '../../types'

import {MaterialTypeNodeActions} from './MaterialCategoryNodeActions'
import {MaterialNodeActions} from './MaterialNodeActions'
import {NewMaterialNodeActions} from './NewMaterialNodeActions'

import {PLANT_ROUTES, getUrl, useUrlParam} from '@settings/modules/routing'

export const MaterialsTreeNode: React.FC<NodeRendererProps<MaterialTreeNode>> = ({node, style}) => {
  const plantCode = useUrlParam('plantCode')
  const navigate = useNavigate()

  const originalEntity = node.data.originalEntity
  const isExistingMaterialNode = isExistingMaterialGuard(originalEntity)
  const isNewMaterialNode = isNewMaterialGuard(originalEntity)
  const isMaterialNode = isExistingMaterialNode || isNewMaterialNode

  const onTreeNodeClick = (e: React.MouseEvent) => {
    if (isExistingMaterialNode) {
      navigate(
        getUrl(PLANT_ROUTES.MATERIALS.TYPE.MATERIAL_TYPE.ID.MATERIAL_ID.path, {
          plantCode,
          materialType: originalEntity.type,
          materialId: String(originalEntity.id)
        })
      )
    } else {
      node.toggle()
      // We use stopPropagation to not remove selection from the leaf node
      e.stopPropagation()
    }
  }

  useEffect(() => {
    // If we have a new material node, we need to select it
    if (isNewMaterialNode && !node.isSelected) node.select()
  }, [isNewMaterialNode, node])

  return (
    <Box {...dataTestId(`settings-tree-${isMaterialNode ? 'leaf' : 'internal'}`)}>
      <ListItemButton
        selected={isMaterialNode && node.isSelected}
        onClick={onTreeNodeClick}
        style={style}
        sx={{height: '58px'}}
        {...dataTestId(`settings-tree-item-${isMaterialNode ? node.data.name : originalEntity}`)}
      >
        <ListItemText
          primary={node.data.name}
          secondary={isMaterialNode && !isNewMaterialNode && originalEntity.globalName}
          sx={{pl: 1.5}}
        />
        {!isMaterialNode && <MaterialTypeNodeActions node={node} />}
        {isExistingMaterialNode && node.isSelected && <MaterialNodeActions node={node} />}
        {isNewMaterialNode && <NewMaterialNodeActions node={node} />}
      </ListItemButton>
      <Divider />
    </Box>
  )
}
