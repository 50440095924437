import {MaterialSettingsTabs, MaterialSource, MaterialWithRecipes} from '@hconnect/common/types'
import {Card} from '@hconnect/uikit/src/lib2'
import {useState, useCallback} from 'react'
import {FormProvider} from 'react-hook-form'
import {useIsMutating} from 'react-query'

import {HeaderTab, HeaderTabs} from '../../../../common/components/HeaderTabs'
import {MaterialDetailsCardHeader} from '../common/MaterialDetailsCardHeader'

import {ExistingMaterialDetailsCardContent} from './ExistingMaterialDetailsCardContent'
import {useMaterialForm, useMaterialFormSubmit, useExistingMaterialTabs} from './hooks'

interface MaterialDetailsCardProps {
  material: MaterialWithRecipes
}

export const ExistingMaterialDetailsCard: React.FC<MaterialDetailsCardProps> = ({material}) => {
  // Preventing user access to invalid tabs while saving
  const runningMutations = useIsMutating(['editMaterial'])
  const isMaterialSaving = Boolean(runningMutations)

  const materialForm = useMaterialForm(material)

  const availableTabs = useExistingMaterialTabs({
    materialWithRecipes: material,
    materialForm,
    isLoading: isMaterialSaving
  })
  const [selectedTab, setSelectedTab] = useState<HeaderTab>(availableTabs[0])

  const onCancelClickHandler = useCallback(() => {
    const isMaterialOnlyBoughtByDefault =
      materialForm.formState.defaultValues?.source === MaterialSource.BoughtFromVendor

    const shouldTabBeHidden = [
      MaterialSettingsTabs.Recipes,
      MaterialSettingsTabs.PxTrendTags
    ].includes(selectedTab.value as MaterialSettingsTabs)

    if (isMaterialOnlyBoughtByDefault && shouldTabBeHidden) {
      setSelectedTab(availableTabs[0])
    }
    materialForm.reset()
  }, [materialForm, availableTabs, selectedTab.value])

  return (
    <Card
      headerContent={
        <MaterialDetailsCardHeader
          headerTitle={material.name}
          headerSubtitle={material.globalName}
          isCancelDisabled={isMaterialSaving || !materialForm.formState.isDirty}
          isSaveDisabled={isMaterialSaving || !materialForm.formState.isDirty}
          onCancelClick={onCancelClickHandler}
          onSaveClick={useMaterialFormSubmit(material, materialForm)}
        />
      }
    >
      <HeaderTabs
        tabs={availableTabs}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        testIdPrefix={'material-settings-card'}
        sxTabs={{mb: 3}}
        sxTab={{color: ({palette}) => palette.common.black, fontWeight: 500}}
      />
      <FormProvider {...materialForm}>
        <ExistingMaterialDetailsCardContent selectedMaterial={material} selectedTab={selectedTab} />
      </FormProvider>
    </Card>
  )
}
