import {StructureNode} from '../types'

export const mapToStructureWithCodesPath = (
  node: StructureNode,
  parentPath: string = ''
): StructureNode => {
  const currentCode = node.entityProperties?.code || ''
  const currentPath = parentPath + (currentCode ? (parentPath ? '_' : '') + currentCode : '')

  const children =
    node.children?.map((child) => mapToStructureWithCodesPath(child, currentPath)) || null

  return {
    ...node,
    entityCodesPath: currentPath,
    children
  }
}
