import ClearIcon from '@mui/icons-material/Clear'
import {Chip, Box} from '@mui/material'
import React, {useState, useRef, useEffect} from 'react'

export const ChipInput = ({
  options,
  onRemove
}: {
  options: string[]
  onRemove: (option: string) => void
}) => {
  const [visibleChips, setVisibleChips] = useState<string[]>([])
  const [hiddenCount, setHiddenCount] = useState(0)
  const inputRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const updateChips = () => {
      if (!inputRef.current) return
      const containerWidth = inputRef.current.offsetWidth
      const tempVisible: string[] = []
      let usedWidth = 0
      let countHidden = 0

      options.forEach((option) => {
        const chipWidth = option.length * 8 + 50
        if (usedWidth + chipWidth < containerWidth - 50) {
          tempVisible.push(option)
          usedWidth += chipWidth
        } else {
          countHidden += 1
        }
      })

      setVisibleChips(tempVisible)
      setHiddenCount(countHidden)
    }

    updateChips()
    window.addEventListener('resize', updateChips)
    return () => window.removeEventListener('resize', updateChips)
  }, [options])

  return (
    <Box
      ref={inputRef}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1
      }}
    >
      {visibleChips.map((option) => (
        <Chip
          onMouseDown={(e) => e.stopPropagation()}
          key={option}
          label={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
                width: '100%',
                m: 0,
                p: 0
              }}
            >
              <span>{option}</span>
              <ClearIcon
                onClick={(e) => {
                  onRemove(option)
                  e.stopPropagation()
                }}
                sx={{cursor: 'pointer'}}
              />
            </Box>
          }
          sx={{borderRadius: 1}}
        />
      ))}
      {hiddenCount > 0 && <Chip label={`+${hiddenCount}`} sx={{borderRadius: 1}} />}
    </Box>
  )
}
