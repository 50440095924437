import {RawMaterialsClassificationInputNames} from '@hconnect/common/enums'
import {MaterialType} from '@hconnect/common/types'
import {dataTestId, useTranslation} from '@hconnect/uikit'
import {InfoOutlined} from '@mui/icons-material'
import {Stack, Tooltip, Typography} from '@mui/material'
import {useFormContext} from 'react-hook-form'

import {useMaterialsClassificationMetadata} from '../../../hooks'
import {MaterialFormDefaultValues} from '../../materials-details/ExistingMaterialDetails/hooks'

import {ClassificationAutocomplete} from './inputs/ClassificationAutocomplete'
import {ClassificationCheckBox} from './inputs/ClassificationCheckbox'
import {ClassificationSegmentedButton} from './inputs/ClassificationSegmentedButton'

interface RawMaterialsClassificationProps {
  isDisabled: boolean
}

export const RawMaterialsClassification: React.FC<RawMaterialsClassificationProps> = ({
  isDisabled
}) => {
  const {t} = useTranslation()

  const {data: rawMaterialsClassificationMetadata} = useMaterialsClassificationMetadata(
    MaterialType.RawMaterials
  )

  const {setValue, getValues, clearErrors} = useFormContext<MaterialFormDefaultValues>()

  if (!rawMaterialsClassificationMetadata) {
    throw new Error(
      'BUG: classification metadata should be loaded before rendering classification tab'
    )
  }

  return (
    <Stack
      spacing={2}
      {...dataTestId('clinker_classification_form')}
      sx={{maxWidth: ({spacing}) => spacing(52)}}
    >
      <Stack direction="row" alignItems="center">
        <ClassificationCheckBox
          materialType={MaterialType.RawMaterials}
          inputName={RawMaterialsClassificationInputNames.IsThermallyProcessedClinkerSubstitute}
          customValues={{trueValue: 'Yes', falseValue: 'No'}}
          metadata={rawMaterialsClassificationMetadata}
          isDisabled={isDisabled}
          onChangeCb={() => {
            const classificationValues = getValues('classification')

            const isThermallyProcessedClinkerSubstitute =
              classificationValues[
                RawMaterialsClassificationInputNames.IsThermallyProcessedClinkerSubstitute
              ] !== 'Yes'

            setValue('classification', {
              ...classificationValues,
              [RawMaterialsClassificationInputNames.RawMaterialType]:
                isThermallyProcessedClinkerSubstitute ? 'Conventional' : null,
              [RawMaterialsClassificationInputNames.Category]: null,
              [RawMaterialsClassificationInputNames.RawMaterialCategory]: null,
              [RawMaterialsClassificationInputNames.RawMaterialSubCategory]: null,
              [RawMaterialsClassificationInputNames.FormSize]: null,
              [RawMaterialsClassificationInputNames.ProcessingDegree]: null
            })
            clearErrors([
              `classification.${RawMaterialsClassificationInputNames.RawMaterialCategory}`,
              `classification.${RawMaterialsClassificationInputNames.RawMaterialSubCategory}`,
              `classification.${RawMaterialsClassificationInputNames.RawMaterialType}`,
              `classification.${RawMaterialsClassificationInputNames.FormSize}`,
              `classification.${RawMaterialsClassificationInputNames.ProcessingDegree}`
            ])
          }}
        />
        <Tooltip
          title={
            <Stack spacing={1}>
              <Typography variant="body1">
                {t(
                  'materialsSettings.classificationInputs.RawMaterials.isThermallyProcessedClinkerSubstituteTooltip'
                )}
              </Typography>
              <Typography variant="body2">
                {t(
                  'materialsSettings.classificationInputs.RawMaterials.isThermallyProcessedClinkerSubstituteTooltipDescription'
                )}
              </Typography>
            </Stack>
          }
          placement="right"
        >
          <InfoOutlined fontSize="small" />
        </Tooltip>
      </Stack>
      <ClassificationAutocomplete
        materialType={MaterialType.RawMaterials}
        inputName={RawMaterialsClassificationInputNames.Category}
        metadata={rawMaterialsClassificationMetadata}
        isDisabled={isDisabled}
      />
      <ClassificationSegmentedButton
        materialType={MaterialType.RawMaterials}
        inputName={RawMaterialsClassificationInputNames.RawMaterialType}
        metadata={rawMaterialsClassificationMetadata}
        isDisabled={isDisabled}
        onChangeCb={() => {
          setValue('classification', {
            ...getValues('classification'),
            [RawMaterialsClassificationInputNames.RawMaterialCategory]: null,
            [RawMaterialsClassificationInputNames.RawMaterialSubCategory]: null,
            [RawMaterialsClassificationInputNames.FormSize]: null,
            [RawMaterialsClassificationInputNames.ProcessingDegree]: null
          })
          clearErrors([
            `classification.${RawMaterialsClassificationInputNames.RawMaterialCategory}`,
            `classification.${RawMaterialsClassificationInputNames.RawMaterialSubCategory}`,
            `classification.${RawMaterialsClassificationInputNames.FormSize}`,
            `classification.${RawMaterialsClassificationInputNames.ProcessingDegree}`
          ])
        }}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.RawMaterials}
        inputName={RawMaterialsClassificationInputNames.RawMaterialCategory}
        metadata={rawMaterialsClassificationMetadata}
        onChangeCb={() => {
          setValue('classification', {
            ...getValues('classification'),
            [RawMaterialsClassificationInputNames.RawMaterialSubCategory]: null,
            [RawMaterialsClassificationInputNames.FormSize]: null,
            [RawMaterialsClassificationInputNames.ProcessingDegree]: null
          })
          clearErrors([
            `classification.${RawMaterialsClassificationInputNames.RawMaterialSubCategory}`,
            `classification.${RawMaterialsClassificationInputNames.FormSize}`,
            `classification.${RawMaterialsClassificationInputNames.ProcessingDegree}`
          ])
        }}
        isDisabled={isDisabled}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.RawMaterials}
        inputName={RawMaterialsClassificationInputNames.RawMaterialSubCategory}
        metadata={rawMaterialsClassificationMetadata}
        isDisabled={isDisabled}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.RawMaterials}
        inputName={RawMaterialsClassificationInputNames.FormSize}
        metadata={rawMaterialsClassificationMetadata}
        isDisabled={isDisabled}
      />
      <ClassificationAutocomplete
        materialType={MaterialType.RawMaterials}
        inputName={RawMaterialsClassificationInputNames.ProcessingDegree}
        metadata={rawMaterialsClassificationMetadata}
        isDisabled={isDisabled}
      />
    </Stack>
  )
}
