import type {Asset} from '@hconnect/common/types'

import type {ScheduleItemDict} from '../types'

export const validateScheduleItemsHaveExistingOperationModes = (
  scheduleItems: ScheduleItemDict,
  assets: Asset[]
) => {
  const operationModeIds = new Set(
    assets.flatMap((asset) => asset.operationModes.map((mode) => mode.id))
  )
  Object.values(scheduleItems).forEach((item) => {
    if (!operationModeIds.has(item.assetOperationModeId)) {
      throw new Error(
        `Schedule item with id ${item.id} has a nonexisting operation mode with id ${item.assetOperationModeId}`
      )
    }
  })
}
