import type {OperationMode, AssetTransition, Asset} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Grid} from '@mui/material'
import {groupBy} from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {FakeTransitionForm} from './FakeTransitionForm'
import {TransitionForm} from './TransitionForm'

interface SwitchMatrixFormProps {
  asset: Asset
  transitions: AssetTransition[]
  operationModes: OperationMode[]
  isReadOnly: boolean
}

export const SwitchMatrixForm: React.FC<SwitchMatrixFormProps> = ({
  asset,
  transitions,
  operationModes,
  isReadOnly
}) => {
  const {t} = useTranslation()

  const operationModesWithTransitions = useMemo(() => {
    const transitionToDict = groupBy(transitions, (transition) => transition.to)
    const sortedOperationModes = [...operationModes].sort((a, b) => a.id - b.id)

    const getTransition = (operationModeId: number): AssetTransition | undefined =>
      transitionToDict[operationModeId]?.[0]

    return sortedOperationModes.map((operationMode) => ({
      transition: getTransition(operationMode.id),
      operationMode
    }))
  }, [transitions, operationModes])

  return (
    <Grid container spacing={2} {...dataTestId('switch_matrix_form')}>
      {operationModesWithTransitions.map(({operationMode, transition}, index) => (
        <Grid key={index} item xs={3}>
          {transition ? (
            <TransitionForm
              key={`${transition.from}-${transition.to}`}
              asset={asset}
              isReadOnly={isReadOnly}
              label={t('assetsSettings.transitionTo', {name: operationMode.name})}
              transition={transition}
            />
          ) : (
            <FakeTransitionForm
              isReadOnly={isReadOnly}
              label={t('assetsSettings.transitionTo', {name: operationMode.name})}
            />
          )}
        </Grid>
      ))}
    </Grid>
  )
}
