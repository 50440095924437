import {MaterialWithRecipes, MaterialSettingsTabs} from '@hconnect/common/types'
import {useMemo} from 'react'

import {HeaderTab} from '../../../../common/components/HeaderTabs'
import {
  MaterialSettingsGeneralTab,
  MaterialSettingsLimsMaterialsTab,
  MaterialSettingsProductsTab,
  MaterialSettingsPxTrendTab,
  MaterialSettingsRecipesTab,
  MaterialSettingsClassificationTab
} from '../../materials-tabs'

interface MaterialDetailsCardProps {
  selectedTab: HeaderTab
  selectedMaterial: MaterialWithRecipes
}

export const ExistingMaterialDetailsCardContent: React.FC<MaterialDetailsCardProps> = ({
  selectedTab,
  selectedMaterial
}) => {
  const selectedTabValue = selectedTab.value as MaterialSettingsTabs

  const TabContentComponent = useMemo(() => {
    switch (selectedTabValue) {
      case MaterialSettingsTabs.General:
        return MaterialSettingsGeneralTab
      case MaterialSettingsTabs.Classification:
        return MaterialSettingsClassificationTab
      case MaterialSettingsTabs.SapCodes:
        return MaterialSettingsProductsTab
      case MaterialSettingsTabs.LimsCodes:
        return MaterialSettingsLimsMaterialsTab
      case MaterialSettingsTabs.Recipes:
        return MaterialSettingsRecipesTab
      case MaterialSettingsTabs.PxTrendTags:
        return MaterialSettingsPxTrendTab
      default:
        throw new Error(`BUG: Unknown tab value: ${selectedTabValue}`)
    }
  }, [selectedTabValue])

  return <TabContentComponent material={selectedMaterial} />
}
