import {RecipeComponent} from '@hconnect/common/types'

import {NewRecipeComponent} from '@settings/modules/materials/types'

export const calculateTotalFraction = (
  recipeComponents: (RecipeComponent | NewRecipeComponent)[]
) => {
  const total = recipeComponents.reduce((sum, component) => sum + component.fraction * 100, 0)
  return Math.round(total * 100) / 100
}
