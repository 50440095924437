import type {Asset, OperationMode} from '@hconnect/common/types'
import {Status, AssetType, OperationModeType} from '@hconnect/common/types'

const now = new Date('2023-01-16T17:47:33Z')
const assetIds: number[] = []
const operationIds: number[] = []
const createOperationMode = ({
  id,
  power,
  type,
  throughput,
  recipeId,
  name = `operation mode with id ${id}`
}: {
  id: number
  power: number
  type: OperationModeType
  throughput?: number
  recipeId?: number
  name?: string
}): OperationMode => {
  if (operationIds.includes(id)) {
    throw new Error('duplicate operation id in test data')
  }
  return {
    id,
    name,
    type,
    powerConsumption: power,
    throughput: throughput ?? null,
    recipeId: recipeId ?? null,
    status: Status.Created,
    minimumRuntime: 0,
    isOptimized: false
  }
}
const createAsset = (
  {
    id,
    name,
    type,
    isOptimized = false,
    startupCost = 127
  }: {
    id: number
    name: string
    type: AssetType
    isOptimized?: boolean
    startupCost?: number
  },
  operationModes: OperationMode[]
): Asset => {
  const idExists = assetIds.includes(id)
  if (idExists) {
    throw new Error('duplicate asset id in test data')
  }
  return {
    id,
    type,
    name,
    status: Status.Created,
    startupCost,
    createdOn: new Date(now.getDate() + id).toISOString(),
    createdBy: 'Test user',
    minimumDowntime: 0,
    isShutdownAvailable: true,
    isOptimized,
    operationModes,
    startCoefficient: 1,
    stopCoefficient: 1
  }
}

const assets: Asset[] = [
  createAsset({id: 1, name: 'ZM6', type: AssetType.CementMill, isOptimized: true}, [
    createOperationMode({
      id: 13,
      power: 2,
      type: OperationModeType.Production,
      throughput: 100,
      recipeId: 1
    }),
    createOperationMode({
      id: 17,
      power: 4,
      type: OperationModeType.Production,
      throughput: 50,
      recipeId: 6
    }),
    createOperationMode({
      id: 18,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 78,
      recipeId: 1
    }),
    createOperationMode({
      id: 19,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 52,
      recipeId: 7
    }),
    createOperationMode({
      id: 20,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 68,
      recipeId: 8
    }),
    createOperationMode({
      id: 21,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 107,
      recipeId: 3
    }),
    createOperationMode({
      id: 22,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 52,
      recipeId: 9
    }),
    createOperationMode({
      id: 23,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 77,
      recipeId: 10
    }),
    createOperationMode({
      id: 24,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 83,
      recipeId: 4
    }),
    createOperationMode({
      id: 25,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 78,
      recipeId: 5
    }),
    createOperationMode({id: 76, power: 0, type: OperationModeType.Maintenance})
  ]),
  createAsset({id: 31, name: 'Packerei', type: AssetType.Other}, [
    (createOperationMode({id: 55, power: 0.3, type: OperationModeType.Production}),
    createOperationMode({id: 65, power: 0, type: OperationModeType.Maintenance}))
  ]),
  createAsset({id: 39, name: 'ZM6-2', type: AssetType.CementMill, isOptimized: true}, [
    createOperationMode({
      id: 170,
      power: 4,
      type: OperationModeType.Production,
      throughput: 50,
      recipeId: 6
    }),
    createOperationMode({
      id: 180,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 78,
      recipeId: 1
    }),
    createOperationMode({
      id: 190,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 52,
      recipeId: 7
    }),
    createOperationMode({
      id: 200,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 68,
      recipeId: 8
    }),
    createOperationMode({
      id: 210,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 107,
      recipeId: 3
    }),
    createOperationMode({
      id: 220,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 52,
      recipeId: 9
    }),
    createOperationMode({
      id: 230,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 77,
      recipeId: 10
    }),
    createOperationMode({
      id: 240,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 83,
      recipeId: 4
    }),
    createOperationMode({
      id: 250,
      power: 2.8,
      type: OperationModeType.Production,
      throughput: 78,
      recipeId: 5
    }),
    createOperationMode({id: 760, power: 0, type: OperationModeType.Maintenance})
  ]),
  createAsset({id: 30, name: 'BPG-Mühle', type: AssetType.Other}, [
    createOperationMode({id: 52, power: 1, type: OperationModeType.Maintenance}), // previously production
    createOperationMode({id: 64, power: 0, type: OperationModeType.Maintenance})
  ]),
  createAsset({id: 29, name: 'Kohlenmühle', type: AssetType.Other}, [
    createOperationMode({id: 51, power: 1.3, type: OperationModeType.Maintenance}), // previous production
    createOperationMode({id: 63, power: 0, type: OperationModeType.Maintenance})
  ]),
  createAsset({id: 42, name: 'RM1', type: AssetType.RawMill, isOptimized: true}, [
    createOperationMode({
      id: 36,
      power: 3.9,
      type: OperationModeType.Production,
      throughput: 214,
      recipeId: 13
    }),
    createOperationMode({
      id: 37,
      power: 3.9,
      type: OperationModeType.Production,
      throughput: 171,
      recipeId: 12
    }),
    createOperationMode({id: 68, power: 0, type: OperationModeType.Maintenance})
  ]),
  // TODO check there's no crusher type but the original was a crusher
  createAsset({id: 26, name: 'Crusher', type: AssetType.Other}, [
    createOperationMode({id: 50, power: 1.7, type: OperationModeType.Maintenance}), // previously production
    createOperationMode({id: 60, power: 0, type: OperationModeType.Maintenance})
  ]),
  createAsset({id: 32, name: 'Schottertr.', type: AssetType.Other}, [
    createOperationMode({id: 54, power: 0.4, type: OperationModeType.Maintenance}), // previously production
    createOperationMode({id: 66, power: 0, type: OperationModeType.Maintenance})
  ]),
  createAsset({id: 40, name: 'ZM7', type: AssetType.CementMill, isOptimized: true}, [
    createOperationMode({
      id: 26,
      power: 1.9,
      type: OperationModeType.Production,
      throughput: 40,
      recipeId: 6
    }),
    createOperationMode({
      id: 27,
      power: 1.9,
      type: OperationModeType.Production,
      throughput: 90,
      recipeId: 1
    }),
    createOperationMode({
      id: 28,
      power: 1.9,
      type: OperationModeType.Production,
      throughput: 83,
      recipeId: 7
    }),
    createOperationMode({
      id: 29,
      power: 1.9,
      type: OperationModeType.Production,
      throughput: 87,
      recipeId: 8
    }),
    createOperationMode({
      id: 30,
      power: 1.9,
      type: OperationModeType.Production,
      throughput: 130,
      recipeId: 3
    }),
    createOperationMode({
      id: 31,
      power: 1.9,
      type: OperationModeType.Production,
      throughput: 50,
      recipeId: 9
    }),
    createOperationMode({
      id: 32,
      power: 1.9,
      type: OperationModeType.Production,
      throughput: 90,
      recipeId: 11
    }),
    createOperationMode({
      id: 33,
      power: 1.9,
      type: OperationModeType.Production,
      throughput: 106,
      recipeId: 10
    }),
    createOperationMode({
      id: 34,
      power: 1.9,
      type: OperationModeType.Production,
      throughput: 90,
      recipeId: 5
    }),
    createOperationMode({id: 77, power: 0, type: OperationModeType.Maintenance})
  ]),
  createAsset({id: 27, name: 'WT01', type: AssetType.RotaryKiln}, [
    createOperationMode({
      id: 53,
      power: 1,
      type: OperationModeType.Production,
      throughput: -100,
      recipeId: 13
    }),
    createOperationMode({
      id: 902,
      power: 5.2,
      type: OperationModeType.Production,
      throughput: -50,
      recipeId: 12
    }),
    createOperationMode({
      id: 903,
      power: 4,
      type: OperationModeType.Production,
      throughput: 40,
      recipeId: 14
    }),
    createOperationMode({
      id: 61,
      power: 0,
      type: OperationModeType.Maintenance,
      name: 'Unplanned Maintenance'
    }),
    createOperationMode({
      id: 62,
      power: 0,
      type: OperationModeType.Maintenance,
      name: 'S2029 Planned Outage - Production Program'
    }),
    createOperationMode({
      id: 70,
      power: 0,
      type: OperationModeType.Maintenance,
      name: 'S2033 Planned Outage - Second Scheduled Outage'
    }),
    createOperationMode({
      id: 71,
      power: 0,
      type: OperationModeType.Maintenance,
      name: 'S2028 Planned Outage - Annual Overhaul'
    })
  ]),
  createAsset({id: 43, name: 'RM2', type: AssetType.RawMill}, [
    createOperationMode({
      id: 38,
      power: 3.9,
      type: OperationModeType.Production,
      throughput: 209,
      recipeId: 13
    }),
    createOperationMode({id: 69, power: 0, type: OperationModeType.Maintenance})
  ]),
  createAsset({id: 38, name: 'ZM5', type: AssetType.CementMill}, [
    createOperationMode({
      id: 1300,
      power: 2,
      type: OperationModeType.Production,
      throughput: 100,
      recipeId: 1
    }),
    createOperationMode({
      id: 14,
      power: 2.5,
      type: OperationModeType.Production,
      throughput: 112,
      recipeId: 3
    }),
    createOperationMode({
      id: 15,
      power: 2.5,
      type: OperationModeType.Production,
      throughput: 87,
      recipeId: 4
    }),
    createOperationMode({
      id: 16,
      power: 2.5,
      type: OperationModeType.Production,
      throughput: 80,
      recipeId: 5
    }),
    createOperationMode({id: 75, power: 0, type: OperationModeType.Maintenance})
  ]),
  createAsset({id: 909, name: 'BaseLoad', type: AssetType.BaseLoad}, [
    createOperationMode({
      id: 1900,
      power: 2.5,
      type: OperationModeType.Production,
      throughput: undefined,
      recipeId: undefined
    }),
    createOperationMode({id: 1901, power: 0, type: OperationModeType.Maintenance})
  ])
]

// eslint-disable-next-line import/no-default-export
export default assets
