import React from 'react'
import {Outlet} from 'react-router-dom'

import {LayoutSettings} from '../../common/components'

import {MaterialsStructure} from './materials-tree/MaterialsStructure'

interface PageMaterialStructureProps {
  isNewMaterial?: boolean
}

export const PageMaterialStructure: React.FC<PageMaterialStructureProps> = ({
  isNewMaterial = false
}) => {
  return (
    <LayoutSettings
      list={<MaterialsStructure isNewMaterial={isNewMaterial} />}
      details={<Outlet />}
    />
  )
}
