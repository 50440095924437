import {MaterialWithRecipes, RecipeComponentType} from '@hconnect/common/types'

import {NewRecipeComponent} from '@settings/modules/materials/types'

interface getNewFormRecipeComponentProps {
  type: RecipeComponentType
  fraction: number
  material?: MaterialWithRecipes
}

export const getNewFormRecipeComponent = ({
  type,
  fraction,
  material
}: getNewFormRecipeComponentProps): NewRecipeComponent => {
  return {
    id: null,
    type,
    fraction,
    material: {
      id: material ? material.id : null,
      name: material ? material.name : ''
    }
  }
}
