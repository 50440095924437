import {
  Material,
  MaterialWithRecipes,
  MaterialType,
  Status,
  AssetType,
  MaterialSource,
  AddMaterialDTO,
  EditMaterialDTO,
  Recipe,
  RecipeDTO
} from '@hconnect/common/types'
import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {isMaterialTypeBoughtFromVendor} from '../../../src/modules/materials/helpers/getProductsPerMaterialSource'
import {getMaterialWithAssignedLimsMaterial} from '../../modules/materials/components/materials-tabs/lims-materials/helpers'
import {getMaterialWithAttachedProduct} from '../../modules/materials/components/materials-tabs/products/helpers'
import {MockState} from '../mockState'
import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx, saveScenario} from './utils'

export const enableMaterialsEndpoints = (mock: MockAdapter) => {
  // GET materials (C#)
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials$`)).reply(() => {
    const {
      burglengenfeld: {materialsWithRecipes}
    } = mockStore.scenario()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const materials = materialsWithRecipes.map<Material>(({recipes, ...material}) => {
      return material
    })

    return sleepResponse([200, materials])
  })

  // GET material with recipes (C#)
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}$`)).reply((config) => {
    const {burglengenfeld} = mockStore.scenario()
    const materialId = Number((config.url as string).split('/')[4])
    const materialWithRecipes = burglengenfeld.materialsWithRecipes.find(
      (material) => material.id === materialId
    )

    return sleepResponse([200, materialWithRecipes])
  })

  // POST create new material
  mock.onPost(new RegExp(`^/plants/${numberRegEx}/materials$`)).reply((config) => {
    const scenario = mockStore.scenario()
    const {type, source, name, description} = JSON.parse(config.data as string) as AddMaterialDTO
    const newMaterial: Material = {
      createdBy: 'Planner User',
      createdOn: moment.utc().toISOString(),
      id: Date.now(),
      status: Status.Created,
      name,
      type,
      source,
      products: [],
      pxTrendCounters: [],
      limsMaterials: [],
      description,
      origin: {
        source
      },
      globalName: ''
    }
    const newMaterialWithRecipe: MaterialWithRecipes = {
      ...newMaterial,
      recipes: []
    }
    scenario.burglengenfeld.materialsWithRecipes = [
      ...scenario.burglengenfeld.materialsWithRecipes,
      newMaterialWithRecipe
    ]

    saveScenario(scenario)
    return sleepResponse([201, newMaterial])
  })

  const handleProducedSourceUncheck = (
    dto: EditMaterialDTO,
    editedMaterial: MaterialWithRecipes,
    scenario: MockState
  ) => {
    const isProducedOrBoughtAndProducedMaterial = [
      MaterialSource.ProducedInPlant,
      MaterialSource.BoughtAndProduced
    ].includes(editedMaterial.source)

    const isProducedInPlantUnchecked =
      isProducedOrBoughtAndProducedMaterial && dto.source === MaterialSource.BoughtFromVendor

    if (isProducedInPlantUnchecked) {
      const recipesIdToDelete = editedMaterial.recipes.map(({id}) => id)
      const operationModesIdsToDelete = scenario.burglengenfeld.assets
        .flatMap(({operationModes}) => operationModes)
        .filter(({recipeId}) => (recipeId ? recipesIdToDelete.includes(recipeId) : false))
        .map(({id}) => id)
      scenario.burglengenfeld.assets = scenario.burglengenfeld.assets.map((asset) => {
        asset.operationModes = asset.operationModes.filter(
          ({id}) => !operationModesIdsToDelete.includes(id)
        )
        return asset
      })
      scenario.burglengenfeld.schedule = {
        ...scenario.burglengenfeld.schedule,
        schedules: Object.values(scenario.burglengenfeld.schedule.schedules).reduce((acc, item) => {
          if (!operationModesIdsToDelete.includes(item.assetOperationModeId)) {
            acc[item.id] = item
          }
          return acc
        }, {})
      }
      editedMaterial.recipes = []
      editedMaterial.products = editedMaterial.products.filter(({type}) =>
        isMaterialTypeBoughtFromVendor(type)
      )
    }
  }

  const handleConflictingProducts = (
    dto: EditMaterialDTO,
    materialsWithRecipesMock: MaterialWithRecipes[]
  ) => {
    if (dto.products) {
      dto.products.forEach((product) => {
        const conflictingMaterial = getMaterialWithAttachedProduct(
          product.id,
          materialsWithRecipesMock
        )
        if (conflictingMaterial) {
          materialsWithRecipesMock.map((material) => {
            if (material.id === conflictingMaterial.id) {
              material.products = material.products.filter(({id}) => id !== product.id)
            }
          })
        }
      })
    }
  }

  const handleConflictingLimsMaterials = (
    dto: EditMaterialDTO,
    materialsWithRecipesMock: MaterialWithRecipes[]
  ) => {
    if (dto.limsMaterials) {
      dto.limsMaterials.forEach((limsMaterial) => {
        const conflictingMaterial = getMaterialWithAssignedLimsMaterial(
          limsMaterial.id,
          materialsWithRecipesMock
        )
        if (conflictingMaterial) {
          materialsWithRecipesMock.map((material) => {
            if (material.id === conflictingMaterial.id) {
              material.limsMaterials = material.limsMaterials.filter(
                ({id}) => id !== limsMaterial.id
              )
            }
          })
        }
      })
    }
  }

  const handleProvidedRecipes = (
    recipes: RecipeDTO[] | undefined,
    editedMaterial: MaterialWithRecipes
  ) => {
    if (!recipes) return
    recipes?.forEach((recipe) => {
      if (recipe.id === null) recipe.id = Date.now()
      recipe.components.map((component) => {
        if (component.id === null) component.id = Date.now()
      })
    })
    editedMaterial.recipes = recipes as Recipe[]
  }

  // PATCH edit material
  mock.onPatch(new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}$`)).reply((config) => {
    const materialId = Number((config.url as string).split('/')[4])
    const {recipes, ...dto} = JSON.parse(config.data as string) as EditMaterialDTO
    const scenario = mockStore.scenario()

    const materialsWithRecipesMock = scenario.burglengenfeld.materialsWithRecipes
    const editedMaterial = materialsWithRecipesMock.find((material) => material.id === materialId)!

    handleProducedSourceUncheck(dto, editedMaterial, scenario)
    handleConflictingProducts(dto, materialsWithRecipesMock)
    handleConflictingLimsMaterials(dto, materialsWithRecipesMock)
    handleProvidedRecipes(recipes, editedMaterial)

    const updatedMaterial: MaterialWithRecipes = {...editedMaterial, ...dto, status: Status.Edited}
    scenario.burglengenfeld.materialsWithRecipes = materialsWithRecipesMock.map((material) =>
      material.id === editedMaterial.id ? updatedMaterial : material
    )
    saveScenario(scenario)
    return sleepResponse([200, {}])
  })

  // TODO <HCP-82759>: remove after all add/edit material tabs functionalities
  // are moved to the single save button and global material is moved to BE
  // PATCH edit pxTrend or global material
  const editMaterialResourceRegEx = '(global-material)'
  mock
    .onPatch(
      new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}/${editMaterialResourceRegEx}$`)
    )
    .reply((config) => {
      const materialId = Number((config.url as string).split('/')[4])
      const dto = JSON.parse(config.data as string) as {
        pxTrendCounters?: string[]
        globalMaterialId?: number
      }
      const scenario = mockStore.scenario()

      scenario.burglengenfeld.materialsWithRecipes =
        scenario.burglengenfeld.materialsWithRecipes.map((material) => {
          if (material.id === materialId) {
            material = {...material, ...dto, status: Status.Edited}
          }
          return material
        })
      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // DELETE delete a material
  mock.onDelete(new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}$`)).reply((config) => {
    const scenario = mockStore.scenario()
    const materialId = Number((config.url as string).split('/')[4])
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const materialToDelete = scenario.burglengenfeld.materialsWithRecipes.find(
      (material) => material.id === materialId
    )!

    const recipesIdToDelete = materialToDelete.recipes.map(({id}) => id)

    scenario.burglengenfeld.materialsWithRecipes =
      scenario.burglengenfeld.materialsWithRecipes.filter((material) => material.id !== materialId)

    const operationModesIdsToDelete = scenario.burglengenfeld.assets
      .flatMap(({operationModes}) => operationModes)
      .filter(({recipeId}) => (recipeId ? recipesIdToDelete.includes(recipeId) : false))
      .map(({id}) => id)

    scenario.burglengenfeld.assets = scenario.burglengenfeld.assets.map((asset) => {
      asset.operationModes = asset.operationModes.filter(
        ({id}) => !operationModesIdsToDelete.includes(id)
      )
      return asset
    })

    scenario.burglengenfeld.schedule = {
      ...scenario.burglengenfeld.schedule,
      schedules: Object.values(scenario.burglengenfeld.schedule.schedules).reduce((acc, item) => {
        if (!operationModesIdsToDelete.includes(item.assetOperationModeId)) {
          acc[item.id] = item
        }
        return acc
      }, {})
    }

    saveScenario(scenario)
    return sleepResponse([200, {}])
  })

  // GET material classification metadata
  mock.onGet(new RegExp('^/plants/materials/material-classification-metadata$')).reply(() => {
    const {
      burglengenfeld: {classificationMetadata}
    } = mockStore.scenario()

    return sleepResponse([200, classificationMetadata])
  })

  // GET all products per plant
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials/products$`)).reply(() => {
    const {
      burglengenfeld: {materialsProducts}
    } = mockStore.scenario()

    return sleepResponse([200, materialsProducts])
  })

  // GET recipes per assetType
  mock.onGet(new RegExp(`/plants/${numberRegEx}/materials/recipes`)).reply((config) => {
    const {
      burglengenfeld: {materialsWithRecipes}
    } = mockStore.scenario()
    const assetType = config?.params?.assetType as AssetType | undefined
    const allRecipes = materialsWithRecipes.map(({recipes}) => recipes).flat()

    if (assetType === undefined) {
      return sleepResponse([200, allRecipes])
    }

    const assetTypeToMainMaterialTypeMap: Record<AssetType, MaterialType[]> = {
      CementMill: [MaterialType.Cement],
      RawMill: [MaterialType.IntermediateAndFinishedProducts],
      RotaryKiln: [MaterialType.Clinker],
      CoalMill: [MaterialType.Fuels],
      Crusher: [MaterialType.RawMaterials, MaterialType.IntermediateAndFinishedProducts],
      Other: [
        MaterialType.Cement,
        MaterialType.Clinker,
        MaterialType.Fuels,
        MaterialType.RawMaterials,
        MaterialType.IntermediateAndFinishedProducts
      ],
      BaseLoad: []
    }

    const possibleMaterialTypes = assetTypeToMainMaterialTypeMap[assetType]
    const recipes = materialsWithRecipes
      .map((materialWithRecipe) => {
        if (possibleMaterialTypes.includes(materialWithRecipe.type)) {
          return materialWithRecipe.recipes
        }
        return []
      })
      .flat()

    return sleepResponse([200, recipes])
  })

  // GET global materials
  mock.onGet(new RegExp('^/plants/materials/global-materials$')).reply(() => {
    const {
      burglengenfeld: {globalMaterials}
    } = mockStore.scenario()

    return sleepResponse([200, globalMaterials])
  })

  // DELETE delete (unmap) global material from material
  mock
    .onDelete(new RegExp(`^/plants/${numberRegEx}/materials/${numberRegEx}/global-material$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const materialId = Number((config.url as string).split('/')[4])
      const materialToUpdate = scenario.burglengenfeld.materialsWithRecipes.find(
        (material) => material.id === materialId
      )!
      delete materialToUpdate.globalMaterialId
      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // GET lims materials for plant
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials/lims/materials$`)).reply(() => {
    const {
      burglengenfeld: {limsMaterials}
    } = mockStore.scenario()

    return sleepResponse([200, limsMaterials])
  })

  return mock
}
