import {MaterialType, Material} from '@hconnect/common/types'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {MaterialTreeNode, MaterialTree} from '../../../types'

export const useMaterialsTreeData = (materials: Material[], showNewMaterial: boolean) => {
  const {t} = useTranslation()

  return useMemo<MaterialTree>(() => {
    const materialTypeToMaterials = materials.reduce((mapping, material) => {
      const materialArboristNode: MaterialTreeNode = {
        id: material.id.toString(),
        name: material.name,
        originalEntity: material
      }
      if (mapping[material.type]) {
        mapping[material.type].push(materialArboristNode)
      } else {
        mapping[material.type] = [materialArboristNode]
      }
      return mapping
    }, {})

    const materialsTreeData = Object.values(MaterialType).map<MaterialTreeNode>((type) => ({
      id: type,
      name: t(`materialsSettings.materialTypes.${type}`),
      originalEntity: type,
      children: materialTypeToMaterials[type] ?? []
    }))

    if (showNewMaterial)
      materialsTreeData.unshift({
        id: 'New Material',
        name: t('materialsSettings.newMaterial'),
        originalEntity: undefined
      })

    return materialsTreeData
  }, [materials, showNewMaterial, t])
}
