import {dataTestId} from '@hconnect/uikit'
import {
  LayersOutlined as LayersOutlinedIcon,
  ChatBubbleOutline as ChatBubbleOutlineIcon
} from '@mui/icons-material/'
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Stack,
  Typography
} from '@mui/material'
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {MonthsFilterOptions} from '../../enums'
import {flattenLastValue} from '../../helpers/getFlattenLastValue'
import {getIsValueFieldValid} from '../../helpers/getIsValueFieldValid'
import {getMonthlyDataCanBeEdited} from '../../helpers/getMonthlyDataCanBeEdited'
import {CommentsRequest, Kpi, MonthlyKpisDetails} from '../../types'

import {CommentsDialog} from './CommentsDialog'
import {ResourceCardHeader} from './ResourceCardHeader'

export const ResourceCardView = ({
  group,
  selectedDate,
  parentId,
  periodType
}: {
  group: MonthlyKpisDetails
  selectedDate?: string
  parentId: string
  periodType: MonthsFilterOptions
}) => {
  const [open, setOpen] = useState<boolean>(true)

  const [selectedReport, setSelectedReport] = useState<CommentsRequest | undefined>()
  const {t} = useTranslation()

  const handleToggle = () => {
    setOpen(!open)
  }

  const {
    control,
    formState: {errors},
    clearErrors,
    setValue
  } = useFormContext()

  const {name, kpis, children, id} = group

  const mapResources = useCallback(
    (kpis: Kpi[] | undefined) => {
      const newResources = flattenLastValue(kpis)

      if (newResources) {
        newResources.forEach((resource) =>
          setValue(`entries.${parentId}.${resource.specificKpiId}`, resource)
        )
      }
    },
    [parentId, setValue]
  )

  useEffect(() => {
    mapResources(kpis)
  }, [kpis, id, mapResources])

  return (
    <>
      {selectedReport && (
        <CommentsDialog
          groupId={id}
          selectedDate={selectedDate}
          periodType={periodType}
          open={!!selectedReport}
          onClose={() => setSelectedReport(undefined)}
          id={selectedReport?.kpiValueId}
          name={selectedReport.kpiName}
          unitName={selectedReport.unitName}
        />
      )}
      <Stack sx={{marginLeft: 2}}>
        <ResourceCardHeader title={name} handleToggle={handleToggle} open={open} />
        <Collapse in={open} timeout="auto" unmountOnExit>
          {!kpis && !children && <Typography>{t('janusConfig.dailyEntry.noData')}</Typography>}

          {children &&
            children?.map((child) => (
              <ResourceCardView
                key={child.id}
                group={child}
                parentId={parentId}
                selectedDate={selectedDate}
                periodType={periodType}
              />
            ))}

          {kpis && kpis[0]?.values && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('janusConfig.monthlyEntry.dataField')}</TableCell>{' '}
                  {kpis[0].values.map((value) => (
                    <TableCell
                      key={`${value.date}-${kpis[0].specificKpiId}`}
                      {...dataTestId(`table-head-cell-date-${value.date}-${kpis[0].specificKpiId}`)}
                    >
                      {moment(value.date).format('MMMM YYYY').toString()}
                    </TableCell>
                  ))}
                  <TableCell>{''}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {kpis.map((report) => {
                  const editableValue = report.values[report.values.length - 1]

                  return (
                    <TableRow key={`report-${report.specificKpiId}`}>
                      <TableCell
                        key={`cell-name-${report.specificKpiId}`}
                        {...dataTestId(`cell-name-${report.name}`)}
                      >
                        {`${report.name} (${report.unit.unitName})`}
                      </TableCell>
                      {report.values.map((kpiValue) => (
                        <TableCell
                          {...dataTestId(
                            `report-kpi-value-cell-${report.specificKpiId}-${kpiValue.date}`
                          )}
                          key={`report-kpi-value-cell-${report.specificKpiId}-${kpiValue.date}`}
                        >
                          {getMonthlyDataCanBeEdited(selectedDate, kpiValue.date) ? (
                            <Controller
                              control={control}
                              rules={{
                                validate: (value) => getIsValueFieldValid(value, report.unit.id, t)
                              }}
                              name={`entries.${parentId}.${report.specificKpiId}.value`}
                              render={({field: {ref, value, onChange}}) => {
                                const error = errors?.entries?.[parentId]?.[report.specificKpiId]

                                const helperText = (error && error?.value?.message) || ''

                                return (
                                  <TextField
                                    fullWidth
                                    onClick={(event) => event.stopPropagation()}
                                    onChange={(e) => {
                                      clearErrors(`entries.${parentId}.${report.specificKpiId}`)
                                      onChange(e)
                                    }}
                                    value={value || ''}
                                    InputProps={{inputProps: {min: 0}}}
                                    inputRef={ref}
                                    variant="outlined"
                                    error={!!error}
                                    helperText={helperText}
                                  />
                                )
                              }}
                            />
                          ) : (
                            kpiValue.value || '-'
                          )}
                        </TableCell>
                      ))}
                      <TableCell>
                        <Box
                          display="flex"
                          flexDirection="row"
                          gap={1}
                          sx={{cursor: 'pointer'}}
                          onClick={() =>
                            setSelectedReport({
                              kpiValueId: editableValue.kpiValueId,
                              kpiName: report.name,
                              unitName: report.unit.unitName
                            })
                          }
                        >
                          <Box display="flex" flexDirection="row" alignItems="center">
                            <LayersOutlinedIcon fontSize="small" /> {editableValue.version}
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            {...dataTestId(`kpi-comments-number-${editableValue.kpiValueId}`)}
                          >
                            <ChatBubbleOutlineIcon fontSize="small" /> {editableValue.comments || 0}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </Collapse>
      </Stack>
    </>
  )
}
