import {Material, MaterialType} from '@hconnect/common/types'
import {Loader} from '@hconnect/uikit/src/lib2'
import {useEffect, useMemo, useState} from 'react'

import {useSettingsQuery} from '../../../../../common/providers'
import {useUrlParam} from '../../../../../routing'
import {useCreateDepartmentConfig} from '../../../../hooks/departmentConfig/useCreateDepartmentConfig'
import {useDepartmentConfig} from '../../../../hooks/departmentConfig/useDepartmentConfig'
import {useUpdateQuarryConfig} from '../../../../hooks/departmentConfig/useUpdateQuarryConfig'
import {DepartmentConfigDto} from '../../../../types'
import {useSelectedItem} from '../../../SelectedItemProvider'
import {ItemDetailsLayout} from '../../ItemDetailsLayout'

import {ConfigForm} from './ConfigForm'

const departmentHeaders = {
  '10_2': 'Quarry',
  '20_4': 'Crusher',
  '25_1': 'RawMaterialDryer',
  '25_2': 'RawMaterialDeforesting',
  '40_4': 'Kiln',
  '50_4': 'CementMill',
  '55_1': 'CementBlending',
  '41_1': 'ClayCalcination',
  '05_5': 'WHR',
  '05_6': 'COPG'
}

const CONFIG_TYPE = 'JanusProductionData'

const getInitialConfig = (
  plantCode: string,
  departmentConfig?: DepartmentConfigDto,
  upmId?: string
): DepartmentConfigDto => {
  return {
    metadata: {
      configurationType: CONFIG_TYPE,
      plantId: plantCode,
      upmType: 'Department',
      typeUpmId: upmId ?? ''
    },
    payload: {productionData: departmentConfig?.payload?.productionData || []}
  }
}

const filterAndMapFuels = (data?: Material[]): Material[] => {
  if (!data) return []
  return data.filter((item) => item.type === MaterialType.Fuels)
}

export const Configuration = () => {
  const {selectedItem} = useSelectedItem()
  const entityCodesPath = useMemo(() => {
    return selectedItem?.entityCodesPath ? selectedItem?.entityCodesPath : ''
  }, [selectedItem?.entityCodesPath])

  const {data: departmentConfig, isLoading, isFetching} = useDepartmentConfig(selectedItem?.upmId)
  const {mutateAsync: createDepartmentConfig} = useCreateDepartmentConfig()
  const {mutateAsync: updateDepartmentConfig} = useUpdateQuarryConfig()

  const plantCode = useUrlParam('plantCode')
  const {
    data: materials,
    isLoading: isLoadingMaterials,
    isFetching: isFetchingMaterials
  } = useSettingsQuery('materials', [plantCode])
  const configNotCreated = useMemo(
    () => !isLoading && !departmentConfig,
    [departmentConfig, isLoading]
  )
  const isLoadingOrFetching = isLoading || isFetching

  const [fuels, setFuels] = useState<Material[]>([])

  useEffect(() => {
    if (!isLoadingMaterials && !isFetchingMaterials) {
      setFuels(filterAndMapFuels(materials))
    }
  }, [materials, isLoading, isLoadingMaterials, isFetchingMaterials])

  if (isLoadingOrFetching) {
    return (
      <ItemDetailsLayout>
        <Loader />
      </ItemDetailsLayout>
    )
  }

  const initialConfig: DepartmentConfigDto = getInitialConfig(
    plantCode,
    departmentConfig,
    selectedItem?.upmId
  )

  const config = departmentConfig || initialConfig

  const handleCreateConfig = (departmentConfig: DepartmentConfigDto) =>
    createDepartmentConfig({plantCode, departmentConfig})

  const handleUpdateConfig = (departmentConfig: DepartmentConfigDto) =>
    updateDepartmentConfig({plantCode, departmentConfig})

  const onSubmit = configNotCreated ? handleCreateConfig : handleUpdateConfig

  return (
    <ConfigForm
      configNotCreated={configNotCreated}
      config={config}
      onSubmit={onSubmit}
      fuels={fuels}
      type={departmentHeaders[entityCodesPath]}
    />
  )
}
