import {Material} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Add} from '@mui/icons-material'
import {Box, Button, Stack} from '@mui/material'
import React from 'react'
import {useFieldArray, useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useIsMutating} from 'react-query'

import {usePermission} from '../../../../../permissions'
import {useLimsMaterialsQuery, useMaterialsQuery} from '../../../../hooks'
import {MaterialFormDefaultValues} from '../../../materials-details/ExistingMaterialDetails/hooks'
import {getNewFormLimsMaterial} from '../helpers'
import {MaterialFormLimsMaterial} from '../MaterialFormLimsMaterial/MaterialFormLimsMaterial'

interface MaterialSettingsLimsMaterialsTabProps {
  material: Material
}

export const MaterialSettingsLimsMaterialsTab: React.FC<MaterialSettingsLimsMaterialsTabProps> = ({
  material
}) => {
  const {t} = useTranslation()
  const canChangeMaterials = usePermission('CHANGE_MATERIALS')
  const isMaterialSaving = !!useIsMutating(['editMaterial'])

  const {control} = useFormContext<MaterialFormDefaultValues>()

  const {
    fields: formLimsMaterials,
    append: appendLimsMaterial,
    remove: removeLimsMaterial,
    update: updateLimsMaterial
  } = useFieldArray({
    control,
    name: 'limsMaterials',
    keyName: 'formLimsMaterialId'
  })

  const {data: materials} = useMaterialsQuery()
  if (!materials) {
    throw new Error('BUG: materials should be loaded before rendering lims codes tab')
  }

  const {data: limsMaterials} = useLimsMaterialsQuery()
  if (!limsMaterials) {
    throw new Error('BUG: lims materials should be loaded before rendering lims codes tab')
  }

  const isAddNewLimsMaterialDisabled =
    !canChangeMaterials || isMaterialSaving || limsMaterials.length === 0
  const isRemoveLimsMaterialDisabled = !canChangeMaterials || isMaterialSaving

  return (
    <Stack spacing={3} {...dataTestId('lims_materials_card')}>
      <Box>
        <Button
          variant="text"
          startIcon={<Add />}
          disabled={isAddNewLimsMaterialDisabled}
          onClick={() => {
            appendLimsMaterial(getNewFormLimsMaterial())
          }}
          {...dataTestId('lims_materials_add_button')}
        >
          {t('materialsSettings.addCode')}
        </Button>
      </Box>
      <Stack spacing={3}>
        {formLimsMaterials.map((formLimsMaterial, index) => (
          <MaterialFormLimsMaterial
            key={formLimsMaterial.formLimsMaterialId}
            index={index}
            formLimsMaterial={formLimsMaterial}
            limsMaterials={limsMaterials}
            materials={materials}
            material={material}
            isRemoveLimsMaterialDisabled={isRemoveLimsMaterialDisabled}
            removeLimsMaterial={removeLimsMaterial}
            updateLimsMaterial={updateLimsMaterial}
          />
        ))}
      </Stack>
    </Stack>
  )
}
